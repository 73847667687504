import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { UserService } from "../common/services/user.service";
import { OrganisationService } from "../common/services/organisation.service";
import { ActivityService } from "../common/services/activity.service";
// import { IMyDrpOptions, IMyDateRangeModel } from "mydaterangepicker";
import { debounceTime, filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { BehaviorSubject } from "rxjs";



declare var $: any;
declare var jquery: any;

@Component({
    selector: "app-activity",
    templateUrl: "./activity.component.html",
    styleUrls: ["./activity.component.css"],
})
export class ActivityComponent implements OnInit {

    org_id;
    org_fetched = false;
    admin_limit = 10;
    admin_offset = 0;
    login_limit = 10;
    login_offset = 0;
    admin_activities;
    login_details = [];
    currentTab = 1;
    searchInputApp;
    search_text = "";
    viewChanged = false;
    height1;
    height2;
    admin_search = '';
    login_search = '';
    CDN_BASE_URL = environment.CDN_BASE_URL;
    admin_begin = 0;
    admin_end = 0;
    admin_pagesinLimit = [];
    admin_showBegin = false;
    admin_showEnd = false;
    showFilterMenu = false;
    login_begin = 0;
    login_end = 0;
    login_pagesinLimit = [];
    login_showBegin = false;
    login_showEnd = false;


    admin_pages = [1, 2, 3];
    admin_currentPage = 1;
    admin_prevIndex = 0;
    admin_currentIndex = 1;
    admin_total = 0;
    admin_pageLimit: number = 0;
    admin_disableNext = false;
    admin_disablePrev = false;
    admin_showPagination = false;
    admin_isOutsideLimit = false;
    login_pages = [1, 2, 3];
    login_currentPage = 1;
    login_prevIndex = 0;
    login_currentIndex = 1;
    login_total = 0;
    login_pageLimit: number = 0;
    login_disableNext = false;
    login_disablePrev = false;
    login_showPagination = false;
    login_isOutsideLimit = false;
    beginDate;
    endDate;
    dateRangeApplied = false;
    searchApplied = false;
    admin_data = adminLabels;
    modalHeads = [this.admin_data.field, this.admin_data.originalValue, this.admin_data.newValue];
    modalRows = [];
    breadcrumb;
    action_types = [];
    actions = [];
    private timeout: any;
    action_Type = "";
    action = "";
    lastSearchedValue = "";
    selectedActionType = "";
    selectedActivityActionType = "";

    private gotoPageAdminSubject = new BehaviorSubject<number>(1);
    private gotoPageLoginSubject = new BehaviorSubject<number>(1);
    @ViewChild('action_type', { static: false }) action_type_input: ElementRef;



    activity_filters = [
        {
            type: 'user_org_tour_relation',
            label: this.admin_data.label7,
            actions: [
                {
                    value: 'update_user_guide_role',
                    label: this.admin_data.update
                },
                {
                    value: 'assign_user_guide_role',
                    label: this.admin_data.assign
                }
            ]
        },
        {
            type: 'segment',
            label: this.admin_data.segments,
            actions: [
                {
                    value: 'create_segment',
                    label: this.admin_data.create
                },
                {
                    value: 'delete_segment',
                    label: this.admin_data.delete
                },
                {
                    value: 'update_segment',
                    label: this.admin_data.update
                },
            ]
        },
        {
            type: 'user',
            label: this.admin_data.user,
            actions: [
                {
                    value: 'create_user',
                    label: this.admin_data.create
                },
                {
                    value: 'update_user',
                    label: this.admin_data.update
                },
                {
                    value: 'delete_user',
                    label: this.admin_data.delete
                },
                {
                    value: 'update_user_password',
                    label: this.admin_data.label1
                },
                {
                    value: 'update_user_suspend_status',
                    label: this.admin_data.usersVariable20
                },
                {
                    value: 'clear_session',
                    label: this.admin_data.dashboardClearSessionButton
                },
                {
                    value: 'update_segment',
                    label: this.admin_data.label2
                },
                {
                    value: 'update_user_locked_status',
                    label: this.admin_data.usersVariable29
                }
            ]
        },
        {
            type: 'provisioning',
            label: this.admin_data.label8,
            actions: [
                {
                    value: 'create_user',
                    label: this.admin_data.create
                },
                {
                    value: 'update_user',
                    label: this.admin_data.update
                },
                {
                    value: 'delete_user',
                    label: this.admin_data.delete
                },
            ]
        },
        {
            type: 'key_services',
            label: this.admin_data.label9,
            actions: [
                {
                    value: 'generate_api_keys',
                    label: this.admin_data.label3
                },
                {
                    value: 'update_api_keys',
                    label: this.admin_data.label4
                },
                {
                    value: 'delete_api_keys',
                    label: this.admin_data.label5
                },
                {
                    value: 'assign_api_keys_to_app',
                    label: this.admin_data.label6
                }
            ]
        },
        {
            type: 'organization',
            label: this.admin_data.label10,
            actions: [
                {
                    value: 'update_admin_settings',
                    label: this.admin_data.label15
                },
                {
                    value: 'create_SSO_setting',
                    label: this.admin_data.label16
                },
                {
                    value: 'update_SSO_setting',
                    label: this.admin_data.label17
                },
                {
                    value: 'delete_SSO_setting',
                    label: this.admin_data.label18
                },
            ]
        },
        {
            type: 'application',
            label: this.admin_data.label11,
            actions: [
                {
                    value: 'create_application',
                    label: this.admin_data.label19
                },
                {
                    value: 'update_application',
                    label: this.admin_data.label20
                },
                {
                    value: 'delete_application',
                    label: this.admin_data.label21
                },
            ]
        },
        {
            type: 'category_orders',
            label: this.admin_data.label12,
            actions: [
                {
                    value: 'update_category_orders',
                    label: this.admin_data.label22
                }
            ]
        },
        {
            type: 'category',
            label: this.admin_data.label13,
            actions: [
                {
                    value: 'create_category',
                    label: this.admin_data.create
                },
                {
                    value: 'update_category',
                    label: this.admin_data.update
                },
                {
                    value: 'delete_category',
                    label: this.admin_data.delete
                },
                {
                    value: 'create_sub_category',
                    label: this.admin_data.label23
                },
                {
                    value: 'update_sub_category',
                    label: this.admin_data.label24
                },
                {
                    value: 'delete_sub_category',
                    label: this.admin_data.label25
                }
            ]
        },
        {
            type: 'tour_orders',
            label: this.admin_data.label14,
            actions: [
                {
                    value: 'update_tour_orders',
                    label: this.admin_data.label26
                }
            ]
        },
        {
            type: 'task_list',
            label: this.admin_data.taskList,
            actions: [
                {
                    value: 'create_task_list',
                    label: this.admin_data.label27
                },
                {
                    value: 'delete_task_list',
                    label: this.admin_data.label28
                },
                {
                    value: 'publish_task_list',
                    label: this.admin_data.label29
                },
                {
                    value: 'update_task_list',
                    label: this.admin_data.label30
                },
                {
                    value: 'update_task_list_setting',
                    label: this.admin_data.label31
                },
                {
                    value: 'create_task_list_folder',
                    label: this.admin_data.label32
                },
                {
                    value: 'update_task_list_folder',
                    label: this.admin_data.label33
                },
                {
                    value: 'delete_task_list_folder',
                    label: this.admin_data.label34
                },
                {
                    value: 'update_task_list_content',
                    label: this.admin_data.label35
                },
                {
                    value: 'create_task_list_completion',
                    label: this.admin_data.label36
                },


            ]
        }
    ]

    actions_to_disable = [
        "create_user",
        "create_application",
        "create_application_url",
        "create_organization",
        "generate_api_keys",
        "create_tour",
        "create_city",
        "create_country",
        "create_domain",
        "create_organization_role",
        "create_step",
        "add_user",
        "signin",
        "signout",
        "create_category",
        "assign_user_guide_role",
        "delete_user",
        "delete_application",
        "delete_application_url",
        "delete_organization",
        "delete_api_keys",
        "delete_tour",
        "delete_city",
        "delete_country",
        "delete_domain",
        "delete_organization_role",
        "delete_step",
        "delete_application_roles",
        "delete_category",
        "clear_session",
        "update_user_password",
        "create_segment",
        "create_task_list",
        "delete_task_list",
        "create_task_list_folder",
        "delete_task_list_folder",
        "create_task_list_completion",
        "publish_task_list",
        "delete_SSO_settings"
    ];

    d = new Date(Date.now());
    // myDateRangePickerOptions: IMyDrpOptions = {
    //     dateFormat: "dd.mm.yyyy",
    //     disableSince: {
    //         year: this.d.getUTCFullYear(),
    //         month: this.d.getMonth() + 1,
    //         day: this.d.getDate() + 1,
    //     },
    // };



    constructor(
        private route: ActivatedRoute,
        public userService: UserService,
        private organisationService: OrganisationService,
        public activityService: ActivityService,
        private router: Router,
        private titleService: Title
    ) {
        this.titleService.setTitle(this.admin_data.activity + " | " + "Cornerstone Guide");
        this.userService.getUserData();
        this.organisationService.getCDNData()
        this.userService.activeTab = 'activity';
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];

            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin")) {
                this.organisationService
                    .getOrganization(this.org_id)
                    .subscribe((response) => {
                        let organization = response["data"]["organization"];
                        this.userService.organization = organization["name"];
                        this.userService.organization_id =
                            organization["organization_id"];
                        //this.userService.user.cdn_signature = organization["cdn_signature"];
                        this.userService.organization = organization;
                        let admin_settings = JSON.parse(
                            this.userService.organization.admin_settings
                        );
                        this.userService.enableSegments =
                            admin_settings["guide_segmentation"] == "1" ? true : false;
                        this.userService.enableSSO =
                            admin_settings["sso"] == 1 ? true : false;

                        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;

                        this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;

                        this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

                        this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;

                        let org = organization;
                        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                        cdn_sign["cdn_signature"] = organization["cdn_signature"];
                        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

                        this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                        this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                        localStorage.setItem("organization", JSON.stringify(org));
                        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                        this.org_fetched = true;
                        // this.ngOnInit();
                    });
            }
        });
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     "Activity",
                //     this.route.url["_value"]
                // );
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.activity,
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
            });
    }

    // onDateRangeChanged(event: IMyDateRangeModel) {
    //     let d1 = event.beginDate;
    //     let d2 = event.endDate;
    //     this.beginDate =
    //         new Date(d1["month"] + "/" + d1["day"] + "/" + d1["year"]).getTime() /
    //         1000;
    //     this.endDate =
    //         new Date(d2["month"] + "/" + d2["day"] + "/" + d2["year"]).getTime() /
    //         1000;
    //     this.applyDateFilter();
    // }

    onDateRangeChanged(begin, end) {
        this.beginDate = Math.floor(begin / 1000);
        this.endDate = Math.floor(end / 1000);
        this.applyDateFilter();
    }

    tab = "contact";

    ngOnInit() {
        this.action_types = this.activity_filters;

        $("#myTab").on("click", function (e) {
            e.preventDefault();
            $('#myTab a[href="#' + this.tab + '"]').tab("show");
        });
        this.searchInputApp = document.getElementById("searchInputApp");
        if (
            this.userService.user.role != "superadmin" ||
            (this.userService.user.role == "superadmin" &&
                this.org_id == this.userService.organization_id && this.org_fetched)
        ) {
            // this.getAdminActivities();
            this.setActions("organization");
            this.action_Type = "organization";
            this.action = "update_admin_settings";
            this.applyFilter();
            this.getLoginActivities();
        }
        let maxdate = new Date(Date.now());
        let mindate = new Date("01/02/1920");
        let maxyear = maxdate.getUTCFullYear();
        let minyear = mindate.getUTCFullYear();
        $('input[name="daterange"]').daterangepicker({
            "autoUpdateInput": false,
            "showDropdowns": true,
            "linkedCalendars": false,
            "autoApply": false,
            "minYear": minyear,
            "maxyear": maxyear,
            "maxDate": maxdate,
            "changeMonth": true,
            "changeYear": true,
            constrainInput: false,
            locale: {
                cancelLabel: this.admin_data.cancel,
                applyLabel: this.admin_data.apply
            }
        });

        let self = this;
        $('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {

            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' to ' + picker.endDate.format('DD/MM/YYYY'));
            self.onDateRangeChanged(picker.startDate, picker.endDate);

        });



        $('input[name="daterange"]').on('cancel.daterangepicker', function (ev, picker) {
            $('input[name="daterange"]').val('');
            //vm.model = {startDate: null, endDate: null};
            //var picker = $element.find('[date-range-picker]').data('daterangepicker');
            picker.setStartDate(new Date);
            picker.setEndDate(new Date);
            //self.onDateRangeChanged(picker.startDate,picker.endDate);
            self.beginDate = undefined;
            self.endDate = undefined;
            if (self.dateRangeApplied) {
                self.applyDateFilter();
            }
            $(this).val('');
        });
        this.gotoPageAdminSubject.pipe(
            debounceTime(800))
            .subscribe((page: number) => {
                this.gotoPageAdmin(page);
            })

        this.gotoPageLoginSubject.pipe(
            debounceTime(800))
            .subscribe((page: number) => {
                this.gotoPageLogin(page);
                // if (this.login_offset >= 0) { this.getLoginActivities() }
            })
    }


    setActions(type) {
        let action_type: any;
        this.action_types.forEach((a) => {
            if (a.type === type) {
                action_type = a;
                this.action_Type = type;
                this.action = '';
            };
        })
        this.actions = action_type.actions;
    }

    setActionTag = (action) => this.action = action;

    applyFilter() {
        this.admin_pageLimit = 0;
        this.login_pageLimit = 0;
        this.admin_limit = 10;
        this.admin_offset = 0;
        this.admin_currentPage = 1;
        this.admin_currentIndex = 1;
        // this.action_Type=action_type;
        // this.action = action;
        if (this.dateRangeApplied) {
            this.getAdminActivities(this.beginDate, this.endDate, this.search_text, this.action_Type, this.action);
        } else {
            this.getAdminActivities("", "", this.search_text, this.action_Type, this.action);
        }
    }

    close() {
        this.showFilterMenu = false;
        // if (document.getElementById("filter-dropdown-menu") && document.getElementById("filter-dropdown-menu").classList.contains("show")) {
        //     document.getElementById("filter-dropdown-menu").classList.remove("show");
        //     document.getElementById("filter").classList.remove("show");
        // }
    }

    clear() {
        this.action_types = this.activity_filters;
        this.actions = [];
        this.action = "";
        this.action_Type = "";
        this.action_type_input.nativeElement.value = "";
        if (this.dateRangeApplied) {
            this.getAdminActivities(this.beginDate, this.endDate, this.search_text, this.action_Type, this.action);
        } else {
            this.getAdminActivities("", "", this.search_text, this.action_Type, this.action);
        }
        this.close();
    }



    getAdminActivities(from_date = "", to_date = "", search = "", action_type = "", action = "") {
        this.activityService
            .getActivity(
                this.admin_limit,
                this.admin_offset,
                "admin_activities",
                this.userService.organization_id,
                from_date,
                to_date,
                search,
                action_type,
                action
            )
            .subscribe((response) => {
                this.processAdminResponse(response);
                this.setAdminPagesinView();
                this.close();
            });
    }

    getLoginActivities(from_date = "", to_date = "", search = "") {
        // this.activityService
        //     .getActivity(
        //         this.login_limit,
        //         this.login_offset,
        //         "login_details",
        //         this.userService.organization_id,
        //         from_date,
        //         to_date,
        //         search
        //     )
        //     .subscribe((response) => {
        //         this.processLoginResponse(response);
        //         this.setLoginPagesinView();
        //     });
    }

    applyDateFilter() {
        this.admin_limit = 10;
        this.admin_offset = 0;
        this.login_limit = 10;
        this.login_offset = 0;
        this.admin_currentPage = 1;
        this.login_currentPage = 1;
        this.admin_currentIndex = 1;
        this.login_currentIndex = 1;
        if (this.beginDate && this.endDate) {
            this.dateRangeApplied = true;
            this.getAdminActivities(this.beginDate, this.endDate, this.search_text, this.action_Type, this.action);
            this.getLoginActivities(this.beginDate, this.endDate, this.search_text);
        } else {
            this.dateRangeApplied = false;
            this.getAdminActivities("", "", this.search_text, this.action_Type, this.action);
            this.getLoginActivities("", "", this.search_text);
        }
    }

    processData(data) {
        let obj = JSON.parse(data);
        this.modalRows = [];
        obj.forEach((data) => {
            let d = {};
            d["field"] = data["field"];
            d["original_value"] = this.setEnableDisable("original_value", data);
            d["new_value"] = this.setEnableDisable("new_value", data);
            this.modalRows.push(d);
        });
        this.showModal();
    }

    showModal() {
        $("#detailModal").modal("show");
    }

    closeModal() {
        $("#detailModal").modal("hide");
    }

    setEnableDisable(field, data) {
        let returnVal;
        if (data[field] === 0 || data[field] === "0") {
            returnVal = "Disabled";
        } else if (data[field] === 1 || data[field] === "1") {
            returnVal = "Enabled";
        } else {
            returnVal = data[field];
        }
        return returnVal;
    }

    processAdminResponse(response) {
        let activities = response["data"]["activities"];
        this.admin_activities = this.makeActionsToText(activities);
        if (response["data"]["activities"]) {

            if (this.admin_activities.length > 0 && this.admin_offset == 0) {
                this.admin_pageLimit = 1000
                this.admin_isOutsideLimit = false;
                // this.setAdminPagesinView();
            }
            this.admin_disableNext = false;
            this.admin_showPagination = true;

            if (this.admin_offset == 0) {
                this.admin_disablePrev = true;
            } else {
                this.admin_disablePrev = false;
                this.admin_showPagination = true;
            }
            if (this.admin_activities.length < 10) {
                this.admin_disableNext = true;
            }

        } else {
            if (this.dateRangeApplied) {
                this.getAdminActivities(this.beginDate, this.endDate, this.search_text, this.action_Type, this.action);
            } else {
                this.getAdminActivities("", "", this.search_text, this.action_Type, this.action);
            }
        }
    }

    appendOffsetAndGetAdminActivities() {
        this.admin_disableNext = true;
        this.admin_currentPage++;
        this.admin_currentIndex = this.admin_currentPage;
        if (this.admin_currentPage > 0) {
            if (
                this.admin_currentPage > this.admin_pages[this.admin_pages.length - 1]
            ) {
                this.admin_pages.shift();
                this.admin_pages.push(this.admin_currentPage);
            }
            this.admin_offset += 10;
            if (this.dateRangeApplied) {
                this.getAdminActivities(this.beginDate, this.endDate, this.search_text, this.action_Type, this.action);
            } else {
                this.getAdminActivities("", "", this.search_text, this.action_Type, this.action);
            }
        }
        this.setAdminPagesinView();
    }

    prependOffsetAndGetAdminActivities() {
        this.admin_disablePrev = true;
        this.admin_currentPage--;
        this.admin_currentIndex = this.admin_currentPage;
        if (this.admin_currentPage > 0) {
            if (this.admin_currentPage < this.admin_pages[0]) {
                this.admin_pages.pop();
                this.admin_pages.unshift(this.admin_currentPage);
            }
            this.admin_offset -= 10;
            if (this.dateRangeApplied) {
                this.getAdminActivities(this.beginDate, this.endDate, this.search_text, this.action_Type, this.action);
            } else {
                this.getAdminActivities("", "", this.search_text, this.action_Type, this.action);
            }
        }
        this.setAdminPagesinView();
    }

    setAdminIndex(ind, event) {
        if (this.admin_disablePrev && this.admin_disableNext) {
            event.preventDefault();
            return;
        }
        if (ind != this.admin_currentIndex) {
            this.admin_prevIndex = this.admin_currentIndex;
            this.admin_currentIndex = ind;
            this.admin_currentPage = this.admin_currentIndex;
            this.calculateOffsetAndGetUsers(
                this.admin_prevIndex,
                this.admin_currentIndex
            );
        }
        this.setAdminPagesinView();
    }

    calculateOffsetAndGetUsers(prev, current) {
        if (this.admin_offset < 0) this.admin_offset = 0;
        if (current > prev) {
            this.admin_offset =
                (current - prev) * this.admin_limit + this.admin_offset;
        } else {
            this.admin_offset =
                this.admin_offset - (prev - current) * this.admin_limit;
        }
        if (this.admin_offset >= 0) {
            this.admin_isOutsideLimit = false;
            if (this.dateRangeApplied) {
                this.getAdminActivities(this.beginDate, this.endDate, this.search_text, this.action_Type, this.action);
            } else {
                this.getAdminActivities("", "", this.search_text, this.action_Type, this.action);
            }
        }
    }

    setAdminPagesinView() {
        this.login_begin = this.admin_currentPage - 3;
        this.admin_end = this.admin_currentPage + 2;
        if (this.login_begin < 0) {
            this.login_begin = 0;
            this.admin_end = this.login_begin + 5;
        }
        if (this.admin_end == this.admin_pageLimit) {
            this.admin_end = this.admin_currentPage + 2;
        }
        if (this.admin_end > this.admin_pageLimit) {
            this.admin_end = this.admin_pageLimit;
            this.admin_pagesinLimit = this.admin_pages.slice(this.admin_end - 5, this.admin_end);
        }
        else {
            this.admin_pagesinLimit = this.admin_pages.slice(this.login_begin, this.admin_end);
        }

        if (Math.ceil(this.admin_pageLimit) > 5) {
            if (this.admin_currentPage > Math.floor(this.admin_pageLimit / 2)) {
                this.admin_showBegin = true;
                this.admin_showEnd = false;
            } else {
                this.admin_showEnd = true;
                this.admin_showBegin = false;
            }
        } else {
            this.admin_showBegin = false;
            this.admin_showEnd = false;
        }
    }

    gotoPageAdmin(page: number) {
        if (page > this.admin_pageLimit || page <= 0) {
            this.admin_isOutsideLimit = true;
            return -1;
        }

        let off = 0;
        if (page == this.admin_pageLimit) {
            this.admin_disableNext = false;
        } else if (page == 1) {
            off = 0;
        } else {
            this.admin_disableNext = true;
            this.admin_disablePrev = true;
        }
        this.admin_currentIndex = page;
        this.calculateOffsetAndGetUsers(this.admin_currentPage, page);
        this.admin_currentPage = page;
        this.setAdminPagesinView();
    }

    //Login Processing

    processLoginResponse(response) {
        let activities = response["data"]["activities"];
        this.login_details = this.makeLoginActionsToText(activities);
        if (response["data"]["activities"]) {
            if (this.login_details.length > 0 && this.login_offset == 0) {
                this.login_pageLimit = Math.ceil(
                    parseInt(response['data']['total_rows']) / 10
                );
                this.login_isOutsideLimit = false;
                this.login_total = parseInt(response['data']['total_rows']);
                if (this.login_pageLimit == 2) {
                    this.login_pages = [1, 2];
                } else {
                    this.login_pages = Array.from(Array(this.login_pageLimit), (_, index) => index + 1);
                }
            }

            if (this.login_details.length < this.login_limit) {
                this.login_disableNext = true;
            } else {
                this.login_disableNext = false;
                this.login_showPagination = true;
            }

            if (this.login_currentPage == 1) {
                this.login_disablePrev = true;
            } else {
                this.login_disablePrev = false;
                this.login_showPagination = true;
            }
            if (this.login_pageLimit == 1) {
                this.login_showPagination = false;
            }
        } else {
            if (this.dateRangeApplied) {
                this.getLoginActivities(this.beginDate, this.endDate, this.search_text);
            } else {
                this.getLoginActivities("", "", this.search_text);
            }
        }
        this.setLoginPagesinView();
    }

    appendOffsetAndGetLoginActivities() {
        this.login_disableNext = true;
        this.login_currentPage++;
        this.login_currentIndex = this.login_currentPage;
        if (this.login_currentPage > 0) {
            if (
                this.login_currentPage > this.login_pages[this.login_pages.length - 1]
            ) {
                this.login_pages.shift();
                this.login_pages.push(this.login_currentPage);
            }
            this.login_offset += 10;
            if (this.dateRangeApplied) {
                this.getLoginActivities(this.beginDate, this.endDate, this.search_text);
            } else {
                this.getLoginActivities("", "", this.search_text);
            }
        }
        this.setLoginPagesinView();
    }

    prependOffsetAndGetLoginActivities() {
        this.login_disablePrev = true;
        this.login_currentPage--;
        this.login_currentIndex = this.login_currentPage;
        if (this.login_currentPage > 0) {
            if (this.login_currentPage < this.login_pages[0]) {
                this.login_pages.pop();
                this.login_pages.unshift(this.login_currentPage);
            }
            this.login_offset -= 10;
            if (this.dateRangeApplied) {
                this.getLoginActivities(this.beginDate, this.endDate, this.search_text);
            } else {
                this.getLoginActivities("", "", this.search_text);
            }
        }
        this.setLoginPagesinView();
    }

    setLoginIndex(ind, event) {
        if (this.login_disablePrev && this.login_disableNext) {
            event.preventDefault();
            return;
        }
        if (ind != this.login_currentIndex) {
            this.login_prevIndex = this.login_currentIndex;
            this.login_currentIndex = ind;
            this.login_currentPage = this.login_currentIndex;
            this.calculateOffsetAndGetLoginActivities(
                this.login_prevIndex,
                this.login_currentIndex
            );
        }
        this.setLoginPagesinView();
    }

    calculateOffsetAndGetLoginActivities(prev, current) {
        if (this.login_offset < 0) this.login_offset = 0;
        if (current > prev) {
            this.login_offset =
                (current - prev) * this.login_limit + this.login_offset;
        } else {
            this.login_offset =
                this.login_offset - (prev - current) * this.login_limit;
        }
        if (this.login_offset > 0) {
            this.login_isOutsideLimit = false;
            if (this.dateRangeApplied) {
                this.getLoginActivities(this.beginDate, this.endDate, this.search_text);
            } else {
                this.getLoginActivities("", "", this.search_text);
            }
        }
    }

    setLoginPagesinView() {
        this.login_begin = this.login_currentPage - 3;
        this.login_end = this.login_currentPage + 2;
        if (this.login_begin < 0) {
            this.login_begin = 0;
            this.login_end = this.login_begin + 5;
        }
        if (this.login_end == this.login_pageLimit) {
            this.login_end = this.login_currentPage + 2;
        }
        if (this.login_end > this.login_pageLimit) {
            this.login_end = this.login_pageLimit;
            this.login_pagesinLimit = this.login_pages.slice(this.login_end - 5, this.login_end);
        }
        else {
            this.login_pagesinLimit = this.login_pages.slice(this.login_begin, this.login_end);
        }

        if (Math.ceil(this.login_pageLimit) > 5) {
            if (this.login_currentPage > Math.floor(this.login_pageLimit / 2)) {
                this.login_showBegin = true;
                this.login_showEnd = false;
            } else {
                this.login_showEnd = true;
                this.login_showBegin = false;
            }
        } else {
            this.login_showBegin = false;
            this.login_showEnd = false;
        }
    }

    gotoPageLogin(page: number) {
        if (page > this.login_pageLimit || page <= 0) {
            this.login_isOutsideLimit = true;
            return -1;
        }

        let off = 0;
        if (page == this.login_pageLimit) {
            this.login_disableNext = false;
        } else if (page == 1) {
            off = 0;
        } else {
            this.login_disableNext = true;
            this.login_disablePrev = true;
        }
        this.login_currentIndex = page;
        this.calculateOffsetAndGetLoginActivities(this.login_currentPage, page);
        this.login_currentPage = page;
        this.setLoginPagesinView();
    }
    selectTab(tab) {
        $('#myTab a[href="#' + this.tab + '"]').tab("show");
    }

    changeTab(val) {
        this.currentTab = val;
        // this.currentTab == 2 ? this.admin_search = this.search_text : this.login_search = this.search_text;
        // this.currentTab == 1 ? this.search_text = this.admin_search : this.search_text = this.login_search;
        this.ngAfterViewChecked();
        if (this.currentTab == 1) {
            this.height2 = document.body.scrollHeight + "px";
        } else {
            this.height1 = document.body.scrollHeight + "px";
        }
    }

    search() {
        let search = this.searchInputApp.value;
        // if (this.currentTab == 1) {
        this.admin_limit = 10;
        this.admin_offset = 0;
        this.admin_currentPage = 1;
        this.admin_currentIndex = 1;
        if (this.dateRangeApplied) {
            this.getAdminActivities(this.beginDate, this.endDate, this.search_text, this.action_Type, this.action);
        } else {
            this.getAdminActivities("", "", this.search_text, this.action_Type, this.action);
        }
        // } else {
        this.login_limit = 10;
        this.login_offset = 0;
        this.login_currentPage = 1;
        this.login_currentIndex = 1;
        if (this.dateRangeApplied) {
            this.getLoginActivities(this.beginDate, this.endDate, search);
        } else {
            this.getLoginActivities("", "", search);
        }
        // }
    }

    onInput(event: Event) {
        if ((event.target as HTMLInputElement).value != this.lastSearchedValue) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.search();
            }, 500);
            this.lastSearchedValue = (event.target as HTMLInputElement).value;
        }
    }
    ngAfterViewChecked() {
        this.viewChanged = true;
        let height = document.body.scrollHeight + "px";
        if (this.height1 && this.height2) {
            this.userService.docHeight.next(
                this.currentTab == 1 ? this.height1 : this.height2
            );
        } else {
            this.userService.docHeight.next(height);
        }
        this.viewChanged = false;
    }

    makeActionsToText(activities) {
        let new_activities = [];
        activities.forEach((activity) => {
            let obj = {}
            let first_name = activity["first_name"];
            let last_name = activity['last_name'];
            switch (activity['action']) {
                case "create_user":
                    obj['activity'] = "Created user";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created a new user with email ID '" + JSON.parse(activity['new_value'])['user_email'] + "'";
                    break;

                case "update_user":
                    obj['activity'] = "Updated user";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated user details for '" + JSON.parse(activity['old_value'])['user_email'] + "'";
                    break;

                case "delete_user":
                    obj['activity'] = "Deleted user";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted a user with email ID '" + JSON.parse(activity['old_value'])['user_email'] + "'";
                    break;

                case "create_application":
                    obj['activity'] = "Created application";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created application '" + activity['title'] + "'";
                    break;

                case "update_application":
                    obj['activity'] = "Updated application";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated application '" + activity['title'] + "'";
                    break;

                case "delete_application":
                    obj['activity'] = "Deleted application";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted application '" + activity['title'] + "'";
                    break;

                case "create_organization":
                    obj['activity'] = "Created organization";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created organization '" + activity['name'] + "'";
                    break;

                case "update_organization":
                    obj['activity'] = "Updated organization";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated organization '" + activity['name'] + "'";
                    break;

                case "delete_organization":
                    obj['activity'] = "Deleted organization";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted organization '" + activity['name'] + "'";
                    break;

                case "update_org_approval_status":
                    obj['activity'] = "Updated organization approval status";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated organization's approval status";
                    break;

                case "update_org_suspend_status":
                    obj['activity'] = "Updated organization suspend status";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated organization's suspend status";
                    break;

                case "update_user_suspend_status":
                    obj['activity'] = "Updated user suspend status";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated suspend status for user '" + JSON.parse(activity['old_value'])['user_email'] + "'";
                    break;

                case "update_user_locked_status":
                    obj['activity'] = "Unlock user account";
                    obj['description'] = "'" + first_name + " " + last_name + "' has unlocked user '" + JSON.parse(activity['old_value'])['user_email'] + "'";
                    break;

                case "update_admin_settings":
                    obj['activity'] = "Updated admin settings";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated admin settings for the organization '" + activity['name'] + "'";
                    break;

                case "update_theme_settings":
                    obj['activity'] = "Updated theme settings";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated theme settings for the organization '" + activity['name'] + "'";
                    break;

                case "generate_api_keys":
                    obj['activity'] = "Generated new API keys";
                    obj['description'] = "'" + first_name + " " + last_name + "' has generated new API keys for the organization '" + activity['name'] + "'";
                    break;

                case "delete_api_keys":
                    obj['activity'] = "Deleted API keys";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted API keys for the organization '" + activity['name'] + "'";
                    break;

                case "delete_application_url":
                    obj['activity'] = "Deleted application URL";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted application URL '" + JSON.parse(activity['new_value'])['url'] + "' under the application '" + activity['title'] + "'";
                    break;

                case "create_application_url":
                    obj['activity'] = "Created application URL";
                    obj['description'] = "'" + first_name + " " + last_name + "' has added application URL '" + JSON.parse(activity['new_value'])['url'] + "' under the application '" + activity['title'] + "'";
                    break;

                case "delete_domain":
                    obj['activity'] = "Deleted domain";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted the domain '" + JSON.parse(activity['old_value'])['domain'] + "' under the organization " + activity['name'] + "'";
                    break;

                case "create_domain":
                    obj['activity'] = "Created domain";
                    obj['description'] = "'" + first_name + " " + last_name + "' has added the domain '" + JSON.parse(activity['new_value'])['domain'] + "' under the organization " + activity['name'] + "'";
                    break;

                case "update_domain":
                    obj['activity'] = "Updated domain";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated the domain '" + JSON.parse(activity['new_value'])['domain'] + "' under the organization " + activity['name'] + "'";
                    break;

                case "create_organization_role":
                    obj['activity'] = "Created organization role";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created organization role '" + JSON.parse(activity['new_value'])['title'] + "'";
                    break;

                case "update_organization_role":
                    obj['activity'] = "Updated organization role";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated organization role '" + JSON.parse(activity['old_value'])['title'] + "'";
                    break;

                case "delete_organization_role":
                    obj['activity'] = "Deleted organization role";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted organization role '" + JSON.parse(activity['old_value'])['title'] + "'";
                    break;

                case "create_SSO_settings":
                    obj['activity'] = "Created SSO settings";
                    obj['description'] = "'" + first_name + " " + last_name + "' has added SSO settings for organization '" + activity['name'] + "'";
                    break;

                case "delete_SSO_settings":
                    obj['activity'] = "Deleted SSO settings";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted SSO settings for organization '" + activity['name'] + "'";
                    break;

                case "update_SSO_settings":
                    obj['activity'] = "Updated SSO settings";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated SSO settings for organization '" + activity['name'] + "'";
                    break;

                case "update_user_organization_role":
                    obj['activity'] = "Updated user organization role";
                    obj['description'] = "'" + first_name + " " + last_name + "' has assigned an app access role '" + JSON.parse(activity['new_value'])['title'] + "' for user '" + JSON.parse(activity['old_value'])['user_email'] + "'";
                    break;

                case "update_application_roles":
                    obj['activity'] = "Updated application roles";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated application roles for application '" + activity['title'] + "'";
                    break;

                case "update_user_guide_role":
                    obj['activity'] = "Updated user role";
                    obj['description'] = "'" + first_name + " " + last_name + "' has assigned a user role '" + JSON.parse(activity['data'])[0]['new_value'] + "' for user '" + JSON.parse(activity['old_value'])['user_email'] + "'";
                    break;

                case "delete_category":
                    obj['activity'] = "Deleted category";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted category '" + JSON.parse(activity['old_value'])['category_title'] + "' under the application '" + activity['title'] + "'";
                    break;

                case "delete_tour":
                    obj['activity'] = "Deleted tour";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted tour '" + JSON.parse(activity['old_value'])['tour_title'] + "' under the application '" + activity['title'] + "'";
                    break;

                case "create_category":
                    obj['activity'] = "Created category";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created category '" + JSON.parse(activity['new_value'])['category_title'] + "' under the application '" + activity['title'] + "'";
                    break;

                case "update_category":
                    obj['activity'] = "Updated category";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated category '" + JSON.parse(activity['new_value'])['category_title'] + "' under the application '" + activity['title'] + "'";
                    break;

                case "create_sub_category":
                    obj['activity'] = "Created sub category";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created sub category '" + JSON.parse(activity['new_value'])['category_title'] + "' under the application '" + activity['title'] + "'";
                    break;

                case "update_sub_category":
                    obj['activity'] = "Updated sub category";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated sub category '" + JSON.parse(activity['new_value'])['category_title'] + "' under the application '" + activity['title'] + "'";
                    break;

                case "delete_sub_category":
                    obj['activity'] = "Deleted sub category";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted sub category '" + JSON.parse(activity['new_value'])['category_title'] + "' under the application '" + activity['title'] + "'";
                    break;


                case "update_user_password":
                    obj['activity'] = "Updated user password";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated password for user '" + JSON.parse(activity['new_value'])['user_email'] + "'";
                    break;

                case "clear_session":
                    obj['activity'] = "Cleared User Session";
                    obj['description'] = "'" + first_name + " " + last_name + "' has cleared session of user '" + activity['name'] + "'";
                    break;

                case "update_api_connector":
                    obj["activity"] = "Updated API Connector";
                    obj['description'] = "'" + first_name + " " + last_name + " has updated the api connector for the organization " + activity['name'];
                    break;

                case "assign_user_guide_role":
                    obj['activity'] = "Added user role";
                    let role = JSON.parse(activity['data'])['new_value'];
                    let displayrole;
                    switch (role) {
                        case 'owner':
                            displayrole = adminLabels.guideRoleAdministrator;
                            break;
                        case 'creator':
                            displayrole = adminLabels.guideRoleCreator;
                            break;
                        case 'user':
                            displayrole = adminLabels.guideRolePlayer;
                            break;
                    }
                    obj['description'] = "'" + first_name + " " + last_name + "' has added user role '" + displayrole + "' for user '" + JSON.parse(activity['old_value'])['user_email'] + "'";
                    break;

                case "assign_api_keys_to_app":
                    obj['activity'] = "Assigned API keys to application";
                    obj['description'] = "'" + first_name + " " + last_name + "' has assigned API keys to applications";
                    break;

                case "create_segment":
                    obj['activity'] = "Created a segment";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created a segment '" + JSON.parse(activity['new_value'])['key_name'] + "'";
                    break;

                case "delete_segment":
                    obj['activity'] = "Deleted a segment";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted segment '" + JSON.parse(activity['old_value'])['key_name'] + "'";
                    break;

                case "update_segment":
                    obj['activity'] = "Updated segment";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated segment '" + JSON.parse(activity['old_value'])['key_name'] + "'";
                    break;
                case "create_api_connector":
                    obj['activity'] = "Created API Connector";
                    obj['description'] = "'" + first_name + " " + last_name + " has created an api connector " + JSON.parse(activity['new_value'])['api_name'] + " for the organization " + activity['name'];
                    break;
                case 'delete_api_connector':
                    obj['activity'] = "Deleted API Connector";
                    obj['description'] = "'" + first_name + " " + last_name + " has deleted the api connector " + JSON.parse(activity['old_value'])['api_name'] + " from the organization " + activity['name'];
                    break;
                case 'create_provision_token':
                    obj['activity'] = "Created Provision Token";
                    obj['description'] = "'" + first_name + " " + last_name + " has created user provisioning token(s) for the organization " + activity['name']
                    break;
                case 'update_provision_token':
                    obj['activity'] = "Updated Provision Token";
                    obj['description'] = "'" + first_name + " " + last_name + " has updated user provisioning token(s) for the organization " + activity['name']
                    break;
                case "create_task_list":
                    obj['activity'] = "Created task list";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created task list '" + JSON.parse(activity['new_value'])['title'] + "' under the application '" + activity['title'] + "'";
                    break;
                case "update_task_list":
                    obj['activity'] = "Updated task list";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated task list '" + JSON.parse(activity['new_value'])['title'] + "' under the application '" + activity['title'] + "'";
                    break;
                case "delete_task_list":
                    obj['activity'] = "Deleted task list";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted task list '" + JSON.parse(activity['old_value'])['title'] + "' under the application '" + activity['title'] + "'";
                    break;
                case "publish_task_list":
                    obj['activity'] = "Publish task list";
                    obj['description'] = "'" + first_name + " " + last_name + "' has '" + JSON.parse(activity['new_value'])['is_publish'] + "' '" + JSON.parse(activity['old_value'])['title'] + "' under the application '" + activity['title'] + "'";
                    break;
                case "update_task_list_setting":
                    obj['activity'] = "Updated task list setting";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated task list setting '" + JSON.parse(activity['old_value'])['title'] + "' under the application '" + activity['title'] + "'";
                    break;
                case "create_task_list_folder":
                    obj['activity'] = "Created task list folder";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created task list folder '" + JSON.parse(activity['new_value'])['title'] + "' under the application '" + activity['title'] + "'";
                    break;
                case "update_task_list_folder":
                    obj['activity'] = "Updated task list folder";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated task list folder '" + JSON.parse(activity['new_value'])['title'] + "' under the application '" + activity['title'] + "'";
                    break;
                case "delete_task_list_folder":
                    obj['activity'] = "Deleted task list folder";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted task list folder '" + JSON.parse(activity['old_value'])['title'] + "' under the application '" + activity['title'] + "'";
                    break;
                case "update_task_list_content":
                    obj['activity'] = "Inserted task list content";
                    obj['description'] = "'" + first_name + " " + last_name + "' has inserted task list content under the application '" + activity['title'] + "'";
                    break;
                case "create_task_list_completion":
                    obj['activity'] = "Completed task list content";
                    obj['description'] = "'" + first_name + " " + last_name + "' has completed task list content under the application '" + activity['title'] + "'";
                    break;
                case "create_segment_group":
                    obj['activity'] = "Created segment group";
                    obj['description'] = "'" + first_name + " " + last_name + "' has created the segment group for the orgnization '" + activity['name'] + "'";
                    break;
                case "update_segment_group":
                    obj['activity'] = "Updated segment group";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated the segment group for the orgnization '" + activity['name'] + "'";
                    break;
                case "delete_segment_group":
                    obj['activity'] = "Deleted segment group";
                    obj['description'] = "'" + first_name + " " + last_name + "' has deleted the segment group for the orgnization '" + activity['name'] + "'";
                    break;
                case "update_domain_sso_mapping":
                    obj['activity'] = "Updated Domain SSO Mapping";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated the domain sso mapping for the domain '" + JSON.parse(activity['new_value'])['domain'] + "' under the orgnization '" + activity['name'] + "'";
                    break;
                case "update_category_orders":
                    obj['activity'] = "Updated Category Orders";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated the catgory orders under the orgnization '" + activity['name'] + "'";
                    break;
                case "update_tour_orders":
                    obj['activity'] = "Updated tour orders";
                    obj['description'] = "'" + first_name + " " + last_name + "' has updated the tour orders under the orgnization '" + activity['name'] + "'";
                    break;

            }
            obj['action'] = activity['action'];
            obj['user_email'] = activity['user_email'];
            obj['modification_date'] = activity['modification_date'];
            obj['ip_address'] = activity['ip_address'];
            obj['data'] = activity['data'];
            obj['total_rows'] = activity['total_rows'];
            obj['details'] = '';
            new_activities.push(obj);
        })
        return new_activities;
    }

    makeLoginActionsToText(activities) {
        let new_activities = [];
        activities.forEach((activity) => {
            let obj = {}
            switch (activity['action']) {
                case ('signout'):
                    obj['action'] = "SignOut";
                    obj['data'] = activity['user_email'] + " logged out from " + JSON.parse(activity['new_value'])['logged_in_from'] + ". Type:" + JSON.parse(activity['new_value'])['signout_type'];
                    break;
                case ('signin'):
                    obj['action'] = "SignIn";
                    obj['data'] = activity['user_email'] + " logged in from " + JSON.parse(activity['new_value'])['logged_in_from'];
                    break;
            }
            obj['modification_date'] = activity['modification_date'];
            obj['ip_address'] = activity['ip_address'];
            obj['total_rows'] = activity['total_rows'];
            new_activities.push(obj);
        })
        return new_activities;
    }

    validateInput(event: Event) {
        this.userService.validateInput(event);
        if ((event.target as HTMLInputElement).value != this.lastSearchedValue) {
            // this.onInput();
            this.lastSearchedValue = (event.target as HTMLInputElement).value;
        }
    }

    goToActionAdmin(event) {
        this.gotoPageAdminSubject.next(Number(event.target.value));
    }

    goToActionLogin(event) {
        this.gotoPageLoginSubject.next(Number(event.target.value));
    }
}
