import { Component, OnInit } from "@angular/core";
import { ADMIN_SETTINGS_FORMDATA } from "../constants/FormData/admin-settings";
import { ToastrService } from 'ngx-toastr';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators,
    FormArray,
} from "@angular/forms";
import { UserService } from "../common/services/user.service";
import { AdminSettingsService } from "../common/services/admin-settings.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { OrganisationService } from "../common/services/organisation.service";
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from "../common/services/toast-modal.service";
import { ConstantPool } from "@angular/compiler";
import adminLabels from "../constants/ApplicationStrings/trans_utils";
import { ApplicationService } from "../common/services/application.service";
import { ActivityService } from "../common/services/activity.service";
import { ToggleSwitchComponent } from "../common/toggle-switch/toggle-switch.component";
declare var $;

@Component({
    selector: "app-admin-settings",
    templateUrl: "./admin-settings.component.html",
    styleUrls: ["./admin-settings.component.css"],
})
export class AdminSettingsComponent implements OnInit {
    admin_settings_formdata = ADMIN_SETTINGS_FORMDATA;

    formData = new FormData();
    formGroup;
    count_more = 1;
    selectedOptionValue = [];
    selectedOptionDisplay = [];
    selectedLanguageNames = [];
    selectedOrg;
    currentTab = 1;
    // is_hash_player_pii = null;
    hash_modal = false;
    automation_settings = false;
    selected_automation_setting;
    breadcrumb;
    flag = 1;
    feature_end_index = 22;
    insight_end_index = 32;
    globalSettingMapper = new Map();
    organizationProductTypeEnterprise;
    appIds = new Set([]);

    language = [];
    translator = []

    standard = "en-AU";
    default_voice = [];
    translation_language = [];
    translation_voice = [];
    more_settings = [this.translation_language, this.translation_voice];
    settings;
    language_settings = [this.language, this.default_voice, this.more_settings];
    formArrayElem = {
        language: new FormControl(""),
        name: new FormControl(""),
        voice: new FormControl(""),
    };

    org_id;
    admin_data = adminLabels;

    activityModalheadings = [this.admin_data.user,
    this.admin_data.tableHeaderModificationDate,
    this.admin_data.originalValue,
    this.admin_data.newValue
    ]
    individualActivity: ActivityDetails;
    betaFeatureHolder = []
    constructor(
        public adminSetting: AdminSettingsService,
        public userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private organisationService: OrganisationService,
        private toastr: ToastrService,
        private titleService: Title,
        private errorResponseService: ErrorResponseService,
        private toastService: ToastModalService,
        private appService: ApplicationService,
        private activityService: ActivityService
    ) {
        this.titleService.setTitle(this.admin_data.adminSettings + " | " + "Cornerstone Guide");
        this.userService.getUserData();
        this.organisationService.getCDNData()
        this.userService.activeTab = 'admin settings';
        this.route.paramMap.subscribe((params) => {
            this.org_id = params["params"]["org_id"];
            this.userService.organization_id = this.org_id;
            if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin")) {
                this.organisationService
                    .getOrganization(this.org_id)
                    .subscribe((response) => {
                        let organization = response["data"]["organization"];

                        this.userService.organization = organization["name"];
                        this.userService.organization_id =
                            organization["organization_id"];
                        //  this.userService.user.cdn_signature = organization["cdn_signature"];

                        this.userService.organization = organization;
                        let admin_settings = JSON.parse(
                            this.userService.organization.admin_settings
                        );
                        this.userService.enableSegments =
                            admin_settings["guide_segmentation"] == "1" ? true : false;
                        this.userService.enableSSO =
                            admin_settings["sso"] == 1 ? true : false;

                        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;
                        this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;

                        this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;

                        this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;

                        let org = organization;
                        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

                        cdn_sign["cdn_signature"] = organization["cdn_signature"];
                        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) - 100;

                        this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
                        this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

                        localStorage.setItem("organization", JSON.stringify(org));
                        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
                        this.organizationProductTypeEnterprise = organization["product_type"] ? !(organization["product_type"] === "legacy") : true;
                        // this.ngOnInit();
                    });
            }
        });
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // this.userService.setBreadcrumbs(
                //     "Admin Settings",
                //     this.route.url["_value"]
                // );
                this.userService.setBreadcrumb([
                    {
                        name: this.admin_data.adminSettings,
                        route: null
                    }
                ])
                this.breadcrumb = this.userService.breadCrumbs;
                this.getLanguage();
            });
    }

    getGlobalFeatureSettings = () => {
        this.organisationService.getGlobalFeature(this.org_id).subscribe(res => {
            if (res["data"]["global_setting"]) {
                res["data"]["global_setting"].forEach(setting => {
                    this.globalSettingMapper.set(setting["feature_flag_key"],
                        new GlobalFeatureSettingHolder(setting["beta"], setting["legacy"], setting["enterprise"]));
                    if (!this.getFeatureSettingVisibility(setting["feature_flag_key"]) && setting["beta"] == 1) {
                        let isExistInFormdata = this.admin_settings_formdata.attributes.find(tempSet => setting["feature_flag_key"] === tempSet.name);
                        if (isExistInFormdata) {
                            let isExistInBetaFeatureHolder = this.betaFeatureHolder.find(fc => fc.name == isExistInFormdata.name)
                            if (!isExistInBetaFeatureHolder) {
                                this.betaFeatureHolder.push(isExistInFormdata);
                            }
                        }
                    }
                })
            }
        });
    }

    getFeatureSettingVisibility = (setting) => {
        let tempSetting = this.globalSettingMapper.get(setting);
        if (tempSetting) {
            if (tempSetting.getBeta() == 1) {
                return false;
            } else {
                if (this.organizationProductTypeEnterprise) {
                    return tempSetting.getEnterprise() == 1;
                } else {
                    return tempSetting.getLegacy() == 1;
                }
            }
        }
        return true;
    }

    getInsightsSettingVisibility = (setting) => {
        let tempSetting;
        if (setting == "insights") {
            tempSetting = this.globalSettingMapper.get("enable_insights");
        } else if (setting == "guide_insights") {
            tempSetting = this.globalSettingMapper.get("guide_insight");
        } else if (setting == "real_time_stats") {
            tempSetting = this.globalSettingMapper.get("realtime_insights");
        }
        if (this.organizationProductTypeEnterprise && tempSetting) {
            return tempSetting.getEnterprise() == 1;
        } else if (!this.organizationProductTypeEnterprise && tempSetting) {
            return tempSetting.getLegacy() == 1;
        } else {
            return true;
        }
    }

    ngOnInit() {
        this.admin_settings_formdata = JSON.parse(JSON.stringify(ADMIN_SETTINGS_FORMDATA));
        this.getGlobalFeatureSettings();
        this.insight_end_index = 32;
        let form = {};
        this.more_settings = [this.translation_language, this.translation_voice];
        this.settings = [];
        for (let i = 0; i < this.admin_settings_formdata.attributes.length; i++) {
            form[this.admin_settings_formdata.attributes[i].name] = new FormControl(
                ""
            );
            let validations = [];
            for (let v of this.admin_settings_formdata.attributes[i].validators) {
                for (let key in v) {
                    validations.push(key);
                }
            }
            let validators = [];
            for (let j = 0; j < validations.length; j++) {
                switch (validations[j]) {
                    case "required":
                        validators.push(Validators.required);
                        break;

                    case "email":
                        validators.push(Validators.email);
                        break;
                    case "regex":
                        validators.push(
                            Validators.pattern(this.admin_settings_formdata.attributes[i].validators[j]["regex"])
                        );
                        break;
                    default:
                        break;

                }
            }
            form[this.admin_settings_formdata.attributes[i].name].setValidators(
                validators
            );
        }

        form["language_settings"] = new FormGroup({
            default: new FormGroup({
                language: new FormControl(""),
                name: new FormControl(""),
                voice: new FormControl(""),
            }),
            translations: new FormArray([]),
        });

        this.formGroup = new FormGroup(form);

        this.userService.showLoader = true;
        if (
            this.userService.user.role != "superadmin" ||
            (this.userService.user.role == "superadmin" &&
                this.org_id == this.userService.organization_id)
        ) {
            this.adminSetting.getAdminSettings().subscribe((response) => {

                this.userService.showLoader = false;
                let settings = JSON.parse(response["data"]["admin_settings"]);
                this.getApplications();
                this.settings = JSON.parse(response["data"]["admin_settings"]);
                this.userService.enableSegments =
                    JSON.parse(response["data"]["admin_settings"])["guide_segmentation"] == "1" ? true : false;
                this.userService.enableSSO =
                    JSON.parse(response["data"]["admin_settings"])["sso"] == 1 ? true : false;
                this.userService.enablePlayerPackages =
                    JSON.parse(response["data"]["admin_settings"])["self_hosted_player"] == 1 ? true : false;
                this.userService.enableConnector = JSON.parse(response["data"]["admin_settings"])['api_connector'] == 1 ? true : false;

                this.userService.enableTaskList = JSON.parse(response["data"]["admin_settings"])['task_list'] == 1 ? true : false;

                this.userService.enableMediaThemes = JSON.parse(response["data"]["admin_settings"])['media_files_themes'] == 0 ? false : true;

                let keys = Object.keys(settings);
                for (let i = 0; i < keys.length; i++) {
                    if (keys[i] != "language_settings") {
                        switch (keys[i]) {
                            case "enable_dap": {
                                this.formGroup.patchValue({
                                    [keys[i]]: settings[keys[i]] !== undefined && settings[keys[i]] == '0' ? '0' : '1',
                                });
                                break;
                            }
                            default: {
                                this.formGroup.patchValue({
                                    [keys[i]]: settings[keys[i]] == undefined ? '1' : settings[keys[i]],
                                });
                                break;
                            }
                        }
                    } else if (keys[i] == "language_settings") {
                        if (settings.language_settings['default'].language) {
                            // this.selectVoice(
                            //     settings.language_settings["default"].language,
                            //     settings.language_settings["default"].name
                            // );
                            this.default_voice = [];
                            this.translator.forEach((value) => {


                                if (value.value == settings.language_settings["default"].language) {
                                    this.default_voice.push(value);
                                }
                            });
                            this.formGroup.get("language_settings").get("default").patchValue({
                                name: settings.language_settings["default"].name,
                                language: settings.language_settings["default"].language,
                                voice: settings.language_settings["default"].voice

                                // voice: settings.language_settings["default"].voice.includes('-') ? settings.language_settings["default"].voice : settings.language_settings["default"].language+'-'+settings.language_settings["default"].voice,
                            });


                            if (
                                this.formGroup
                                    .get("language_settings")
                                    .get("default")
                                    .get("name")
                                    .value.includes("English")
                            ) {
                                this.more_settings = [[], []];
                                while (
                                    this.formGroup.get("language_settings").get("translations")
                                        .length != 0
                                ) {
                                    this.formGroup
                                        .get("language_settings")
                                        .get("translations")
                                        .removeAt(0);
                                }
                                for (
                                    let j = 0;
                                    j < settings.language_settings["translations"].length;
                                    j++
                                ) {

                                    this.formGroup
                                        .get("language_settings")
                                        .get("translations")
                                        .push(
                                            new FormGroup({
                                                language: new FormControl(""),
                                                name: new FormControl(""),
                                                voice: new FormControl(""),
                                            })
                                        );

                                    this.selectedOptionValue.push(
                                        settings.language_settings["translations"][j].language
                                    );
                                    this.selectedOptionDisplay.push(
                                        settings.language_settings["translations"][j].voice
                                    );




                                    this.formGroup
                                        .get("language_settings")
                                        .get("translations")
                                    ["controls"][j].patchValue({
                                        name: settings.language_settings["translations"][j].name,
                                        language:
                                            settings.language_settings["translations"][j].language,
                                        voice: settings.language_settings["translations"][j].voice,
                                        // voice: settings.language_settings["translations"][j].voice.includes('-') ? settings.language_settings["translations"][j].voice : settings.language_settings["translations"][j].language+'-'+settings.language_settings["translations"][j].voice,
                                    });
                                    this.selectedLanguageNames.push(
                                        settings.language_settings["translations"][j].name
                                    );
                                }
                                for (
                                    let j = 0;
                                    j < settings.language_settings["translations"].length;
                                    j++
                                ) {
                                    this.more_settings[0].push(this.language);
                                    // this.more_settings[1].push(this.translator);
                                    this.more_settings[1][j] = this.translator.filter((elem) => {
                                        if (elem.value == settings.language_settings["translations"][j].language) {
                                            return elem;
                                        }
                                    });
                                }
                            }
                        }
                    }
                }
                // this.is_hash_player_pii = this.formGroup.get('hash_player_pii').value == 1 ? true : null;
                // if (this.formGroup.get('hash_player_pii').value == '1') {
                //     this.formGroup.get("hash_player_pii").disable();
                // }
                this.formGroup.patchValue({
                    insights: settings["insights"]["enabled"],
                    guide_insights: settings["insights"]["guide"],
                    app_insights: settings["insights"]["app"],
                    real_time_stats: settings["insights"]["real_time_stats"],
                    insights_priority: settings["insights"]["priority"],
                });

                this.formGroup.patchValue({
                    media_files_themes: settings['media_files_themes'] == '0' ? '0' : '1',
                    display_publish_date: settings['display_publish_date'] == '0' ? '0' : '1',
                    enable_dap: settings['enable_dap'] == '0' ? '0' : '1',
                })

                this.formGroup.patchValue({
                    auto_generate_keywords: settings['auto_generate_keywords'] == '1' ? '1' : '0'
                })

                if (this.toastService.showToast) {
                    this.toastService.showToastMessage(this.toastService.toastType);
                }
                this.FTPEndpointfield();
                if (this.formGroup.get('ftp_endpoint')) {
                    this.formGroup.patchValue({
                        ftp_endpoint: settings['ftp_endpoint']
                    })
                }

                $('[data-toggle="tooltip"]').tooltip();
            });
        }
        $('[data-toggle="tooltip"]').tooltip();
    }

    getSelectedOptionValue(dataValue, optionValue) {
        let optnVal = optionValue.split('-');
        console.log(dataValue, optnVal[2], dataValue === optnVal[2])
        if (dataValue === optnVal[2]) {
            return true;
        } else {
            return false;

        }

    }

    getLanguage() {
        if (this.userService.organization_id && this.userService.organization_id !== '000') {
            this.adminSetting.getLanguageSettings().subscribe((response) => {
                const dummyData = response['languages'];
                const dummyData_voices = response['voices'];

                for (let itr0 = 0; itr0 < dummyData.length; itr0++) {
                    this.language.push({ id: itr0, name: dummyData[itr0]['label'], value: dummyData[itr0]['value'] })
                }

                for (const data in dummyData_voices) {
                    for (let itr1 = 0; itr1 < dummyData_voices[data].length; itr1++) {
                        this.translator.push({ value: data, display: dummyData_voices[data][itr1]['label'], valueData: dummyData_voices[data][itr1]['value'] })
                    }
                }
            });
        }
    }

    addMoreSettings(name) {
        if (
            !name.includes("English")
        ) {
            if (
                this.formGroup.get("language_settings").get("translations").length > 0
            ) {
                while (
                    this.formGroup.get("language_settings").get("translations").length !=
                    0
                ) {
                    this.formGroup
                        .get("language_settings")
                        .get("translations")
                        .removeAt(0);
                }
            }
        } else {
            this.formGroup
                .get("language_settings")
                .get("translations")
                .push(
                    new FormGroup({
                        language: new FormControl("", Validators.required),
                        name: new FormControl(""),
                        voice: new FormControl("", Validators.required),
                    })
                );
            this.more_settings[0].push(this.language);
            this.more_settings[1].push([]);
            this.formGroup
                .get("language_settings")
                .get("translations")
            ["controls"][this.more_settings[0].length - 1].patchValue({
                name: "",
                language: "",
                voice: "",
            });
            this.selectedLanguageNames.push("");

        }
    }

    deleteMoreSettings(ind) {
        this.formGroup.get("language_settings").get("translations").removeAt(ind);
        this.more_settings[0].splice(ind, 1);
        this.more_settings[1].splice(ind, 1);
        this.selectedOptionValue.splice(ind, 1);
        this.selectedOptionDisplay.splice(ind, 1);
        let languages = this.formGroup.get("language_settings").get("translations")
            .value;
        for (
            let i = 0;
            i <
            this.formGroup.get("language_settings").get("translations")["controls"]
                .length;
            i++
        ) {
            this.formGroup
                .get("language_settings")
                .get("translations")
            ["controls"][i].patchValue({
                name: languages[i].name,
                language: languages[i].language,
                voice: languages[i].voice,
            });
            this.selectedOptionValue[i] = languages[i].language;
            this.selectedOptionDisplay[i] = languages[i].voice;
        }
        this.formGroup.updateValueAndValidity();

        this.selectedLanguageNames.splice(ind, 1);
    }

    setSelected(val, ind) {
        this.selectedLanguageNames[ind] = val;
    }

    getKeyVal(event) {
        return event.charCode >= 48 && event.charCode <= 57
    }

    submitSettings() {
        let formValue = this.formGroup.getRawValue();
        delete formValue["app_insights"];
        delete formValue["guide_insights"];
        delete formValue["real_time_stats"];
        delete formValue["beta_features"];
        this.userService.user.role !== 'superadmin' && delete formValue['enable_user_session_tracking']
        this.adminSetting.save_button.disable = true;
        this.adminSetting.save_button.text = this.admin_data.savingButtonText;
        formValue["insights"] = {
            enabled: this.formGroup.get("insights").value,
            guide: this.formGroup.get("guide_insights").value,
            app: {},
            real_time_stats: this.formGroup.get("real_time_stats").value,
            priority: this.formGroup.get("insights_priority").value,
        };

        this.appIds.forEach((val) => {
            if (this.formGroup.get(val + "_page_tracking").value) {
                formValue["insights"]["app"][val] = {}
                formValue["insights"]["app"][val]["enable_page_tracking"] = parseInt(this.formGroup.get(val + "_page_tracking").value)
                formValue["insights"]["app"][val]["enable_feature_tracking"] = parseInt(this.formGroup.get(val + "_feature_tracking").value)
            }
            delete formValue[val + "_page_tracking"]
            delete formValue[val + "_feature_tracking"]
        })
        let val = JSON.stringify(formValue);
        let request = {
            admin_settings: val,
            organization_id: parseInt(this.userService.organization_id),
        };
        let req = JSON.stringify(request);
        this.userService.showLoader = true;
        this.adminSetting.updateAdminSettings(req).subscribe((response) => {
            if (response['error'] == false) {
                this.userService.showLoader = false;
                this.adminSetting.save_button.disable = false;
                this.adminSetting.save_button.text = this.admin_data.saveSettingsButtonText;
                // this.router.navigate([
                //     "organization",
                //     this.userService.organization_id,
                // ]);
                this.toastService.showToast = true;
                this.toastService.toastType = "admin_settings";
                // this.userService.enablePlayerPackages = this.formGroup.get("insights").value == "1" ? true : false
            } else {
                this.userService.showLoader = false;
                this.adminSetting.save_button.disable = false;
                this.adminSetting.save_button.text = this.admin_data.saveSettingsButtonText;
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }
            this.ngOnInit();
        });
    }


    // testSettings(){
    // }

    toggle(j, checked, event?: Event) {
        // this.admin_settings_formdata.attributes[j].checked = !this
        //     .admin_settings_formdata.attributes[j].checked;
        if (this.admin_settings_formdata.attributes[j].name != "create_tooltip_media_file") {
            this.formGroup.patchValue({
                [this.admin_settings_formdata.attributes[j].name]:
                    checked == true ? 1 : 0,
            });
        } else {
            if (this.formGroup.get("mediafile_creation").value == 1) {
                this.formGroup.patchValue({
                    'create_tooltip_media_file': checked == true ? 1 : 0,
                });
            } else {
                this.formGroup.patchValue({
                    'create_tooltip_media_file': 0,
                });
                event.preventDefault();
                this.toastService.showToastMessage("media_file_creation_tooltip_guide_disable");
            }
        }

        if (this.admin_settings_formdata.attributes[j].name == 'mediafile_creation' && !checked) {
            if (this.formGroup.get("create_tooltip_media_file").value == 1) {
                this.formGroup.patchValue({
                    "create_tooltip_media_file": 0
                });
            }
        }

        // if (this.admin_settings_formdata.attributes[j].name == 'maintenance_mode' && checked) {
        //     this.showWarning();
        // }

        // else if (this.admin_settings_formdata.attributes[j].name == 'hash_player_pii' && checked) {
        //     this.hash_modal = true;
        //     this.automation_settings = false;
        //     this.showConfirmation();
        // }

        else if (this.admin_settings_formdata.attributes[j].name == 'insights') {
            if (checked) {
                this.formGroup.patchValue({
                    'guide_insights': 1,
                    'app_insights': 0
                })
            } else {
                this.formGroup.patchValue({
                    'guide_insights': 0,
                    'app_insights': 0,
                    'real_time_stats': 0
                })
            }
        }

        else if (this.admin_settings_formdata.attributes[j].name == 'guide_insights' || this.admin_settings_formdata.attributes[j].name == 'app_insights') {
            if (this.formGroup.get("guide_insights").value == 0 && this.formGroup.get("app_insights").value == 0) {
                this.formGroup.patchValue({
                    'insights': 0,
                    'real_time_stats': 0
                })
            }

            if (this.formGroup.get("guide_insights").value == 0) {
                this.formGroup.patchValue({
                    'real_time_stats': 0
                })
            }

            if (this.formGroup.get("guide_insights").value == 1 || this.formGroup.get("app_insights").value == 1) {
                this.formGroup.patchValue({
                    'insights': 1
                })
            }
        }

        else if (this.admin_settings_formdata.attributes[j].name == 'real_time_stats') {
            if (this.formGroup.get("real_time_stats").value == 1) {
                this.formGroup.patchValue({
                    'guide_insights': 1,
                    'insights': 1
                })
            }
        }

        else if (this.admin_settings_formdata.attributes[j].name == 'on_premise_deployment') {

            this.FTPEndpointfield();
        }

        // else if((this.admin_settings_formdata.attributes[j].name=='myBotEnabled' || this.admin_settings_formdata.attributes[j].name=='workflowAutomationEnabled') && this.formGroup.get("myBotEnabled").value=='1' && this.formGroup.get("workflowAutomationEnabled").value=='1'){
        //     this.hash_modal=false;
        //     this.automation_settings=true;
        //     this.selected_automation_setting=this.admin_settings_formdata.attributes[j].name;
        //     this.showConfirmation();
        // }
        else if (this.admin_settings_formdata.attributes[j].name.split("_")[1] == 'feature') {
            if (this.formGroup.get(this.admin_settings_formdata.attributes[j].name).value == 1) {
                let page = this.admin_settings_formdata.attributes[j].name.split("_");
                page[1] = "page"
                this.formGroup.patchValue({
                    [page.join("_")]: 1
                })
            }
        }
        else if (this.admin_settings_formdata.attributes[j].name.split("_")[1] == 'page') {
            if (this.formGroup.get(this.admin_settings_formdata.attributes[j].name).value == 0) {
                let feature = this.admin_settings_formdata.attributes[j].name.split("_");
                feature[1] = "feature"
                this.formGroup.patchValue({
                    [feature.join("_")]: 0
                })
            }
        }
        else {
            this.hash_modal = false;
            this.automation_settings = false;
        }
    }

    FTPEndpointfield() {
        // this.feature_end_index=20;
        // this.insight_end_index=24;
        if (this.formGroup.get("on_premise_deployment").value == 1) {
            // this.feature_end_index+=1;
            // this.insight_end_index+=1;

            // let ftp_endpoint_box:any={
            //     type: "text",
            //     name: "ftp_endpoint",
            //     value: "",
            //     formtype: "admin_inline",
            //     class: "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 my-2 font14",
            //     validators: [
            //       {
            //         required: true,
            //         pattern:`(ftp)://[\\w-]+(\\.[\\w-]+)+([\\w.,@?^=%&amp;:/~+#-]*[\\w@?^=%&amp;/~+#-])?`
            //       },
            //     ],
            //     disable:false,
            //     tooltip:'Enter a valid FTP address starting with ftp://. eg: ftp://1.2.3.4:21 or ftp://abc.example.com:21',
            //     ui: {
            //       label: "FTP endpoint to save content",
            //       class: "mb-0",
            //       inputclass: "form-control inline-field",
            //       maxLength: 5
            //     },
            //   }
            // this.admin_settings_formdata.attributes.splice(15,0,ftp_endpoint_box);
            // this.formGroup.addControl('ftp_endpoint',new FormControl('',[Validators.required,Validators.pattern('(ftp)://[\\w-]+(\\.[\\w-]+)+([\\w.,@?^=%&amp;:/~+#-]*[\\w@?^=%&amp;/~+#-])?')]));
            this.admin_settings_formdata.attributes[25].disable = false;
            this.formGroup.get('ftp_endpoint').setValidators([Validators.required, Validators.pattern(`(ftp)://[\\w-]+(\\.[\\w-]+)+([\\w.,@?^=%&amp;:/~+#-]*[\\w@?^=%&amp;/~+#-])?`)]);
            this.formGroup.get('ftp_endpoint').patchValue(null);
            this.formGroup.get('ftp_endpoint').markAsTouched();
            this.formGroup.get('ftp_endpoint').markAsDirty();
            this.formGroup.updateValueAndValidity();
        } else {
            // this.feature_end_index=24;
            // this.insight_end_index=29;
            if (this.formGroup.get('ftp_endpoint')) {
                // this.admin_settings_formdata.attributes.splice(15,1);
                this.admin_settings_formdata.attributes[25].disable = true;
                this.formGroup.get('ftp_endpoint').setValidators([]);
                this.formGroup.updateValueAndValidity();
                this.formGroup.get('ftp_endpoint').patchValue(null);
                // this.formGroup.removeControl('ftp_endpoint');
            }
        }
        setTimeout(() => { $('[data-toggle="tooltip"]').tooltip() }, 200);
    }

    selectVoice(language, name = "") {
        if (name == "") {
            name = language.name;
            language = language.value;
            this.formGroup.get('language_settings').get('default').patchValue({
                name: name,
                language: language,
                voice: ""
            });
        }
        if (!name.includes("English")) {
            if (
                this.formGroup.get("language_settings").get("translations").length > 0
            ) {
                while (
                    this.formGroup.get("language_settings").get("translations").length !=
                    0
                ) {
                    this.formGroup
                        .get("language_settings")
                        .get("translations")
                        .removeAt(0);
                }
                this.more_settings[0] = [];
                this.more_settings[1] = [];
            }
        } else {
            if (this.formGroup.get("language_settings").get("translations").length == 0) {
                this.addMoreSettings(name);
            }
        }
        this.default_voice = [];
        this.translator.forEach((value) => {
            if (value.value == language) {
                this.default_voice.push(value);
            }
        });
    }

    selectTranslationVoice(event, ind) {
        let { value: language, name } = event
        this.flag = 0;
        this.selectedLanguageNames[ind] = name;
        this.more_settings[1][ind] = [];

        this.translator.forEach((value) => {
            if (value.value == language) {
                console.log(value);
                this.more_settings[1][ind].push(value);
            }
        });
        this.formGroup
            .get("language_settings")
            .get("translations")
        ["controls"][ind].patchValue({
            name: this.selectedLanguageNames[ind],
            voice: "",
            language: language
        });

        setTimeout(() => {
            this.flag = 1;
        }, 500);
    }

    unassign() {
        this.router.navigate([
            "organization",
            this.userService.organization_id,
        ]);
    }

    changeTab(val) {
        this.currentTab = val;
    }

    showWarning() {
        $("#warningmodal").modal("show");
    }

    showConfirmation() {
        $("#confirm_modal").modal("show");
    }

    resetAndClose() {
        // this.formGroup.patchValue({
        //     ["hash_player_pii"]: 0,
        // });

        if (this.formGroup.get("myBotEnabled").value == '1' && this.formGroup.get("workflowAutomationEnabled").value == '1') {
            this.formGroup.patchValue({
                [this.selected_automation_setting]: 0
            })
        }

        $("#confirm_modal").modal("hide");
    }

    existingLanguage() {
        let more_languages = JSON.parse(JSON.stringify(this.formGroup.get('language_settings').get('translations').value));
        more_languages.push(JSON.parse(JSON.stringify(this.formGroup.get('language_settings').get('default').value)));
        return more_languages;
        // let found = false;
        // more_languages.forEach((val) => {
        //     if (val['language'] == language) {
        //         found = true;
        //     }
        // })
        // if (found) {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    getApplications() {
        this.appService.getApplications(1000, 0).subscribe((response) => {
            if (this.insight_end_index === 32) {  //to avoid duplication of applications in table
                let curr_index = this.insight_end_index - 3;
                let apps = response['data']['applications'];
                for (let index = 0; index < apps.length; index++) {
                    let element = apps[index]
                    this.appIds.add(element['application_id'])
                    let pageTracking =
                    {
                        type: "checkbox",
                        name: element['application_id'] + "_page_tracking",
                        class: "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 mr-0 mr-sm-0 mr-md-0 font14 displayFlex alignItemsCenter height50px",
                        value: "",
                        checked: false,
                        validators: [],
                        ui: {
                            label: element['title'],
                            class: "mb-0 pl-2 width100",
                            inputclass: "primary",
                        },
                    }
                    this.admin_settings_formdata.attributes.splice(curr_index, 0, pageTracking);
                    this.formGroup.addControl(element['application_id'] + "_page_tracking", new FormControl(""))
                    this.insight_end_index++;
                    curr_index++;

                    let featureTracking =
                    {
                        type: "checkbox",
                        name: element['application_id'] + "_feature_tracking",
                        class: "col-sm-4 col-md-4 col-lg-4 col-xl-4 py-2 bg-checkbox my-1 my-sm-1 my-md-1 my-lg-0 my-xl-0 font14 displayFlex alignItemsCenter height50px",
                        value: "",
                        checked: false,
                        validators: [],
                        ui: {
                            label: "",
                            class: "mb-0",
                            inputclass: "primary",
                        },
                    }

                    this.admin_settings_formdata.attributes.splice(curr_index, 0, featureTracking);
                    this.formGroup.addControl(element['application_id'] + "_feature_tracking", new FormControl(""))
                    this.insight_end_index++;
                    curr_index++;
                }
                this.patchApplicationInsightsValue();
            }
        })

    }

    patchApplicationInsightsValue() {
        this.appIds.forEach((element) => {
            if (this.settings["insights"] == undefined || this.settings["insights"]["app"] == undefined || this.settings["insights"]["app"][element] == undefined || this.settings["insights"]["app"][element]["enable_page_tracking"] == undefined) {
                this.formGroup.patchValue({
                    [element + "_page_tracking"]: 0
                })
            }
            else {
                this.formGroup.patchValue({
                    [element + "_page_tracking"]: parseInt(this.settings["insights"]["app"][element]["enable_page_tracking"])
                })
            }

            if (this.settings["insights"] == undefined || this.settings["insights"]["app"] == undefined || this.settings["insights"]["app"][element] == undefined || this.settings["insights"]["app"][element]["enable_feature_tracking"] == undefined) {
                this.formGroup.patchValue({
                    [element + "_feature_tracking"]: 0
                })
            }
            else {
                this.formGroup.patchValue({
                    [element + "_feature_tracking"]: parseInt(this.settings["insights"]["app"][element]["enable_feature_tracking"])
                })
            }
        });
    }

    toggleFeature = (settingName, isChecked) => {
        this.formGroup.patchValue({ [settingName]: isChecked ? '1' : '0' })
    }

    openActivityModal(key: string) {
        $("#activityModal").modal("show");
        this.getActivityFor(key)
    }

    getActivityFor(key: string) {
        const emptyActivity: ActivityDetails = {
            name: "",
            modificationDate: "",
            oldValue: "",
            newValue: ""
        };
        this.individualActivity = undefined;
        return this.activityService.getIndividualActivity(key, this.org_id).subscribe((response) => {
            if (response['error'] == true) {
                $("#activityModal").modal("hide");
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            } else {
                let dataObj = response['data']['activity'];
                this.individualActivity = emptyActivity;
                if (Object.keys(dataObj).length != 0) {
                    this.individualActivity.name = dataObj['first_name'] + " " + dataObj['last_name'];
                    this.individualActivity.modificationDate = dataObj['modification_date'];
                    this.individualActivity.oldValue = dataObj['original_value'];
                    this.individualActivity.newValue = dataObj['new_value'];
                }
            }
        }, (Error) => {

            $("#activityModal").modal("hide");
        })
    }

    removeToolTip() {
        $('.tooltip').remove();
    }

    showToolTip() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    checkRole(name): boolean {
        if (name === 'enable_user_session_tracking' && this.userService.user.role !== 'superadmin') {
            return false;
        }
        return true;
    }

    getSortedByDisplay = (default_voice) => default_voice.sort((a, b) => a.display.localeCompare(b.display));
}

interface ActivityDetails {
    name: string,
    modificationDate: string,
    oldValue: string,
    newValue: string
}



class GlobalFeatureSettingHolder {
    beta; legacy; enterprise;
    constructor(beta, legacy, enterprise) {
        this.beta = this.getValue(beta);
        this.legacy = this.getValue(legacy);
        this.enterprise = this.getValue(enterprise);
    }
    getValue = val => val !== undefined ? val : 0;
    getBeta = () => this.beta;
    getLegacy = () => this.legacy;
    getEnterprise = () => this.enterprise;
}
