<div class="d-flex">
	<div class="container-fluid content-container p-3 toggle-view"
		[ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
		<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
		<section class="component-section">

			<div class="sectionBody">

				<div class="d-flex mb-3">
					<div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0 sectionHeader">
						{{this.admin_data.labelTaskList}}
					</div>
					<div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right displayFlex align-items-center justify-content-end">
						<svg-icon class="text-left" src="../../../assets/svgs/icon-info.svg" class="ml-2 infoIcon"
							data-html="true" data-toggle="tooltip" data-placement="bottom"
							title="{{getcontent()}} "></svg-icon>
						<div class="input-group w-50 ml-2 d-inline-flex width-300">
							<input id="searchInputApp" class="form-control py-2 search" type="search"
								placeholder={{this.admin_data.searchPlaceholder}} autocomplete="off"
								(input)="validateInput($event, true)" (search)="searchTaskList()" aria-label="Search"
								[(ngModel)]="search">
							<!-- <span class="input-group-append">
								<div class="input-group-text bg-white">
									<svg-icon src="../../assets/svgs/search-icon.svg"></svg-icon>
								</div>
							</span> -->
						</div>
						<button type="button" class="btn btn-primary ml-2"
							(click)="showAddTaskListModal()">{{this.admin_data.taskListAddTaskButtonText}}</button>
					</div>
				</div>



				<app-table [data]="tasks" [titles]="tasksService.titles" [dataKeys]="tasksService.DataKeys"
					[dataCopy]="tasks" [widths]="tasksService.col_widths" (edit)="getTaskListData($event)"
					(delete)="showModal($event)" (changePublishStatus)="changePublishStatus($event)"
					(showLeaderBoard)="getLeaderBoardData($event)" (visibility)="getVisibilityData($event)">
				</app-table>
				<app-pagination [offset]="offset" [totalRecords]="paginationService.total"
					[currentPage]="paginationService.currentPage" [limit]="limit" (gotoFirstPage)="gotoFirstPage()"
					(gotoLastPage)="gotoLastPage($event)" (gotoPreviousPage)="prependOffsetAndGetTasks()"
					(gotoNextPage)="appendOffsetAndGetTasks()" (changeLimit)="changeLimit($event)"
					(gotoAction)="gotoPage($event)"></app-pagination>
				<br>
				<ng-container *ngIf="tasks">
					<ng-container *ngIf="tasks.length>0">
                        <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent"
                            [modal_ok_value]="this.userService.delete_button.text" [org]=""
                            [disabled]="this.userService.delete_button.disable"
                            (onDelete)="deleteTask($event)"></app-modal-confirmation>
					</ng-container>
				</ng-container>
			</div>
		</section>

		<!--Add/Edit Task List Modal-->
		<div class="modal fade" id="addTaskListModal" tabindex="-1" role="dialog"
			aria-labelledby="addTaskListModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="addTaskListModalLabel">
							<ng-container *ngIf="tasksService.isEdit">{{this.admin_data.updateTaskList}}</ng-container>
							<ng-container *ngIf="!tasksService.isEdit">{{this.admin_data.addTaskList}}</ng-container>
						</h5>
						<button (click)="closeAddTaskListModal()" type="button" class="close" data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<app-form [formData]="taskList_formData" [formGroup]="formGroup"></app-form>
					</div>
					<div class="modal-footer justify-content-center">
						<!-- <button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
              (click)="closeAddTaskListModal()">{{this.admin_data.cancelButtonText}}</button> -->
						<app-button [buttonData]="tasksService.cancel_button" (clickhandler)="closeAddTaskListModal()">
						</app-button>
						<!-- <button type="button" class="btn btn-primary minWidth-120"
              (click)="tasksService.isEdit ? editTaskList() : addTaskList()" [disabled]="!formGroup.valid">
              <ng-container *ngIf="tasksService.isEdit">Update Task</ng-container>
              <ng-container *ngIf="!tasksService.isEdit">Add Task</ng-container>
            </button> -->
						<app-button *ngIf="tasksService.isEdit" [disabled]="!formGroup.valid"
							[buttonData]="tasksService.update_button" (clickhandler)="editTaskList()"></app-button>
						<app-button *ngIf="!tasksService.isEdit" [disabled]="!formGroup.valid"
							[buttonData]="tasksService.add_button" (clickhandler)="addTaskList()"></app-button>
					</div>
				</div>
			</div>
		</div>
		<!--End of Add/Edit Task List Modal-->
		<!--Leader Board Modal-->
		<div class="modal fade" id="leaderBoardModal" tabindex="-1" role="dialog"
			aria-labelledby="leaderBoardModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered lb-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header displayFlex">
						<div class="header-div">
							<h5 class="modal-title m-3 marginLR-0" id="leaderBoardModalLabel">
								{{this.admin_data.taskListTableHeaderLeaderboard}}
							</h5>
						</div>

						<div class="displayFlex task-contentDiv">
							<div *ngIf="tasksService.openLeaderBoard" class="displayFlex task-contentDiv innerDiv">
								<div class="displayFlex partitionDiv borderR">
									<span class="title taskTitle">{{this.admin_data.manageContentVariable1}}
										<b>{{tasksService.selectedTask.title}}</b></span>
								</div>
								<div class="displayFlex partitionDiv borderL">
									<span class="title taskTitle">{{this.admin_data.taskListTableHeaderApplication}}:
										<b>{{tasksService.selectedTask.application_title}}</b></span>

								</div>
							</div>
						</div>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body m-h-560px">
						<div>
							<div class="col-sm-8 col-md-6 col-lg-5 col-xl-5 my-3 full-width">
								<div class="input-group">
									<span class="input-group-append">
										<div
											class="border-right-0 input-group-text bg-white rounded-left custom-border-color">
											<svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
										</div>
									</span>
									<input id="searchInputUser"
										class="form-control border-left-0 border-radius-4 custom-border-color"
										type="search" autocomplete="off"
										placeholder={{this.admin_data.searchPlaceholder}}
										(input)="validateInput($event, false)" (search)="searchUser()"
										aria-label="Search" [(ngModel)]="search_user">
								</div>
							</div>
							<div class="spinner-wrapper" *ngIf="visible" id="loader{{i}}">
								<img class="spinner-img img-fluid" src="../../../assets/images/g_new_loader.gif" />
							</div>
							<div class="col-sm-8 col-md-6 col-lg-5 col-xl-5 my-3 full-width overflow-auto h-350px"
								[class.hidden]="visible">
								<app-table [data]="leader_board_data" [titles]="leaderBoardService.titles"
									[widths]="leaderBoardService.col_widths" [dataKeys]="leaderBoardService.DataKeys"
									[dataCopy]="leader_board_data" [anotherOne]="'anotherDataTable'"
									[min-width]="'100%'">
								</app-table>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<ul *ngIf="modalPaginationService.pageLimit>1 && !(modalPaginationService.disableNext && modalPaginationService.disablePrev) && modalPaginationService.showPagination"
							class="pagination my-4 float-right">
							<li class="page-item mx-1" [class.disabled]="modalPaginationService.disablePrev">
								<button class="page-link rounded" (click)="prependOffsetAndGetUsers()">
									<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14"
										fill="none">
										<path
											d="M8.50781 2.0275L3.54615 7L8.50781 11.9725L6.98031 13.5L0.480312 7L6.98031 0.5L8.50781 2.0275Z"
											fill="#8E8FA6" />
									</svg>
								</button>
							</li>
							<ng-container *ngFor="let page of modalPaginationService.pages">
								<li class="page-item mx-1"
									[ngClass]="{'active':page==modalPaginationService.currentPage}">
									<a class="page-link rounded" (click)="setUserIndex(page)">{{page}}</a>
								</li>
							</ng-container>
							<li class="page-item mx-1" [class.disabled]="modalPaginationService.disableNext">
								<button class="page-link rounded" (click)="appendOffsetAndGetUsers()">
									<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14"
										fill="none">
										<path
											d="M0.492188 11.9725L5.45385 7L0.492188 2.0275L2.01969 0.5L8.51969 7L2.01969 13.5L0.492188 11.9725Z"
											fill="#8E8FA6" />
									</svg>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!--End of Leader Board Modal-->
		<!--Visibility Modal-->
		<div class="modal fade" id="visibilityModal" tabindex="-1" role="dialog" aria-labelledby="visibilityModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered lb-dialog h-100" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="visibilityModalLabel">{{this.admin_data.visibility}}</h5>
						<button (click)="closeVisibilityModal()" type="button" class="close" data-dismiss="modal"
							aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div [formGroup]="visibilityFormGroup" class="m-h-420px overflow-auto">
							<ng-container formArrayName="groups" *ngFor="let group of elem_groups;let j=index">
								<div class="btn-border border-light">
									<div *ngIf="j==0" class="rule-title-container">Rules</div>
									<ng-container [formGroupName]="j">
										<ng-container formArrayName="attributes" *ngFor="let elem of group;let i=index">
											<div *ngIf="(i>0)"
												class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 my-2 and-or-text">
												{{this.admin_data.and}}</div>
											<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 d-inline-flex"
												[formGroupName]="i" #attributes>
												<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 my-2">
													<!-- <select formControlName="rule_type" class="form-control"
														(change)="applyRuleTypeValidation(visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i],$event.target.value)">
														<option *ngFor="let option of rule_type_options"
															value="{{option}}">
															{{option}}
														</option>
													</select> -->

													<app-custom-select formControlName="rule_type"
														[dataList]="rule_type_options"
														(updateDataList)="applyRuleTypeValidation(visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i],$event)"
														componentName="default" dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
													<!-- </ng-container> -->
													<!-- <ng-container *ngIf=";else otheroptions"> -->
													<!-- <ng-template #otheroptions>
														<option *ngFor="let option of rule_type_options_hash" value="{{option}}">{{option}}
														</option>
													</ng-template> -->
												</div>
												<div *ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i]['controls']['rule_type'].value == 'Variables'"
													class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 my-2">
													<!-- <select
														[value]="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_variable_name').value"
														formControlName="rule_variable_name" class="form-control">
														<option *ngFor="let option of rule_variable_options"
															value="{{option}}">{{option}}
														</option>
													</select> -->

													<app-custom-select formControlName="rule_variable_name"
														[dataList]="rule_variable_options"
														[selected]="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_variable_name').value"
														componentName="default" dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
												</div>
												<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 my-2">
													<!-- <select
														[value]="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_condition').value"
														formControlName="rule_condition" class="form-control">
														<option *ngFor="let option of visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_type').value=='URL Parameters'
															?rule_condition_options_url_param
															:visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_type').value=='Variables'?rule_condition_options_variables
															:rule_condition_options" value="{{option}}">{{option}}
														</option>
													</select> -->

													<app-custom-select formControlName="rule_condition"
														[dataList]="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_type').value=='URL Parameters'
														?rule_condition_options_url_param
														:visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_type').value=='Variables'?rule_condition_options_variables
														:rule_condition_options"
														[selected]="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_condition').value"
														componentName="default" dropdownPosition="inherit" [ngStyle]="{ 'position': 'relative' }"></app-custom-select>
												</div>
												<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 my-2">
													<input
														[value]="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').value"
														autocomplete="off" formControlName="rule_value" type="text"
														class="form-control">
													<ng-container
														*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').touched || visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').dirty">
														<small class="error color"
															*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').hasError('pattern')">
															<span
																*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_type').value=='URL Path'">{{this.admin_data.taskVariable1}}
															</span>
														</small>
														<small class="error color"
															*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').hasError('required')">
															<span>{{this.admin_data.taskVariable2}}</span>
														</small>
														<small class="error color"
															*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').errors?.doubleslashValidator">
															<span>{{this.admin_data.taskVariable3}}</span>
														</small>
														<small class="error color"
															*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').errors?.angularTileAndtildaEqual">
															{{this.admin_data.taskVariable4}}
														</small>
														<small class="error color"
															*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').errors?.invalidhyphenunderscore">
															{{this.admin_data.taskVariable5}}
														</small>
														<small class="error color"
															*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').errors?.tildaEqualNotAlone">
															{{this.admin_data.taskVariable6}}
														</small>
														<span
															*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_type').value=='URL Parameters' && visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').hasError('pattern')">
															<small class="error color">
																<ng-container
																	*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').value.charAt(0)!='?'">
																	{{this.admin_data.taskVariable7}}
																</ng-container>
																<ng-container
																	*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').value.charAt(0)=='?' && visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').value.indexOf('&')!=-1">
																	{{this.admin_data.taskVariable8}}
																</ng-container>
																<ng-container
																	*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').value.charAt(0)=='?' && visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').value.indexOf('&')==-1">
																	{{this.admin_data.taskVariable9}}
																</ng-container>
															</small>
														</span>
														<span
															*ngIf="visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_type').value=='URL Hash' && visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes']['controls'][i].get('rule_value').hasError('pattern')">
															<small
																class="error color">{{this.admin_data.taskVariable10}}
															</small>
														</span>
													</ng-container>
												</div>
												<ng-container *ngIf="tasksService.selectedTask">
													<div *ngIf="i==0 && tasksService.selectedTask.is_publish=='0'"
														class="my-2 d-flex align-items-center justify-content-center">
														<svg-icon src="../../../assets/svgs/add_lang.svg"
															class="cursor pointer" (click)="addAttribute(j)">
														</svg-icon>
													</div>
													<div *ngIf="group.length>1 && tasksService.selectedTask.is_publish=='0'"
														class="col-lg-2 my-2 d-flex align-items-center justify-content-center">
														<svg-icon src="../../../assets/svgs/org_delete.svg"
															class="cursor pointer" (click)="remove(j,i)">
														</svg-icon>
													</div>
												</ng-container>
											</div>
										</ng-container>
									</ng-container>
								</div>
								<ng-container *ngIf="tasksService.selectedTask">
									<div class="d-flex w-100 justify-content-end">
										<div *ngIf="j==(elem_groups.length-1) && tasksService.selectedTask.is_publish=='0'"
											class="p-2 d-flex align-items-center">
											<button class="btn-border border-light btn pl-2 height45px"
												(click)="addGroup()">
												<svg-icon src="../../../assets/svgs/add_lang.svg"
													class="cursor pointer">
												</svg-icon>
												{{this.admin_data.addGroup}}
											</button>
										</div>
										<div *ngIf="elem_groups.length>1 && tasksService.selectedTask.is_publish=='0'"
											class="p-2 float-right d-flex align-items-center">
											<button class="btn-border border-light btn pl-2 height45px"
												(click)="removeGroup(j)">
												<svg-icon src="../../../assets/svgs/org_delete.svg"
													class="cursor pointer">
												</svg-icon>
												{{this.admin_data.removeGroup}}
											</button>

										</div>
									</div>
								</ng-container>
								<div *ngIf="j<(elem_groups.length-1)" class="and-or-text">{{this.admin_data.or}}</div>
							</ng-container>
						</div>
						<br />
						<div class="btn-border border-light">
							<div class="segment-container">
								<div class="segment-title-container">
									Segmentation
								</div>
								<div class="segment-list-container" *ngIf="segmentData.length>0">
									<!-- <select class="segment-data">
										<option *ngFor="let option of segmentData" value="{{option}}">{{option}}
										</option>
									</select> -->
									<div style="position: relative;">
										<!-- <ng-multiselect-dropdown class="p-0 font14 text-nowrap" 
                                    [settings]="dropdownSettings" [placeholder]="segmentText"
										[data]="segmentData" [(ngModel)]="selectedItems"
                                        [disabled] = "tasksService.selectedTask && tasksService.selectedTask.is_publish=='1'"
										(onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)"
										(onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
										Select
									</ng-multiselect-dropdown> -->
										<app-multi-checkbox-dropdown componentName="tasklist" [appList]="segmentData"
											[placeholder]="segmentText" (updateAppList)="updateAppList($event)"
											selectAll="true"
											[isDisabled]="tasksService.selectedTask && tasksService.selectedTask.is_publish=='1'"
											position="up"></app-multi-checkbox-dropdown>
									</div>


								</div>
							</div>
						</div>
					</div>
					<ng-container *ngIf="tasksService.selectedTask">
						<div *ngIf="tasksService.selectedTask.is_publish=='0'"
							class="modal-footer justify-content-center">
							<button type="button" class="btn btn-secondary minWidth-120" data-dismiss="modal"
								(click)="closeVisibilityModal()">{{this.admin_data.cancelButtonText}}</button>
							<button type="button" class="btn btn-primary minWidth-120"
								(click)="saveVisibilitySettings()" [disabled]="validateVisibilityForm()">
								{{this.admin_data.saveButtonText}}
							</button>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<!--End of Visibility Modal-->
	</div>
</div>