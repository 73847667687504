if (GmXt === undefined) var GmXt = {};
GmXt.playerLbls = {};
GmXt.playerLbls.fr_FR = {
    "myGuideTitle": "MyGuide",
    "addButtonText": "Ajouter",
    "addingButtonText": "Ajout en cours...",
    "cancelButtonText": "Annuler",
    "okButtonText": "OK",
    "updateButtonText": "Mettre à jour",
    "updatingButtonText": "Mise à jour en cours...",
    "deleteButtonText": "Supprimer",
    "deletingButtonText": "Suppression en cours...",
    "uploadButtonText": "Téléverser",
    "uploadingButtonText": "Téléversement en cours…",
    "saveButtonText": "Enregistrer",
    "savingButtonText": "Enregistrement en cours…",
    "saveSettingsButtonText": "Enregistrer les paramètres",
    "noDataFoundText": "Donnée introuvable !",
    "generateButtonText": "Générer",
    "generatingButtonText": "Génération en cours...",
    "actionDeclined": "Action refusée !",
    "searchPlaceholder": "Rechercher",
    "searchNameIdPlaceholder": "Rechercher le prénom/nom, l’identifiant d’email",
    "selectDatesPlaceholder": "Sélectionner des dates",
    "enterIDPAttributeNamePlaceholder": "Entrez le nom de l’attribut IDP",
    "domainFormatPlaceholder": "nom@domain.com",
    "error": "Erreur",
    "yes": "Oui",
    "no": "Non",
    "all": "Tout",
    "web": "Web",
    "desktop": "Bureau",
    "android": "Android",
    "ios": "IOS",
    "mobileWeb": "Web mobile",
    "user": "Utilisateur",
    "currentAdminPassword": "Mot de passe actuel de l’Admin",
    "note": "Remarque :",
    "domainMessage": "L’IDP de l’authentification SSO est lié à une organisation par défaut. Par conséquent, l’URL de l’authentification SSO par défaut sera déclenchée si l’utilisateur visite un domaine Web et que la session reste introuvable. Dans cette section, vous pouvez lier l’URL de l’authentification SSO à un domaine particulier, afin que cette URL ne se déclenche que sur le domaine associé. De cette façon, une organisation peut avoir plusieurs IDP d’authentification SSO sur plusieurs domaines.",
    "refresh": "Actualiser",
    "iconWorks": "L’icône est opérationnelle !",
    "pageNotWorks": "La mention page_introuvable est opérationnelle !",
    "valid": "Valide.",
    "select": "Sélectionner",
    "selectAll": "Sélectionner tout",
    "guideTitle": "Titre du Guide",
    "copy": "Copier",
    "copied": "Copie effectuée !!",
    "on": "Activé",
    "off": "Désactivé",
    "publish": "Publier",
    "unpublished": "Non publié",
    "locked": "Verrouillé",
    "unlocked": "Déverrouillé",
    "superAdmin": "Super Admin",
    "download": "Télécharger",
    "noDataFound": "Donnée introuvable",
    "and": "Et",
    "guideRoleAdministrator": "Organisation Admin",
    "guideRoleCreator": "Auteur",
    "guideRolePlayer": "Consultant",
    "guideRoleAppAdmin": "Admin d’application",
    "tableHeaderStatus": "État",
    "tableHeaderProgress": "Avancement",
    "tableHeaderTitle": "Titre",
    "tableHeaderType": "Type",
    "tableHeaderCreationDate": "Date de création",
    "tableHeaderActions": "Actions",
    "tableHeaderMarkAll": "Marquer tout",
    "tableHeaderDescription": "Description",
    "pleaseSelect": "Veuillez sélectionner",
    "filterBy": "Filtrer par",
    "close": "Fermer",
    "clear": "Effacer",
    "apply": "Appliquer",
    "patternInvalid": "Le modèle est non valide",
    "isRequired": "est obligatoire",
    "confirmNewPassword": "Confirmer le nouveau mot de passe",
    "newPassword": "Nouveau mot de passe",
    "currentPassword": "Mot de passe actuel",
    "addApplicationAddBreadcrumb": "Ajouter une application",
    "addApplicationEditBreadcrumb": "Modifier l’application",
    "addApplicationUploadThumbnail": "Téléverser l’image miniature de l’application",
    "addApplicationGuideAutomation": "Automatisation du Guide",
    "addApplicationApplicationLevelTranslationLanguages": "Langues de traduction au niveau de l’application",
    "addApplicationEnableApplicationLanguageSettings": "Activer les paramètres de langue au niveau de l’application",
    "addApplicationPackageId": "ID de forfait",
    "addApplicationBundleId": "ID de groupe",
    "addApplicationMaxApplicationLimit": "Il est impossible d’ajouter une nouvelle application, car l’organisation a dépassé le nombre maximal d’applications autorisées.",
    "addApplicationIdExists": "La nouvelle application ne peut pas être ajoutée car l’ID de forfait/l’ID de groupe existe déjà.",
    "addFolderName": "Nom du dossier",
    "addOrganisationName": "Nom de l’organisation",
    "addOrganisationType": "Type d’organisation",
    "addUserAvtar": "Avtar",
    "addUserFirstName": "Prénom",
    "addUserLastName": "Nom",
    "addUserEmailId": "ID de messagerie",
    "addUserOrganisationRole": "Rôle d’accès à l’application",
    "addUserLockedStatus": "État verrouillé",
    "addUserRole": "Rôle de l’utilisateur",
    "addUserUUName": "UUName",
    "labelDoItForMe": "DoItForMe",
    "labelMyChat": "MyChat",
    "labelTeachMe": "TeachMe",
    "labelTutorialGuides": "Tutorial Guides",
    "labelGuideTranslation": "Traduction du Guide",
    "labelShowBeaconGuidesOnCurrentPage": "Afficher les Guides relatifs aux Beacon sur la Page actuelle",
    "labelGuideSegmentation": "Segmentation du Guide",
    "labelShowCurrentPageGuidesOnly": "Afficher uniquement les Guides de la Page en cours",
    "labelTooltipEnhancements": "Améliorations de l’Tooltip",
    "labelUserInformationTracking": "Suivi des informations utilisateur",
    "labelSSO": "Authentification SSO",
    "labelMediaFileCreation": "Création de fichiers multimédia",
    "labelYoutubeSettings": "Paramètres Youtube",
    "labelEnableOrgAndGuidesJSONCaching": "Activer la mise en cache JSON pour l’organisation & les Guides",
    "labelGuideAutomation": "Automatisation du Guide",
    "labelAPIConnector": "Connecteur API",
    "labelLoadContextChangeOnPageClick": "Charger le changement de contexte lors d’un clic sur la page",
    "labelTaskList": "Liste de tâches",
    "labelMediaFileTheme": "Thèmes de fichiers multimédia",
    "labelDisplayGuidePublishingDate": "Afficher la date de publication du Guide",
    "labelMaintenanceMode": "Mode Maintenance",
    "labelEnableGuidemeAsSecondaryOption": "Activer Guideme en tant qu’option secondaire",
    "labelHashPlayerUsersData": "Données d’utilisateur de Hash Player",
    "labelAllowOnPremiseDeployment": "Autoriser le déploiement sur site",
    "labelFTPEndpointToSaveContent": "Point de terminaison FTP pour enregistrer le contenu",
    "labelEnableInsights": "Activer Insights",
    "labelDisplayAcceptCookiePopup": "Afficher la fenêtre contextuelle d’acceptation des cookies",
    "labelSetRefreshTokensExpiryTime": "Définir l’heure d’expiration du jeton d’actualisation (en secondes)",
    "labelGuideInsights": "Guide Insights",
    "labelRealTimeInsights": "Insights en temps réel",
    "labelInsightsPriority": "Priorité des Insights",
    "labelMaxGuideLimit": "Nombre maximum de Guide",
    "labelMaxApplicationLimit": "Nombre maximum d’applications",
    "labelMaxStepLimit": "Nombre maximum d’étapes",
    "labelMaxAdminUsers": "Nombre maximum d’utilisateurs Admin",
    "labelMaxCreatorUsers": "Nombre maximum d’utilisateurs auteurs",
    "labelMaxPlayerUsers": "Nombre maximum d’utilisateurs consultants",
    "tooltipDoItForMe": "Autorise la lecture des guides dans Do it for me (mode automatisé)",
    "tooltipMyChat": "Active l’assistant de chat virtuel",
    "tooltipTeachMe": "Active la lecture du guide dans la simulation de l’application hôte",
    "tooltipTutorialGuides": "Active la fonction de guidage du Tutorial",
    "tooltipGuideTranslation": "Permet de traduire les guides dans une ou plusieurs langues prises en charge par MyGuide",
    "tooltipShowBeaconGuidesOnCurrentPage": "Permet aux guides dont la balise est configurée de s’afficher sur la page en cours. Par défaut, les balises sont affichées sur la page et les guides correspondants ne sont pas visibles sur la page en cours.",
    "tooltipGuideSegmentation": "Gérer les guides auxquels un utilisateur peut et ne peut pas accéder en fonction des données d’attribut de l’utilisateur",
    "tooltipShowCurrentPageGuidesOnly": "Masque l’onglet Tous les Guides et la hiérarchie des dossiers qui comprend les guides non disponibles sur la page en cours.",
    "tooltipTooltipEnhancements": "Améliorations de l’Tooltip",
    "tooltipUserInformationTracking": "Permet un suivi plus précis des Insights en suivant les données uniques de l’utilisateur",
    "tooltipSSO": "Permet d’intégrer l’authentification unique avec l’IDP du client",
    "tooltipMediaFileCreation": "Permet l’exportation automatique de contenus MyGuide sous divers formats tels que .pdf, .docx, etc.",
    "tooltipYoutubeSettings": "Permet au connecteur YouTube de publier des guides sur YouTube.",
    "tooltipEnableOrgAndGuidesJSONCaching": "Permet à l’organisation JSON d’inclure tous les guides et toutes les étapes. Recommandé uniquement pour les organisations disposant de moins de 100 guides publiés. Il doit être désactivé si la taille totale de l’organisation JSON est supérieure à 4 Mo.",
    "tooltipAPIConnector": "Permet à des connecteurs d’API tiers d’importer des données. Les connecteurs pris en charge sont Zendesk, etc.",
    "tooltipLoadContextChangeOnPageClick": "Permet à l’utilisateur de restreindre la recherche de règles lors d’un clic sur la page",
    "tooltipTaskList": "Permet la création de listes de tâches basées sur des guides à attribuer à l’utilisateur en tant que tâches",
    "tooltipMediaFileTheme": "Permet la sélection et la personnalisation de thèmes de fichiers multimédia",
    "tooltipDisplayGuidePublishingDate": "Permet l’affichage de la date de publication du guide sur des formats téléchargeables (vidéo, pdf, etc.)",
    "tooltipMaintenanceMode": "Mode Maintenance",
    "tooltipEnableGuidemeAsSecondaryOption": "Activez guideme en tant que mode secondaire pour les guides créés avec showme par défaut.",
    "tooltipHashPlayerUsersData": "Données d’utilisateur de Hash Player",
    "tooltipAllowOnPremiseDeployment": "Permet le déploiement et l’exploitation de MyGuide en tant que configuration locale/intranet",
    "tooltipFTPEndpointToSaveContent": "Saisissez une adresse FTP valide commençant par ftp : //. par exemple : ftp : //1.2.3.4 : 21 ou ftp : //abc.exemple.com : 21",
    "tooltipEnableInsights": "Activé par défaut. S’il est désactivé, il stoppe la transmission de tous types d’informations pour l’organisation",
    "tooltipDisplayAcceptCookiePopup": "Active l’affichage de la fenêtre d’acceptation des cookies",
    "tooltipSetRefreshTokensExpiryTime": "Définir l’heure d’expiration du jeton d’actualisation",
    "tooltipGuideInsights": "Permet d’obtenir des informations sur l’interaction de l’utilisateur avec les guides MyGuide, les info-bulles, les balises, etc. Activé par défaut",
    "tooltipRealTimeInsights": "L’activation de cette fonctionnalité fournira une transmission en temps réel des données pour certaines sections du portail d’informations",
    "tooltipAppInsights": "Permet d’obtenir des informations sur le suivi des clics, des pages, des flux de travail, etc. Cette fonction s’exécute en arrière-plan sans que les utilisateurs aient besoin d’interagir avec les guides, les balises, les info-bulles, etc.",
    "tooltipInsightsPriority": "Définir la priorité des Insights de MyGuide",
    "languageTranslationsSettings": "Règles de traduction linguistique",
    "englishDefault": "1. La traduction de la langue est disponible uniquement lorsque l’anglais (qu’elle que soit la variante) est sélectionné comme langue par défaut.",
    "languageEdited": "2. Si la langue par défaut est modifiée, vous devez resélectionner les langues précédemment ajoutées à partir de l’option « Choisir la langue ».",
    "defaultLanguage": "Langue par défaut",
    "defaultVoice": "Voix par défaut",
    "moreLanguage": "Plus de langues",
    "guideOrWorkflowAutomation": "Automatisation du Guide ou du Workflow",
    "cannotBeDisabled": "Après activation, cette option ne peut pas être désactivée.",
    "okToApply": "Pour appliquer ce paramètre, veuillez cliquer sur le bouton OK.",
    "addToConnector": "Ajouter un connecteur",
    "doYouWantToDelete": "Confirmez-vous la suppression ?",
    "segmentData": "Données :",
    "segmentKeyName": "Nom clé :",
    "ssoDescription": "Description SSO",
    "ssoIssuerURL": "URL de l’émetteur",
    "ssoCertificate": "Certificat x.509",
    "ssoAPIKey": "Clé de l’API",
    "ssoAPISecret": "Question secrète de l’API",
    "ssoNameIdEncrypted": "Voulez-vous que NameId soit crypté ?",
    "ssoMetaDataSigned": "Voulez-vous que les métadonnées soient signées ?",
    "ssoResponseSigned": "Voulez-vous que la réponse soit signée ?",
    "ssoAssertionSigned": "Voulez-vous que l’assertion soit signée ?",
    "ssoSignatureAlgorithm": "Algorithme de signature",
    "ssoDigestAlgorithm": "Algorithme d’empreinte",
    "ssoMessageSigned": "Voulez-vous que le message soit signé ?",
    "ssoWish": "Souhaitez-vous utiliser le même certificat pour le cryptage de NameId ?",
    "ssoEncryptCertificate": "Certificat x509 de cryptage",
    "ssoAllowAplicationSingleLayout": "Autoriser l’application à initier une déconnexion unique ?",
    "ssoAuthRequestSigned": "Voulez-vous que la demande d’authentification soit signée ?",
    "ssoConfirmationMessage": "Message de confirmation de l’authentification SSO",
    "ssoButtonMessage": "Message du bouton d’authentification SSO",
    "ssoSingleLogoutURL": "URL de déconnexion unique",
    "ssoListName": "Nom :*",
    "userProvisioningMethod": "Méthode de provisionnement de l’utilisateur",
    "apiConnectorToken": "Jeton",
    "apiConnectorEmailAddress": "Adresse email",
    "apiConnectorAPIEndpoint": "Point d’extrémité d’API",
    "apiConnectorFolder": "Dossier",
    "organisationFormDualAuthentication": "Double authentification",
    "organisationFormUploadOrganisationThumbnail": "Téléverser l’image miniature de l’organisation",
    "organisationFormUploadFirstSlide": "Téléverser l’image de la première diapositive",
    "organisationFormUploadFirstSlideText": "Texte de la première diapositive",
    "organisationFormUploadLastSlide": "Téléverser l’image de la dernière diapositive",
    "organisationFormUploadLastSlideText": "Texte de la dernière diapositive",
    "organisationFormCopyrightText": "Texte du droit d’auteur",
    "applicationCreatedText": "Application créée",
    "applicationUpdatedText": "Application mise à jour",
    "applicationDeletedText": "Application supprimée",
    "applicationAssignedText": "Applications attribuées",
    "automationStatusUpdatedText": "État d’automatisation mis à jour",
    "roleCreatedText": "Rôle créé",
    "roleUpdatedText": "Rôle mis à jour",
    "roleDeletedText": "Rôle supprimé",
    "roleAssignedText": "Rôle attribué",
    "roleAssignedToApplicationText": "Rôle attribué à l’application",
    "organisationRoleAssignedText": "Rôle d’organisation attribué",
    "credentialsCreatedText": "Identifiants créés",
    "credentialsDeletedText": "Identifiants supprimés",
    "segmentCreatedText": "Segment créé",
    "segmentUpdatedText": "Segment mis à jour",
    "segmentDeletedText": "Segment supprimé",
    "segmentValueCreatedText": "Valeur de segment créée",
    "segmentValueDeletedText": "Valeur de segment supprimée",
    "userCreatedText": "Utilisateur créé",
    "userUpdatedText": "Utilisateur mis à jour",
    "userDeletedText": "Utilisateur supprimé",
    "domainCreatedText": "Domaine créé",
    "domainDeletedText": "Domaine supprimé",
    "updatedDomainSsoMapping": "Mappage SSO de domaine mis à jour",
    "apiConnectorAddedText": "Connecteur d’API ajouté",
    "apiConnectorDeletedText": "Connecteur d’API supprimé",
    "taskListCreatedText": "Liste de tâches créée",
    "taskListUpdatedText": "Liste de tâches mise à jour",
    "taskListDeletedText": "Tâche supprimée",
    "visibilitySettingsUpdatedText": "Les paramètres de visibilité ont été mis à jour",
    "adminSettingsUpdatedText": "Paramètres d’Admin mis à jour",
    "importedText": "Importé",
    "userUnlockedText": "Utilisateur déverrouillé",
    "userStatusChangedText": "L’état de l’utilisateur a changé",
    "sessionClearedforUserText": "Session effacée pour l’utilisateur",
    "passwordChangedText": "Mot de passe modifié",
    "organisationUpdatedText": "Organisation mise à jour",
    "organisationActiveStatusChangedText": "État Actif de l’organisation modifié",
    "organisationVerifiedStatusChangedText": "État Vérifié de l’organisation modifié",
    "organisationCreatedText": "Organisation ajoutée",
    "organisationDeletedText": "Organisation supprimée",
    "ssoCreatedText": "Authentification SSO ajoutée",
    "ssoDeletedText": "Authentification SSO supprimée",
    "changesReflectedText": "Les changements seront répercutés sur le portail des consultants dans quelques minutes",
    "sessionClearedForOrganisationText": "Session autorisée avec succès pour l’organisation !",
    "ssoConfigurationUpdatedText": "Configuration SSO mise à jour",
    "instantUpdateText": "Mise à jour instantanée réussie",
    "sessionClearedText": "Session autorisée",
    "successfullyText": "Avec succès !",
    "successText": "Félicitations !",
    "login": "Connexion",
    "myGuideLogin": "Connexion de MyGuide",
    "loginInvalidCredentials": "Identifiants de connexion non valides. Réessayez",
    "loginAccessForbidden": "Accès formellement interdit",
    "loginPassword": "Mot de passe",
    "loginFillField": "Veuillez renseigner ce champ.",
    "loginRemember": "N’oubliez pas",
    "loginFieldMandatory": "Ce champ est obligatoire.",
    "loginContactEdcast": "Si vous avez besoin d’aide, veuillez nous écrire à",
    "loginCopyright": "Copyright ©",
    "support": "support@csod.com",
    "forgotPassword": "Mot de passe oublié",
    "resetPassword": "Réinitialiser le mot de passe",
    "forgotPasswordBackToLogin": "Revenir à l’espace de connexion",
    "forgotPasswordResendLink": "Renvoyer le lien",
    "forgotPasswordInstructions": "- S’il s’agit d’un ID MyGuide valide, vous devriez recevoir un email contenant des instructions sur la façon de créer un nouveau mot de passe.",
    "forgotPasswordLinkSent": "Le lien de réinitialisation du mot de passe a été envoyé",
    "forgotPasswordDidntRecieveMail": "Vous n’avez pas reçu l’email ?",
    "forgotPasswordCheckSpam": "Vérifiez dans le dossier du courrier indésirable la présence d’un message provenant de",
    "generatePasswordSuccesfullyReset": "Le mot de passe a été réinitialisé avec succès",
    "generatePasswordLinkExpired": "Générer le message « lien de mot de passe expiré » ou « mot de passe à usage unique non valide »",
    "generatePasswordMustContain": "Le nouveau mot de passe doit contenir :",
    "generatePasswordCharacterLimit": "Entre 8 et 20 caractères",
    "generatePasswordOneNumber": "Au moins un chiffre",
    "generatePasswordOneUpperCase": "Au moins une lettre majuscule",
    "generatePasswordOneSpecialCharacter": "Au moins un caractère spécial (par ex. @,*,!)",
    "generatePasswordShouldMatch": "Les champs Nouveau mot de passe et Confirmer le nouveau mot de passe doivent être identiques",
    "organisationSelectText": "Sélectionner une organisation",
    "organisationAddText": "Ajouter une organisation",
    "organisationEditText": "Modifier l’organisation",
    "organisationActiveText": "Actif",
    "organisationVerifiedText": "Vérifié",
    "organisationPaid": "Payé",
    "organisationTrial": "Essai",
    "organisationHeading1": "Paramètres vidéo & GIF",
    "organisationTooltip1": "Les paramètres suivants sont utilisés pour personnaliser la structure des formats vidéo et GIF des guides générés pour l’organisation",
    "dashboardTitle": "Dashboard | MyGuide",
    "dashboardNoOfOrganisationsTitle": "ORGANISATION(S)",
    "dashboardNoOfApplicationsTitle": "APPLICATION(S)",
    "dashboardNoOfUsersTitle": "UTILISATEUR(S)",
    "dashboardTriggerInstantUpdateButton": "Déclencher une mise à jour instantanée",
    "dashboardClearSessionButton": "Autoriser la session",
    "dashboardTriggerInstantUpdateButtonTooltip": "Les portails des consultants sont mis à jour toutes les heures. Cliquez ici pour déclencher une mise à jour instantanée. Les modifications seront effectives dans 2 à 10 minutes.",
    "dashboardClearSessionButtonTooltip": "Autoriser la session pour l’organisation.",
    "dashboardUserAction": "Action de l’utilisateur",
    "dashboardUserKey": "Clé utilisateur",
    "dashboardRegisteredDate": "Date d’enregistrement",
    "apiAndServicesTitle": "API & services |",
    "apiAndServicesBreadCrumbTitle": "API & services",
    "apiAndServicesGenrateCrendentialsButton": "Générer des identifiants",
    "apiAndServicesTableHeaderKeyName": "Nom clé",
    "apiAndServicesTableHeaderKey": "Clé",
    "apiAndServicesTableHeaderSecret": "Question secrète",
    "apiAndServicesTableHeaderAnonymousAccess": "Accès anonyme",
    "apiAndServicesTableHeaderJITProvisioning": "Provisionnement juste à temps",
    "applicationsTitle": "Applications |",
    "applicationsAssignTitle": "Attribuer des applications",
    "applicationsBreadCrumbTitle": "Applications",
    "applicationsTableHeaderThumbnail": "Miniature",
    "applicationsTableHeaderApplicationName": "Nom de l’application",
    "applicationsTableHeaderApplicationId": "ID de l’application",
    "applicationsTableHeaderMobileApplicationId": "ID de l’application mobile",
    "applicationsTableHeaderAutomationStatus": "État d’automatisation",
    "applicationsUpdateAutomationStatus": "Mettre à jour l’état d’automatisation",
    "changeAutomationStatus": "Voulez-vous modifier l’état d’automatisation de cette application ?",
    "rolesAddRoleButtonText": "Ajouter un rôle",
    "rolesAddingRoleButtonText": "Ajout d’un rôle en cours...",
    "rolesUpdateRoleButtonText": "Mettre à jour le rôle",
    "rolesUpdatingRoleButtonText": "Mise à jour du rôle en cours...",
    "usersBreadCrumbTitle": "Utilisateurs",
    "segmentsTableHeaderKeyName": "Nom clé",
    "segmentsTableHeaderIsRequired": "Est obligatoire ?",
    "segmentsListTableHeaderValue": "Valeur",
    "segmentsListTableHeaderIsDefault": "Est la valeur par défaut ?",
    "segmentsAddSegmentButtonText": "Ajouter un segment",
    "segmentsUpdateSegmentButtonText": "Mettre à jour le segment",
    "segmentsAddValueButtonText": "Ajouter une valeur",
    "segmentsAddingValueButtonText": "Ajout d’une valeur en cours...",
    "segmentsAddingSegmentButtonText": "Ajout d’un segment en cours...",
    "segmentsUpdatingSegmentButtonText": "Mise à jour du segment en cours...",
    "addSegmentsValue": "Ajouter des valeurs de segment",
    "domainsAddDomainDomainName": "Nom du domaine",
    "domainsAddDomainSSOIDP": "IDP d’authentification SSO",
    "domainsAddDomainButtonText": "Ajouter un domaine",
    "domainsAddingDomainButtonText": "Ajout du domaine en cours...",
    "apiConnectorTableHeaderAPIName": "Nom de l’API",
    "apiConnectorTableHeaderSyncCompleted": "Synchronisation terminée",
    "ssoListTableHeaderSSOName": "Nom de l’authentification SSO",
    "ssoListDownloadMetadata": "Télécharger les métadonnées",
    "ssoListIdentityProviders": "Fournisseurs d’identité",
    "ssoListDomainMappings": "Mappages de domaine",
    "addSSOIDP": "Ajouter l’IDP d’authentification SSO",
    "addSSO": "Ajouter une authentification SSO",
    "updateSSO": "Mettre à jour l’authentification SSO",
    "featureSettings": "Paramètres des fonctionnalité",
    "insightsSettings": "Paramètres des Insights",
    "limitSettings": "Paramètres des limites",
    "languageTranslations": "Traduction linguistique",
    "chooseImage": "Choisir une image",
    "adminSettingsWarning": "Avertissement ! L’activation du mode Maintenance désactivera cette organisation",
    "adminSettingsHashPlayersData": "Données d’utilisateur de Hash Player",
    "adminSettingsEnableSure": "Voulez-vous vraiment l’activer",
    "adminSettingsYouCanEither": "Vous pouvez activer soit",
    "adminSettingsGuideAutomation": "Automatisation du Guide",
    "adminSettingsWorkflowAutomation": "Automatisation du Workflow",
    "adminSettingsUnselectProceed": "Désélectionnez l’une des deux options pour continuer",
    "activityTableHeaderActivity": "Activité",
    "activityTableHeaderActivityEmailId": "ID d’email d’activité",
    "activityTableHeaderActivityIPAddress": "Adresse IP",
    "activityAdmin": "Activité d’Admin",
    "activityLogin": "Activité de connexion",
    "activityType": "Type d’activité",
    "activityAction": "Action",
    "activityDetails": "Détails d’activité",
    "activityTableHeaderActivityIPAddresss": "Adresse IP",
    "userProvisioningTitle": "Provisionnement de l’utilisateur |",
    "userProvisioningBreadCrumbTitle": "Provisionnement de l’utilisateur",
    "userProvisioningProvideKey": "Indiquer le nom de la clé",
    "exportDataExportGuideButtonText": "Exporter les données du Guide",
    "exportDataExportUserButtonText": "Exporter les données utilisateur",
    "exportDataTableHeaderExportType": "Type d’exportation",
    "exportDataTableHeaderExportedBy": "Exporté par",
    "guideList": "Liste des Guides",
    "userList": "Liste des utilisateurs",
    "selectApplications": "Sélectionner des applications",
    "selectApplicationsMessage": "Veuillez sélectionner l’application pour exporter les données du guide",
    "exportDetails": "Exporter les détails",
    "taskListAddTaskButtonText": "Ajouter une tâche",
    "taskListAddingTaskButtonText": "Ajout d’une tâche en cours...",
    "taskListUpdateTaskButtonText": "Mettre à jour la tâche",
    "taskListUpdatingTaskButtonText": "Mise à jour de la tâche en cours...",
    "taskListTableHeaderApplication": "Application",
    "taskListTableHeaderLeaderboard": "Leaderboard",
    "addGroup": "Ajouter un groupe",
    "removeGroup": "Supprimer un groupe",
    "updateTaskList": "Mettre à jour la liste de tâches",
    "addTaskList": "Ajouter une liste de tâches",
    "formMessage1": "1. Le nom de domaine doit contenir au moins deux parties séparées par un point « . »",
    "formMessage2": "2. La partie nom de domaine ne doit pas commencer ou se terminer par un trait d’union « - ».",
    "formMessage3": "3. Les noms de domaine peuvent contenir les lettres a à z, les chiffres 0 à 9 et le trait d’union.",
    "formMessage4": "4. Les parties suivantes du nom de domaine doivent comporter au moins deux caractères.",
    "recommendedSize": "(Taille recommandée 500 x 500 pixels) Fichier pris en charge : JPG, PNG",
    "recommendedSize2": "Taille recommandée (500 x 500 pixels).",
    "topLeft": "En haut à gauche",
    "topRight": "En haut à droite",
    "bottomLeft": "En bas à gauche",
    "bottomRight": "En bas à droite",
    "importUserVariable1": "Téléverser le fichier CSV pour importer des utilisateurs",
    "importUserVariable2": "Glisser-déposer le fichier CSV ici",
    "importUserVariable4": "Parcourir",
    "importUserVariable5": "Type de fichier non valide",
    "importUserVariable6": "Envoyer un email groupé :",
    "importUserVariable7": "Oui",
    "importUserVariable8": "Non",
    "importUserVariable9": "Téléverser",
    "importUserVariable10": "Historique du fichier d’importation des utilisateurs",
    "importUserVariable11": "Actualiser",
    "importUserVariable12": "Télécharger un exemple de fichier CSV",
    "importUserVariable13": "OU",
    "generateCredentialsVariable1": "Informations sur la création de données d’identification",
    "generateCredentialsVariable2": "Anonyme",
    "generateCredentialsVariable3": "- Si les utilisateurs ne veulent pas utiliser MyGuide pour enregistrer leurs informations, sélectionnez Anonyme.",
    "generateCredentialsVariable4": "Juste à temps",
    "generateCredentialsVariable5": "Si vous sélectionnez Juste à temps, nous vérifions si cet email est déjà enregistré avec MyGuide. Dans le cas contraire, nous ajoutons la personne en tant qu’utilisateur MyGuide.",
    "generateCredentialsVariable6": "Nom clé",
    "generateCredentialsVariable7": "- Dans ce champ, entrez le but de la création de cette clé. Par exemple, SSO, Test, Salesforce, .js, etc.",
    "generateCredentialsVariable8": "Choisir une image",
    "generateCredentialsVariable15": "Sélectionner des applications",
    "generateCredentialsVariable16": "Une application est requise",
    "headerVariable1": "ORGANISATION :",
    "headerVariable2": "RÔLE :",
    "headerVariable3": "Modifier le mot de passe",
    "headerVariable4": "Déconnexion",
    "headerVariable5": "Insights",
    "manageContentVariable1": "Liste de tâches :",
    "manageContentVariable5": "Application :",
    "manageContentVariable6": "Prévisualisation",
    "manageContentVariable10": "Pas de Guides",
    "manageContentVariable12": "Gérer les Guides",
    "manageContentVariable13": "Ajouter un dossier",
    "manageContentVariable17": "Sélectionner tout",
    "manageContentVariable18": "Guides sélectionnés",
    "manageContentVariable21": "Aucun Guides publié n’est disponible sous cette application",
    "manageContentVariable27": "Précédent",
    "manageContentVariable29": "Suivant",
    "manageContentVariable30": "Ce dossier ne peut pas être supprimé, car il contient des guides. Retirez les Guides et réessayez !",
    "manageRoleVariable5": "Ajouter un rôle",
    "manageRoleVariable1": "Attribuer un rôle",
    "otpVariable1": "Revenir à l’espace de connexion",
    "otpVariable2": "Vérifier le compte",
    "otpVariable3": "Déverrouiller l’utilisateur",
    "otpVariable4": "Le mot de passe à usage unique a été envoyé à",
    "otpVariable6": "Renvoyer le mot de passe à usage unique",
    "otpVariable7": "Revenir à l’espace de connexion",
    "otpVariable8": "Compte utilisateur déverrouillé avec succès.",
    "otpVariable9": ". Veuillez entrer le mot de passe à usage unique pour vérifier votre compte",
    "otpVariable10": "Veuillez entrer le mot de passe à usage unique pour déverrouiller votre compte",
    "otpVariable11": "Mot de passe à usage unique",
    "otpVariable12": "Mot de passe à usage unique non valide",
    "otpVariable13": "Veuillez essayer avec le bon mot de passe à usage unique",
    "otpVariable14": "Mot de passe à usage unique non reçu",
    "otpVariable15": "Renvoyer le mot de passe à usage unique",
    "attributeMapping": "Mappage d’attributs",
    "stepTitle": "Titre de l’étape",
    "stepDescription": "Description de l’étape",
    "stepTooltips": "Info-bulles des étapes",
    "pageThemeNumber": "Page ##",
    "firstPage": "Première Page",
    "middlePage": "Deuxième Page",
    "lastPage": "Dernière Page",
    "themeListVariable1": "Paramètre de thème au format PPT, Doc & PDF",
    "themeListVariable2": "Info :",
    "themeListVariable3": "Le thème actif sera utilisé pour créer des fichiers au format PPT (.pptx), PDF (.pdf) et document (.docx)",
    "themeListVariable4": "Actif",
    "themeListVariable5": "Activer",
    "themeListVariable6": "Activation du thème",
    "themeListVariable7": "Voulez-vous changer le thème par défaut ?",
    "themeListVariable8": "Non",
    "themeListVariable9": "Oui",
    "usersVariable2": "État",
    "usersVariable3": "Tout",
    "usersVariable4": "Actif",
    "usersVariable5": "Suspendu",
    "usersVariable6": "Tout",
    "usersVariable7": "Organisation Admin",
    "usersVariable8": "Auteur",
    "usersVariable9": "Consultant",
    "usersVariable10": "Admin de l’application",
    "usersVariable11": "Admin MG",
    "usersVariable12": "Rôle d’accès à l’application",
    "usersVariable13": "Tout",
    "usersVariable14": "Exporter les données",
    "usersVariable19": "Mettre à jour l’état verrouillé",
    "usersVariable20": "Mettre à jour l’état",
    "usersVariable22": "Voulez-vous déverrouiller cet utilisateur ?",
    "usersVariable23": "Voulez-vous mettre à jour l’état de cet utilisateur ?",
    "usersVariable25": "Déverrouiller",
    "usersVariable26": "Oui",
    "usersVariable27": "Ajouter un utilisateur",
    "usersVariable28": "Importer un utilisateur",
    "usersVariable29": "Mettre à jour l’état verrouillé",
    "usersVariable30": "Mettre à jour l’état",
    "usersVariable31": "Rôle de l’utilisateur",
    "usersProvisioningVariable30": "1. La création d’un nouveau jeton de provisionnement supprimera/remplacera le jeton de provisionnement existant. Cela empêchera les utilisateurs de provisionner les API à l’aide d’un ancien jeton.",
    "usersProvisioningVariable31": "2. Les informations d’identification générées dépendent de la méthode de provisionnement sélectionnée ci-dessous.",
    "usersProvisioningVariable1": "Veuillez copier les informations d’identification ci-dessus avant de fermer cette fenêtre contextuelle. Elles ne peuvent plus être visualisées après la fermeture de cette fenêtre contextuelle.",
    "usersProvisioningVariable2": "Jeton d’API",
    "usersProvisioningVariable3": "Voulez-vous vraiment continuer ?",
    "tableUnmapped": "Non mappé",
    "tableTitle1": "Attribuer des valeurs de segment",
    "tableTitle2": "Modifier",
    "tableTitle3": "Gérer le contenu",
    "tableTitle4": "Cliquer pour modifier",
    "tableTitle5": "Se déconnecter de toutes les sessions",
    "visibility": "Visibilité",
    "taskVariable1": "Le chemin commencera toujours par une barre oblique, seuls les caractères alphanumériques et les caractères spéciaux (~, =, <, >, /, * uniquement) sont autorisés, les espaces ne sont pas autorisés dans la chaîne de chemin.",
    "taskVariable2": "Ce champ est obligatoire.",
    "taskVariable3": "La double barre oblique n’est pas autorisée.",
    "taskVariable4": "Il est impossible d’avoir <> et ~= ensemble après une barre oblique.",
    "taskVariable5": "Erreur : _ et - ne sont autorisés qu’entre crochets obliques (<>).",
    "taskVariable6": "Il est impossible d’avoir ~ ou = seul dans le chemin, ~ sera toujours suivi de =.",
    "taskVariable7": "Paramètre non valide : il commencera toujours par un ? et ne peut pas avoir de ? suivi de = dans le paramètre",
    "taskVariable8": "Paramètre non valide : & suivi de caractères alphanumériques et de caractères spéciaux (-, _) est autorisé.",
    "taskVariable9": "Paramètre non valide : seuls les caractères alphanumériques et les caractères spéciaux (-, _, =, &) sont autorisés.",
    "taskVariable10": "Hash non valide : il commencera toujours par un #.",
    "applications": "Applications",
    "users": "Utilisateurs",
    "manageRoles": "Gérer les rôles",
    "themes": "Thèmes",
    "activity": "Activité",
    "exportData": "Exporter les données",
    "taskList": "Liste de tâches",
    "segments": "Segments",
    "domains": "Domaines",
    "adminSettings": "Paramètres d’Admin",
    "themeSettings": "Paramètres de thème",
    "guidePublishingDate": "Afficher la date de publication du Guide",
    "firstSlide": "Première diapositive",
    "lastSlide": "Dernière diapositive",
    "setDisplay": "Définir la position d’affichage",
    "setFirstSlide": "Définir la position d’affichage de la première diapositive",
    "setLastSlide": "Définir la position d’affichage de la dernière diapositive",
    "markAll": "Marquer tout",
    "language": "Langue",
    "voice": "Voix",
    "uploadProfileImage": "Téléverser l’image du profil",
    "downloadSampleCSV": "Télécharger un exemple de fichier CSV",
    "done": "Terminé",
    "started": "Démarré",
    "pending": "En attente",
    "failed": "En échec",
    "changeUserPassword": "Modifier le mot de passe de l’utilisateur",
    "segmentValues": "Valeurs de segment",
    "tokenGenerated": "Jeton généré",
    "availableSoon": "Votre fichier sera bientôt disponible",
    "exportInProgress": "Exportation en cours !",
    "themeActivated": "Thème activé",
    "themeUpdated": "Thème activé",
    "themeGenerated": "Thème généré",
    "themeActivation": "Activation du thème",
    "invalidExtension": "Extension non valide ou le format est supérieur à 2 Mo.",
    "unableUpdateLogo": "Impossible de mettre à jour le logo",
    "create": "Créer",
    "delete": "Supprimer",
    "update": "Mettre à jour",
    "field": "Champ",
    "originalValue": "Valeur initiale",
    "newValue": "Nouvelle valeur",
    "message1": "Avertissement ! Une fois que les données de l’utilisateur de Hash player sont activées, elles ne peuvent pas être désactivées",
    "message2": "Pour activer Insights, au moins une des options suivantes doit être activée",
    "high": "Élevé",
    "medium": "Intermédiaire",
    "low": "Faible",
    "view": "Afficher",
    "details1": "Détails",
    "assign": "Attribuer",
    "label1": "Mettre à jour le mot de passe",
    "label2": "Mettre à jour le profil",
    "label3": "Générer des clés",
    "label4": "Mettre à jour les clés",
    "label5": "Supprimer les clés",
    "label6": "Attribuer à l’application",
    "label7": "Rôle de l’utilisateur",
    "label8": "Provisionnement",
    "label9": "Clés d’API",
    "label10": "Organisation",
    "label11": "Application",
    "label12": "Ordre des catégories",
    "label13": "Catégorie",
    "label14": "Ordre de présentation",
    "label15": "Mettre à jour les paramètres de l’Admin",
    "label16": "Créer des paramètres d’authentification SSO",
    "label17": "Mettre à jour les paramètres d’authentification SSO",
    "label18": "Supprimer les paramètres d’authentification SSO",
    "label19": "Créer une application",
    "label20": "Mettre à jour l’application",
    "label21": "Supprimer l’application",
    "label22": "Mettre à jour l’ordre des catégories",
    "label23": "Créer une sous-catégorie",
    "label24": "Mettre à jour la sous-catégorie",
    "label25": "Supprimer une sous-catégorie",
    "label26": "Mettre à jour l’ordre de présentation",
    "label27": "Créer une liste de tâches",
    "label28": "Supprimer la liste de tâches",
    "label29": "Publier la liste de tâches",
    "label30": "Mettre à jour la liste de tâches",
    "label31": "Mettre à jour les paramètres de la liste de tâches",
    "label32": "Créer le dossier de la liste de tâches",
    "label33": "Mettre à jour le dossier de la liste de tâches",
    "label34": "Supprimer le dossier de la liste de tâches",
    "label35": "Mettre à jour le contenu de la liste de tâches",
    "label36": "Finalisation de la création de la liste de tâches",
    "exportmessage1": "Exporter le fichier avec les métadonnées du guide - nom, application, état de publication, etc.",
    "exportmessage2": "Exporter le fichier avec les détails de l’utilisateur comme : nom et prénom, email, rôle, etc.",
    "previewMessage": "La prévisualisation vidéo n’existe pas",
    "preview": "Prévisualisation",
    "detailButtonText": "Détails",
    "taskListNote1": "Étapes de publication d'une liste de tâches :",
    "taskListNote2": "1. Créer une liste de tâches",
    "taskListNote3": "2. Ajouter du contenu",
    "taskListNote4": "3. Ajouter des paramètres de visibilité",
    "taskListNote5": "4. Modifier l’état de la publication",
    "serverMessage1": "En-tête non valide/vide [JetonAccès]",
    "serverMessage2": "[JetonAccès] a expiré",
    "serverMessage3": "Nous sommes vraiment désolés, il semble qu’il y ait un problème avec votre demande ou avec nos serveurs. Nous avons été informés de l’erreur et nous la corrigerons dès que possible.",
    "serverMessage4": "L’email de l’utilisateur n’est pas vérifié",
    "serverMessage5": "Maintenance en cours. Veuillez nous contacter via l’adresse support@csod.com",
    "serverMessage6": "Paramètre non valide/vide [jeton]",
    "serverMessage7": "Vous avez atteint la limite maximale du nombre d’étapes pouvant être créées",
    "serverMessage8": "Paramètre non valide/vide [jeton déverrouiller compte]",
    "serverMessage9": "Le support de cette API d’Admin est indisponible. Nous l’avons rendu obsolète. Nous le supprimerons dans les prochaines versions.",
    "serverMessage10": "L’en-tête [autorisation] a expiré",
    "serverMessage11": "Il manque un ou plusieurs paramètre(s) obligatoire(s).",
    "serverMessage12": "Identifiants de connexion non valides. Votre compte sera verrouillé après plusieurs tentatives infructueuses.",
    "serverMessage13": "Mot de passe à usage unique non valide ou expiré",
    "serverMessage14": "Le fichier téléversé n’est pas une image valide. (Seuls les fichiers JPG et PNG sont autorisés)",
    "serverMessage15": "Session expirée",
    "serverMessage16": "ID utilisateur non valide. Réessayez",
    "serverMessage17": "ID email incorrect. Réessayez",
    "serverMessage18": "Rôle utilisateur non valide. Réessayez",
    "serverMessage19": "Paramètre non valide [task_type]",
    "serverMessage20": "Paramètre non valide/vide [application_id]",
    "serverMessage21": "JSON non valide",
    "serverMessage22": "Paramètre non valide/vide [organization_id]",
    "serverMessage23": "Paramètre non valide/vide [parent_id]",
    "serverMessage24": "Paramètre non valide/vide [category_id]",
    "serverMessage25": "Paramètres non valides/vides [from_category_id/to_category_id]",
    "serverMessage26": "Paramètre requis pour super Admin [organization_id]",
    "serverMessage27": "Le contenu des données d’image doit être codé en base64",
    "serverMessage28": "ID de domaine non valide",
    "serverMessage29": "Type de fichier non pris en charge",
    "serverMessage30": "Paramètre non valide [file_id]",
    "serverMessage31": "Paramètre non valide/vide [tour_id]",
    "serverMessage32": "Paramètre non valide/vide [step_id]",
    "serverMessage33": "En-tête non valide/vide [JetonGoogle]",
    "serverMessage34": "URL d’équipe non valide",
    "serverMessage35": "En-tête non valide/vide [JetonEdcast]",
    "serverMessage36": "La taille du fichier ne doit pas dépasser 5 Mo.",
    "serverMessage37": "URL non valide",
    "serverMessage38": "Votre mot de passe doit comporter entre 8 et 15 caractères, dont au moins une lettre majuscule, une lettre minuscule, une valeur numérique et un caractère spécial.",
    "serverMessage39": "Mot de passe non valide",
    "serverMessage40": "Paramètre non valide/vide [country_id]",
    "serverMessage41": "Paramètre non valide/vide [city_id]",
    "serverMessage42": "Paramètre non valide [langue]",
    "serverMessage43": "L’ID d’authentification est non valide",
    "serverMessage44": "Paramètre non valide [file_id]",
    "serverMessage45": "ID d’URL non valide",
    "serverMessage46": "En-tête non valide/vide/expiré [Autorisation]",
    "serverMessage47": "En-tête non valide/vide [CléApp]",
    "serverMessage48": "En-tête non valide/vide [ActualiserJeton]",
    "serverMessage49": "ID de rôle non valide",
    "serverMessage50": "ID de segment non valide",
    "serverMessage51": "Nom de domaine non valide",
    "serverMessage52": "Données de segment en double",
    "serverMessage53": "ID d’abonnement non valide",
    "serverMessage54": "ID de rôle d’organisation non valide",
    "serverMessage55": "ID de liste de tâches non valide",
    "serverMessage56": "La liste de tâches n’a pas été publiée",
    "serverMessage57": "La liste de tâches a été publiée",
    "serverMessage58": "2044",
    "serverMessage59": "ID de dossier non valide",
    "serverMessage60": "2045",
    "serverMessage61": "ID de contenu non valide",
    "serverMessage62": "Paramètre non valide [external_user_id]",
    "serverMessage63": "Données de langue non valides pour l’étape. Veuillez vérifier dans le titre et la description de l’étape la présence de tout caractère non valide ou indésirable",
    "serverMessage64": "ID d’emploi non valide",
    "serverMessage65": "Action non autorisée sur un compte utilisateur verrouillé",
    "serverMessage66": "Paramètre non valide/vide [segments]",
    "serverMessage67": "Paramètre non valide/vide [group_id]",
    "serverMessage68": "Paramètre non valide/vide [sso_id]",
    "serverMessage69": "URL non valide fournie",
    "serverMessage70": "Paramètre non valide/vide [group_id]",
    "serverMessage71": "Les champs step_audio ou step_audio_text sont obligatoires",
    "serverMessage72": "Échec de l’intégrité des données",
    "serverMessage73": "Un ancien et un nouveau mot de passe ne peuvent pas être identiques.",
    "serverMessage74": "Des champs d’action sont obligatoires",
    "serverMessage75": "Paramètre non valide/vide [id]",
    "serverMessage76": "La sous-catégorie ne peut pas être créée dans la catégorie si un Guide est déjà créé.",
    "serverMessage77": "Si un utilisateur est déjà un super Admin, vous ne pouvez pas modifier le rôle d’utilisateur.",
    "serverMessage78": "Cette action n’est pas autorisée sur un guide publié",
    "serverMessage79": "Le guide verrouillé ne peut pas être publié.",
    "serverMessage80": "Cette action n’est pas autorisée sur une catégorie publiée. Veuillez annuler sa publication.",
    "serverMessage81": "L’application doit appartenir à l’organisation concernée",
    "serverMessage82": "Vous n’êtes pas autorisé(e) à effectuer cette action",
    "serverMessage83": "L’action n’est pas autorisée pour cette sous-catégorie.",
    "serverMessage84": "Le Guide ne peut pas être créé dans la catégorie si une sous-catégorie a déjà été créée",
    "serverMessage85": "Pour créer une ou plusieurs étape(s), vous devez déverrouiller ce guide",
    "serverMessage86": "Pour modifier une ou plusieurs étape(s), vous devez déverrouiller ce guide",
    "serverMessage87": "Pour supprimer une ou plusieurs étape(s), vous devez déverrouiller ce guide",
    "serverMessage88": "Pour copier et coller une ou plusieurs étape(s), vous devez déverrouiller ce guide",
    "serverMessage89": "L’action n’est pas autorisée pour cette liste de tâches.",
    "serverMessage90": "L’action n’est pas autorisée sur une liste de tâches publiée.",
    "serverMessage91": "L’action n’est pas autorisée pour ce dossier.",
    "serverMessage92": "Ceci est déjà un brouillon de guide, nous ne pouvons pas en créer un nouveau",
    "serverMessage93": "Le brouillon de guide ne peut pas être créé si le guide n’est pas publié sur l’environnement le plus élevé.",
    "serverMessage94": "Ce guide a déjà un brouillon, nous ne pouvons pas en créer un nouveau.",
    "serverMessage95": "Valeurs non valides du paramètre [envs]",
    "serverMessage96": "Vous avez atteint la limite autorisée pour les identifiants du Guide",
    "serverMessage97": "Valeurs non valides du paramètre [Mots clés]",
    "serverMessage98": "Action non autorisée pour l’étape relative aux tags.",
    "serverMessage99": "Les Guides ne peuvent pas être collés dans la même catégorie",
    "serverMessage100": "Les étapes ne peuvent pas être collées dans la même présentation",
    "serverMessage101": "Le Guide sera bientôt disponible à l’exportation. Pour vérifier l’état, accédez à [État de la tâche] dans le menu du compte.",
    "serverMessage102": "Le téléversement a commencé. Veuillez ne pas actualiser ou fermer le navigateur tant que le téléversement n’est pas terminé. Vous pouvez vérifier l’état de l’importation une fois le téléversement terminé",
    "serverMessage103": "L’utilisateur existe déjà",
    "serverMessage104": "L’identifiant de messagerie est déjà vérifié",
    "serverMessage105": "Cette URL est en conflit avec une autre configuration d’application, veuillez essayer d’ajouter une autre URL. Pour obtenir de l’aide, veuillez utiliser l’adresse support@csod.com",
    "serverMessage106": "La catégorie doit appartenir à la même application",
    "serverMessage107": "Le domaine existe déjà",
    "serverMessage108": "Vous avez atteint la limite maximale pour créer des guides",
    "serverMessage109": "L’ID d’étape doit appartenir au même guide",
    "serverMessage110": "Cette organisation est suspendue. Veuillez contacter votre administrateur.",
    "serverMessage111": "Le ou les ID du Guide doi(ven)t appartenir à la même catégorie",
    "serverMessage112": "Le Guide existe déjà avec la paire donnée de nom d’objet et de champ",
    "serverMessage113": "Utilisateur suspendu",
    "serverMessage114": "Utilisateur déjà vérifié",
    "serverMessage115": "Cette fonction est désactivée sur HPE",
    "serverMessage116": "Le mot de passe à usage unique a été envoyé avec succès à votre adresse email",
    "serverMessage117": "Le rôle existe déjà",
    "serverMessage118": "Le pays existe déjà",
    "serverMessage119": "La ville existe déjà",
    "serverMessage120": "Le ou les guide(s) donné(s) a/ont déjà été ajouté(s) dans la file d’attente en vue d’une exportation",
    "serverMessage121": "Catégorie non publiée",
    "serverMessage122": "Catégorie publiée",
    "serverMessage123": "Votre organisation n’est pas vérifiée. Pour obtenir de l’aide, veuillez utiliser l’adresse support@csod.com",
    "serverMessage124": "Le fichier JSON n’est pas disponible pour cette organisation.",
    "serverMessage125": "Les informations d’identification de l’API sont erronées ou n’existent pas.",
    "serverMessage126": "Le fichier JSON n’est pas disponible pour ce guide.",
    "serverMessage127": "Aucune étape disponible. Créez au moins une étape pour publier le guide.",
    "serverMessage128": "Veuillez entrer une adresse email professionnelle valide.",
    "serverMessage129": "Application par défaut.",
    "serverMessage130": "Rôle par défaut.",
    "serverMessage131": "La clé d’API est utilisée pour la configuration de l’authentification SSO.",
    "serverMessage132": "Impossible de traiter cette demande. Veuillez nous contacter à l’adresse contact support@csod.com pour activer cette demande.",
    "serverMessage133": "Vous avez atteint la limite maximale pour créer des applications",
    "serverMessage134": "Cette fonction est désactivée",
    "serverMessage135": "L’horodatage fourni n’est pas valide. Veuillez indiquer un horodatage valide.",
    "serverMessage136": "Vous avez atteint la limite maximale pour créer des utilisateurs.",
    "serverMessage137": "Abonnement non valide/expiré.",
    "serverMessage138": "L’ID SCIM fourni n’est pas valide.",
    "serverMessage139": "Le filtre fourni n’est pas pris en charge.",
    "serverMessage140": "La ressource spécifiée n’est pas disponible.",
    "serverMessage141": "Schéma SCIM non valide.",
    "serverMessage142": "Paramètre non valide.",
    "serverMessage143": "Le(s) segment(s) requis sont manquant(s).",
    "serverMessage144": "Cette tâche est déjà terminée.",
    "serverMessage145": "Ce compte utilisateur est déjà déverrouillé.",
    "serverMessage146": "Votre compte a été temporairement verrouillé en raison d’un trop grand nombre d’échecs de connexion. Nous vous avons envoyé un email indiquant les étapes requises pour le déverrouiller. Pour plus d’assistance, veuillez nous contacter à l’adresse support@csod.com.",
    "serverMessage147": "Données non valides dans le JSON fourni",
    "serverMessage148": "L’authentification SSO n’est pas activée pour cette organisation. Pour plus d’informations, veuillez nous contacter à l’adresse support@csod.com.",
    "serverMessage149": "L’audio n’est pas créé",
    "serverMessage150": "Le fichier Excel de la liste des guides sera bientôt disponible.",
    "serverMessage151": "Les présentations n’existent pas dans l’organisation donnée",
    "serverMessage152": "Votre action précédente est en cours, une fois qu’elle est terminée, vous pouvez effectuer l’action suivante.",
    "serverMessage153": "Les présentations existent déjà dans la catégorie donnée",
    "serverMessage154": "Le ou les fichier(s) n’a/n’ont pas pu être importé(s) dans l’application/la catégorie spécifiée.",
    "serverMessage155": "Les thèmes de fichiers multimédia ne sont pas activés pour cette organisation. Pour plus d’informations, veuillez nous contacter à l’adresse support@csod.com.",
    "serverMessage156": "Destination non valide pour l’importation du Guide",
    "serverMessage157": "Les utilisateurs n’existent pas dans l’organisation donnée",
    "serverMessage158": "Le fichier Excel de la liste des utilisateurs sera bientôt disponible.",
    "serverMessage159": "Le cache Redis est désactivé",
    "serverMessage160": "Vous pouvez épingler jusqu’à 5 guides",
    "serverMessage161": "Le Guide est déjà épinglé",
    "serverMessage162": "Paramètre non valide/vide [mobile_app_id]",
    "serverMessage163": "Le fichier JSON n’est pas disponible pour cette application.",
    "serverMessage164": "L’ID de bundle/package existe déjà.",
    "serverMessage165": "Un segment de même nom existe déjà.",
    "serverMessageDefault": "Désolé pour le dérangement. Pour plus d’informations, veuillez nous contacter à l’adresse support@csod.com.",
    "errorMessage1": "Session expirée !",
    "errorMessage2": "Service non disponible !",
    "dashboardNoOfPartnersTitle": "PARTENAIRE(S)",
    "usersVariable32": "Ajouter un utilisateur interne",
    "usersVariable33": "Attribuer un organisme",
    "customizationCentre": "Centre de personnalisation",
    "customizationCentreBreadcrumb": "Centre de personnalisation",
    "customizationCentreTitle": "Centre de personnalisation |",
    "settings": "Paramètres",
    "brandLogo": "Logo de la marque",
    "brandingWatermark": "Filigrane de personnalisation",
    "widgetIcon": "Icône de widget",
    "widgetIconPosition": "Position de l'icône du widget",
    "widgetIconBadge": "Badge de l'icône du widget",
    "widgetIconZIndex": "z-index du widget",
    "hideWidgetIconOnGuidesPage": "Cacher le widget s'il n'y a pas de guides sur la page",
    "widgetIconVisibility": "Visibilité de l'icône du widget",
    "false": "False",
    "firstSlideImage": "Image de la première diapositive",
    "lastSlideImage": "Image de la dernière diapositive",
    "stepBalloonTheme": "Thème du ballon de l'étape",
    "stepTitleText": "Texte du titre de l'étape",
    "dummyText": "Lorem Ipsum est un texte factice",
    "prev": "Préc",
    "tooltipBalloonTheme": "thème du ballon de l'Tooltip",
    "tooltipBallonInfoTip": "La première ligne du texte de l'Tooltip sera traitée comme le « 'Titre de l'étape' » et la partie suivante comme la « 'Description de l'étape' »",
    "applyStepBallonTheme": "Appliquer le thème du ballon de l'étape",
    "stepSelectorBorderWidth": "Largeur de la bordure du sélecteur d'étape",
    "stepSelectorBorderColor": "Couleur de la bordure du sélecteur d'étape",
    "enableStepAudio": "Activer l'étape audio",
    "preferredColorsForTitleDescription": "Couleurs préférées pour le titre et la description de l'étape",
    "reset": "Réinitialiser",
    "tooltipSelectorBGColor": "Couleur d'arrière-plan du sélecteur de l'Tooltip",
    "tooltipSelectorBorderColor": "couleur de la bordure du sélecteur de l'Tooltip",
    "notificationHeaderColor": "Couleur de l'en-tête de la notification",
    "pushNotificationDelay": "Délai de la notification Push",
    "pushNotificationSnoozeTime": "Délai de répétition de la notification Push",
    "chatbotIcon": "Icône de chatbot",
    "chatbotPosition": "Position du chatbot",
    "branding": "Personnalisation",
    "widget": "Widget",
    "videogif": "Vidéo & GIF",
    "step": "Étape",
    "notification": "Notification",
    "chatbot": "Chatbot",
    "features": "Fonctions",
    "labels": "Labels",
    "top": "Supérieur",
    "bottom": "Inférieur",
    "left": "Gauche",
    "right": "Droit",
    "chatbotGreetingMsg": "Message d'accueil du Chatbot",
    "defaultChatbotGreeting": "Hé ! Bienvenue dans MyGuide. Comment puis-je vous aider ? N'oubliez pas que je suis un bot.",
    "chatbotGreetingEndMsg": "Message de fin de conversation du chatbot",
    "defaultChatbotEnd": "Nous avons terminé, merci beaucoup !",
    "chatbotErrorMsg": "Message d'erreur du Chatbot",
    "defaultChatbotError": "Je suis désolé, je n'ai pas compris. Pourriez-vous être plus précis ?",
    "miniPlayerHeader": "En-tête du mini-lecteur",
    "showAllGuidesTab": "Afficher l'onglet « Tous les Guides »",
    "defaultGuideActionInPlayer": "Action de guidage par défaut dans le lecteur",
    "defaultWebPlayerMode": "Mode par défaut du lecteur Web",
    "default": "Valeur par défaut",
    "miniPlayer": "Mini Player",
    "playerPanelDefaultMode": "Mode par défaut du panneau du lecteur",
    "open": "Ouvrir",
    "closed": "Fermé",
    "configureFeedbackOptn": "Configurer l'option de feed-back",
    "email": "E-mail",
    "imageLibrary": "Bibliothèque d'images",
    "submit": "Soumettre",
    "classic": "Classique",
    "defaultPagePlayer": "Page par défaut dans le lecteur",
    "allGuides": "Tous Guides",
    "currentPage": "Page actuel",
    "bgColor": "Couleur de l'arrière-plan",
    "borderColor": "Couleur de la bordure",
    "iconColor": "Couleur des icônes",
    "stepTitleColor": "Couleur du titre de l'étape",
    "stepDescColor": "Couleur de la description de l'étape",
    "borderRadius": "Rayon de la bordure",
    "prevBTNBG": "Arrière-plan du bouton Précédent",
    "prevBTNText": "Texte du bouton Précédent",
    "borderWidth": "Largeur de la bordure",
    "nextBTNBG": "Arrière-plan du bouton Suivant",
    "nextBTNText": "Texte du bouton Suivant",
    "popupWidth": "Largeur de la fenêtre contextuelle",
    "colorPicker": "Sélecteur de couleurs",
    "brandLogoTooltip": "Dimensions de l'image : 100 x 40 pixels",
    "brandingWatermarkTooltip": "Un élément de personnalisation à superposer au bas de vos vidéos et GIF. Pour de meilleurs résultats, utilisez la transparence et les dimensions recommandées\nDimensions de l'image : 150 x 150 pixels",
    "widgetIconBadgeTooltip": "Ce badge est visible si des guides sont présents sur la page en cours.",
    "firstSlideText": "Texte de la première diapositive",
    "slideTextTooltip": "Le texte de la diapositive s'applique uniquement à une vidéo et non à un GIF",
    "lastSlideText": "Texte de la dernière diapositive",
    "pushNotifDelayTooltip": "Afficher la fenêtre contextuelle de notification Push après (plage autorisée de 0 à 5 secondes)",
    "pushNotifSnoozeTime": "Ne pas afficher les notifications fermées pendant [select] heures",
    "playerPanelOpenLabelTooltip": "Ouvre le panneau du lecteur à chaque fois que la page est chargée",
    "widgetPosi1": "En haut à droite",
    "widgetPosi2": "En haut à gauche",
    "widgetPosi3": "En bas à droite",
    "widgetPosi4": "En bas à gauche",
    "URL": "URL",
    "urlHostname": "Nom d'hôte de l'URL",
    "urlPath": "Chemin de l'URL",
    "urlParams": "Paramètres de l'URL",
    "urlHash": "Hash de l'URL",
    "pageTitle": "Titre du Page",
    "equals": "Égal",
    "notEquals": "Non égal",
    "contains": "Contient",
    "doesNotContain": "Ne contient pas",
    "startsWith": "Commence par",
    "endsWith": "Se termine par",
    "discardBtnTxt": "Rejeter",
    "addRule": "Ajouter une règle",
    "createRules": "Créer des règles",
    "stepRules": "Règles d'étape",
    "building": "Construction",
    "business": "Commerce",
    "education": "Enseignement",
    "fashion": "Mode",
    "finance": "Finances",
    "food": "Agro-alimentaire",
    "healthcare": "Santé",
    "miscellenous": "Divers",
    "nature": "Nature",
    "people": "Affaires sociales",
    "science": "Sciences",
    "technology": "Technologie",
    "transport": "Transport",
    "work": "Travail",
    "images": "Images",
    "icons": "Icônes",
    "colors": "Couleurs",
    "accessibility": "Accessibilité",
    "animal": "Animal",
    "audioAndVideo": "Audio & video",
    "automotive": "Secteur automobile",
    "charity": "Activités caritatives",
    "chat": "Forum",
    "communication": "Communication",
    "solid": "Sérieux",
    "trendy": "Branché",
    "popular": "Populaire",
    "random": "Aléatoire",
    "resumeGuide": "Reprendre avec le Guide :",
    "oopsMessage": "Message Oups :",
    "maintaninence": "Maintenance :",
    "serviceError": "Erreur d'entretien :",
    "internalUsers": "Utilisateurs internes :",
    "externalUsers": "Utilisateurs externes",
    "partnerId": "ID partenaire",
    "addPartner": "Ajouter un partenaire",
    "partner": "Partenaire",
    "partnerAdmin": "Partenaire Admin",
    "partners": "Partenaire",
    "editPartner": "Éditer le partenaire",
    "addInternalUser": "Ajouter un utilisateur interne",
    "editInternalUser": "Modifier l'utilisateur interne",
    "unassign": "Annuler l'attribution",
    "orgAssign": "Attribuer une organisation",
    "tableHeaderFolder": "Dossier",
    "labelAppInsights": "Insights sur l’application :",
    "version": "Version",
    "decommissionBtn": "Mettre hors service",
    "deleteBackupBtn": "Supprimer la sauvegarde",
    "reactivateBtn": "Réactiver",
    "activeOrgs": "Organisations actives",
    "suspendedDeletedOrgs": "Organisations suspendues / mises hors service",
    "suspendedByUser": "Suspendues par utilisateur :",
    "suspendedByEmail": "Suspendues par e-mail :",
    "suspendedOn": "Suspendues le :",
    "deletedByUser": "Supprimées par utilisateur :",
    "deletedByEmail": "Supprimées par e-mail :",
    "deletedOn": "Supprimées le :",
    "decommissionContent": "Voulez-vous vraiment mettre hors service cette organisation ? Cette action ne peut pas être annulée.",
    "deleteBackupOf": "Supprimer la sauvegarde de",
    "deleteBackupContent": "Voulez-vous vraiment supprimer cette sauvegarde d'organisation ? Cette action ne peut pas être annulée.",
    "enableMediaNotification": "Activer la notification de création de fichier multimédia",
    "guideName": "Nom du Guide",
    "keywords": "Mots clés",
    "addKeywords": "Ajouter un ou plusieurs mot(s) clé(s)",
    "manageKeywords": "Gérer les mots clés",
    "published": "Publié",
    "keywordLoading": "Mise à jour des mots clés. Cela prendra quelques minutes. Il est sûr de s’éloigner",
    "assignKeywords": "Attribuer des mots-clés",
    "keywordTooltip": "Appuyez sur Entrée ou sur une virgule pour ajouter un mot clé",
    "manageSegments": "Gérer les segments",
    "guides": "Guides",
    "segmentTitle": "Titre du segment",
    "addSegmentTitle": "Ajouter un titre de segment",
    "assignSegments": "Attribuer des segments",
    "segmentLoading": "Mise à jour des segments. Cela prendra quelques minutes. Il est sûr de s’éloigner",
    "addSegment": "Ajouter un ou plusieurs segment(s)",
    "segmentTooltip": "Sélectionnez un ou plusieurs segments dans la liste",
    "addASegment": "Ajouter un segment",
    "deleteSegment": "Supprimer le segment",
    "confirmDeleteSegment": "Voulez-vous vraiment supprimer ce segment ?",
    "findReplace": "Trouver & remplacer",
    "apps": "Applications",
    "content": "Contenu",
    "text": "Texte",
    "element": "Élément",
    "find": "Rechercher",
    "replace": "Remplacer",
    "selectElement": "Sélectionner l’élément",
    "elementPreview": "Prévisualisation de l’élément",
    "action": "Action",
    "findReplaceSearchPlaceholder": "Que recherchez-vous ?",
    "noGuideFound": "Aucun Guide ne correspond à votre recherche",
    "modifySearchCriteria": "Essayez de modifier vos critères de recherche",
    "maxGuideSelected": "Vous pouvez sélectionner au maximum 50 Guide (s) à la fois",
    "import": "Importer",
    "paste": "Coller",
    "importedGuides": "Guides importé",
    "exportedGuides": "Guides exporté",
    "transferGuides": "Transférer les Guides",
    "export": "Exporter",
    "pageTracking": "Suivi du Page",
    "featureTracking": "Suivi des fonctionnalités",
    "enableMirrorApplications": "Activer les applications miroir",
    "selfHostedPlayer": "Lecteur auto-hébergé",
    "playerPackages": "Forfaits lecteur",
    "createPlayerPackage": "Créer un forfait lecteur",
    "playerPackageError": "Cette action n'est pas autorisée car le travail est déjà en cours",
    "selfHostedPlayerURL": "Lecteur auto-hébergé URL",
    "selectApps": "Select Apps",
    "tooltipPageTracking": "This enables tracking of various URL (of domains have been added into applications). Along with various metrics like page load time, time spend on page, etc.",
    "tooltipFeatureTracking": "This enables click tracking based on feature guides created in application. This tracking is silent and transparent to the user",
    "commonLoginMsg": "This is common login for Admin portal & MyGuide Insights",
    "serverMessage166": "A new password can not be the same as the old 5 password.",
    "serverMessage167": "The Title field may only contain alpha characters and spaces",
    "tutorialSelectorBGColor": "Tutorial selector background color",
    "circular": "Circular",
    "rectangular": "Rectangular",
    "width": "Width",
    "height": "Height",
    "stepPopupBtnNext": "Bouton contextuel de l'étape : « Suivant »",
    "stepPopupBtnPrev": "Bouton contextuel de l'étape : « Précédent »",
    "enableChat": "Activate Chat",
    "resumeGuideDefaulText": "Resume Guide",
    "oopsMessageDefaulText": "It looks like you're performing steps that are not part of this Guide. If you no longer wish to follow the Guide, click on Exit Guide",
    "maintenanceDefaulText": "Maintenance in progress. Please contact us via support@csod.com",
    "serviceErrorDefaulText": "Oops! An error occurred. Please try again after some time. You can also email us at support@csod.com for any assistance",
    "invalidImageText": "Selected files should be in png or jpeg format",
    "tutorialSteps": "Tutorial Steps",
    "tutorialModal": "Thème du ballon de l'Tutorial}",
    "tutorial": "Tutorial",
    "titleBgColor": "Title Background Color",
    "prevBtnLabel": "Previous Button",
    "nextBtnLabel": "Next Button",
    "color": "Color",
    "background": "Background",
    "fontSize": "Font Size",
    "outerBox": "Outer Box",
    "outerBoxShadow": "Outer Box Shadow",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "blur": "Blur",
    "spreadRadius": "Spread Radius",
    "inset": "Inset",
    "noApplicationAssigned": "No Application assigned",
    "pasteErrorMessage": "Guides cannot be pasted in same category",
    "pasted": "Pasted Successfully !!",
    "exportMessage": "Guide will be available for export soon. To check the status, go to 'Exported Guides' under the Guides section",
    "importMessage": "Import of guides started. To check the status, go to 'Imported Guides' tab under the Guides section",
    "srNo": "Sr No.",
    "jsonProcessingIncomplete": "This action is not allowed because json processing is not completed",
    "contentCreationNotAllowed": "Content creation is not allowed for Mirror Applications.",
    "ssoUrl": "SSO URL",
    "selectSegment": "Sélectionner un Segment}",
    "enterNewTag": "Entrer un nouveau tag",
    "mediaFileCreationForTooltipGuides": "Création de fichiers multimédias pour les guides d'info-bulles",
    "tooltipMediaFileCreationTooltipGuides": "Permet l'exportation de la création de fichiers multimédias pour les guides d'info-bulles.",
    "mediaFileCreationForTooltipGuidesToastInfo": "L'activation des guides d'info-bulles pour la création de fichiers multimédias n'est possible que lorsque le paramètre 'Création de fichiers multimédias' est activé.",
    "importSegmentValues": "Importer valeurs Segment",
    "importSegmentValuesHistory": "Importer historique des valeurs Segment",
    "tooltipGuideAutomation": "Active la fonctionnalité Guide automatisée",
    "sendFeedbackOptn": "Activer l'envoi de commentaires",
    "chatbotVisibilitySettings": "Paramètres de visibilité du chatbot",
    "padding": "Remplissage :",
    "excludeDomains": "Exclure des domaines",
    "excludeDomainsTooltip": "Définir une liste de domaines où MyGuide} ne devrait pas être disponible",
    "firstNameError": "Le prénom ne doit contenir aucun caractère spécial",
    "lastNameError": "Le nom ne doit contenir aucun caractère spécial",
    "noteForCustomizationCentre": "La disponibilité des fonctionnalités ci-dessous est soumise à leur disponibilité dans la catégorie de produits cible (par exemple : Application mobile, application de bureau, etc.) et peut changer au fil du temps.",
    "enforceSequence": "Appliquer une séquence",
    "guideSequenceUpdated": "Séquence Guide} mise à jour",
    "updateSequence": "Mettre à jour séquence",
    "shiftUp": "Décaler vers le haut",
    "shiftDown": "Décaler vers le bas",
    "configureHelpLocation": "Emplacement de l'aide pour la configuration",
    "creataOrganization": "Créer une organisation",
    "createPartner": "Créer un partenaire",
    "createSegment": "Créer Segment",
    "oneOrMoreGuidesPresent": "Un ou plusieurs des guides sélectionnés existent déjà dans la liste de tâches",
    "betaFeatures": "Fonctions bêta",
    "keyboardShortcuts": "Raccourcis clavier",
    "keyboardShortcutsTooltip": "Les raccourcis clavier vous permettent de créer des raccourcis de lecture des guides du flux de travail. Pour voir une liste complète des raccourcis clavier créés, tapez ? lors de l'affichage de l'application Web client dans votre navigateur.",
    "dashboardBreadCrumb": "Dashboard",
    "tableHeaderModificationDate": "Date de modification",
    "headerVariable6": "Aide",
    "productType": "Type de produit",
    "guideInventory": "Matériel Guide",
    "createdBy": "Créé par",
    "modifiedBy": "Modifié par",
    "guideId": "ID Guide",
    "botGuide": "Robot Guide",
    "tooltipGuide": "Tooltip Guide",
    "workflowGuide": "Workflow Guide",
    "featureTags": "Fonction Tags",
    "tutorialGuide": "Tutorial Guide",
    "guideType": "Type de Guide",
    "unpublish": "Annuler la publication",
    "environments": "Environnements",
    "publishGuide": "Publier Guide",
    "importSegment": "Importer Segments",
    "importSegmentHistory": "Importer l'historique Segments",
    "guidePublishInfoMessage": "Le Guide sera publié dans",
    "guidePublishedSuccessfully": "Guide publié avec succès",
    "guideUnpublishedSuccessfully": "Publication de Guide annulée avec succès",
    "enableDAP": "Activer les notifications DAP de Insights",
    "enableDAPTooltip": "Lorsque ce paramètre est activé, les valeurs par défaut définies dans Insights Portal sont utilisées, sous Control Center, pour Préférences de notification par email > Statistiques d’utilisation hebdomadaires et Rapport quotidien sur les étapes en échec. Si ce paramètre est désactivé, les paramètres par défaut seront remplacés et cela désactivera l'envoi des notifications pour Statistiques d’utilisation hebdomadaires et Rapport quotidien sur les étapes en échec dans le Centre de contrôle Insights",
    "showtooltipsWithWorkflowTutorial": "Afficher Tooltips avec Workflow et Tutorial Guides",
    "showtooltipsWithWorkflowTutorialTooltip": "Permet l'affichage des guides Tooltip sur une page qui affiche également les guides Workflow ou Tutorial. Lorsque ce paramètre est désactivé, les guides Tooltip ne sont pas affichés en parallèle avec les guides Workflow ou Tutorial.",
    "exportInventoryToastMessage": "Les données de Guide ont été mises en file d'attente pour l'exportation. Pour vérifier l'état, accédez à Exporter les données dans la section Gérer Guides.",
    "autobot": "AutoBot",
    "globalFeatureFlags": "Indicateurs de fonctionnalités globales",
    "beta": "Bêta",
    "legacy": "Existant",
    "enterprise": "Entreprise",
    "featureStatusChanged": "Indicateur de fonctionnalité globale mis à jour avec succès",
    "confirmChanges": "Confirmer les modifications",
    "featureFlagConfirmationMessage": "Cela se propagera à toutes les organisations. Voulez-vous continuer ?",
    "generatePasswordOneLowerCase": "Au moins une lettre minuscule",
    "pageEnteredOutsideRange": "Le numéro Page est en dehors de la plage",
    "showing": "Affichage",
    "of": "sur",
    "segmentation": "Segmentation",
    "customization": "Personnalisation",
    "administration": "Administration",
    "userAttributes": "Attributs utilisateur",
    "addUserAttributesButtonText": "Ajouter un attribut utilisateur",
    "updateUserAttributesButtonText": "Mettre à jour l'attribut utilisateur",
    "assignUserAttributes": "Attribuer des valeurs d'attribut utilisateur",
    "userAttributeValues": "Valeurs des attributs utilisateur",
    "addUserAttributeValues": "Ajouter des valeurs d'attribut utilisateur",
    "importUserAttributeValues": "Importer les valeurs d'attribut utilisateur",
    "importUserAttributeValuesHistory": "Importer l'historique des valeurs d'attribut utilisateur",
    "importUserAttributes": "Importer les attributs utilisateur",
    "importUserAttributesHistory": "Importer l'historique des attributs utilisateur",
    "addingUserAttributeButtonText": "Ajout d'un attribut utilisateur...",
    "updatingUserAttributeButtonText": "Mise à jour de l'attribut utilisateur.",
    "userAttributeCreatedText": "Attribut utilisateur créé",
    "userAttributeUpdatedText": "Attribut utilisateur mis à jour",
    "userAttributeDeletedText": "Attribut utilisateur supprimé",
    "userAttributeValueCreatedText": "Valeur d'attribut utilisateur créée",
    "userAttributeValueDeletedText": "Valeur d'attribut utilisateur supprimée",
    "widgetIconZIndextooltip": "Z-Index est utilisé pour contrôler la façon dont MyGuide s'affiche sur les éléments à l'écran. Dans certains cas, MyGuide les composants sont masqués derrière des éléments à l'écran. Le réglage d'une valeur Z-Index supérieure ou inférieure peut améliorer les chances d'afficher MyGuide composants sur les éléments à l'écran (valeur comprise entre -2147483646 et 2147483646).",
    "mirrorApplications": "Applications en miroir",
    "enabled": "Activé",
    "disabled": "Désactivé",
    "appIntelligence": "Intelligence des applications",
    "greaterThan": "Plus grand que",
    "lessThan": "Inférieur à",
    "userCreationDate": "Date de création de l'utilisateur",
    "rule_dayOfWeek": "<ul><li>Utilisez les 3 premières lettres de l’orthographe anglaise pour désigner un jour</li><br><li>Considérez le dimanche comme le début de la semaine</li><br><li>Utilisez le <i>trait d’union</i> « - » pour spécifier l’intervalle.</li><br><li>OU ne mentionner qu’un jour précis</li><br><li><i>Le séparateur droit</i> « | » peut être utilisé en cas d’options multiples.</li><br><li>Exemple de valeurs acceptées : <br>« Lun - Ven », <br>« Mar », <br>« Lun - Mer | Ven ».</li></ul>",
    "rule_fixTime": "<ul><li>Entrez l’heure au format 24 heures</li><br><li>Utilisez le <i>trait d’union</i> « - » pour spécifier l’intervalle.</li><br><li>OU ne mentionner qu’une heure de début</li><br><br><li>L’heure de fin sera considérée comme la fin de la journée (23:59) sauf si une valeur valide est spécifiée</li><br><li><i>Le séparateur droit</i> « | » peut être utilisé en cas d’options multiples.</li><br><li>Exemple de valeurs acceptées : <br>« 09:00 - 18:00 », <br>« 11:00 », <br>« 09:00-12:00 | 16:00 ».</li></ul>",
    "rule_dateRange": "Les champs de date <span><b>Début</b> et <b>Fin</b> sont obligatoires</span>",
    "insightsCustomerType": "Insights Type de client",
    "myguideCustomers": "Clients de Cornerstone Guide",
    "csodInternal": "CSOD Interne",
    "insightsCustomerTypeMessage": "L'activation de ce paramètre peut entraîner la purge des anciennes données enregistrées dans Cornerstone Guide Insights",
    "insightsCustomerTypeTooltip": "Ce paramètre modifiera les bases de données pour répartir les charges entre les clients payants de Cornerstone Guide et ceux de CSOD.",
    "date": "Date",
    "time": "Temps",
    "dayOfWeek": "Jour de la semaine",
    "fixedTime": "Heure fixe dans la journée",
    "dateRange": "Tranches de date",
    "displayFrequency": "Fréquence d’affichage",
    "platFormUsageReport": "Rapport d'utilisation de la plateforme",
    "platformUsageReportInfo": "Un rapport qui présente les données globales de Cornerstone Guide en tant que plateforme, en fournissant des informations sur la croissance des utilisateurs et leurs consommations dans toutes les organisations",
    "totalUsers": "Nombre total d'utilisateurs",
    "newUsers": "Nouveaux utilisateurs",
    "activeUserSessions": "Sessions utilisateur actives",
    "guidesCreated": "Guides créés",
    "guidesPublished": "Guides Publié",
    "guidesPlays": "Consultations de Guides",
    "tooltipsCreated": "Tooltips Créé",
    "tooltipsPublished": "Tooltips Publié",
    "tooltipsPlays": "Consultations de Tooltips",
    "autoProceedStep": "Passer automatiquement à l'étape suivante",
    "automationStepDelay": "Délai de passage automatique à l'étape suivante",
    "userProvisioning": "Provisionnement de l’utilisateur",
    "hideBrandLogo": "Masquer le logo de la marque",
    "enableUserSessionTracking": "Activer le suivi des sessions utilisateur",
    "viewActivityDetails": "Afficher les détails de l'activité",
    "editingSegment": "Modifier un segment",
    "selectSegmentUserAttribute": "Sélectionnez un attribut utilisateur",
    "selectUserAttributeAndValues": "Sélectionner les attributs et les valeurs de l'utilisateur",
    "editingSegmentInfoMessage": "Segments vous permettent de contrôler l'accès aux guides pour des groupes d'utilisateurs ciblés. Les attributs et les valeurs des utilisateurs reposent sur les profils des utilisateurs, qui sont introduits par le biais d'intégrations d'utilisateurs, telles que l'authentification unique, ou gérés manuellement à partir des attributs des utilisateurs dans la segmentation. En outre, utilisez des règles pour contrôler l'emplacement des guides pour les utilisateurs segmentés, par exemple sur des pages ou des périodes spécifiques. Les attributs utilisateur et les règles sont utilisés ensemble pour créer le segment.",
    "numOfPartnerAdminsAllowed": "Nombre d'administrateurs partenaires autorisés",
    "numOfPartnerAdminExceeded": "Le nombre d'administrateurs partenaires pour le partenaire actuel a été dépassé. Veuillez nous contacter via l’adresse support@csod.com",
    "valueShouldBeGreaterThan": "La valeur doit être supérieure ou égale à",
    "setVisibilitySetting": "Définir la visibilité du Guide après la publication",
    "visibilitySettingsUpdated": "Paramètres de visibilité mis à jour",
    "auto_generate_keywords": "Guide Assistance pour les créateurs",
    "editCredentials": "Modifier les informations d'identification",
    "createSegmentOptionOne": "Afficher les Guides dans le lecteur si un utilisateur fait partie du Segment.",
    "createSegmentOptionTwo": "Masquer les Guides dans le lecteur si un utilisateur fait partie du Segment.",
    "createSegmentOptionThree": "Afficher les Guides dans le lecteur si un utilisateur fait partie du Segment."
};
module.exports = GmXt.playerLbls.fr_FR;