import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { UserService } from 'src/app/common/services/user.service';
import { TasksService, Tasks } from 'src/app/common/services/tasks.service';
import { OrganisationService } from 'src/app/common/services/organisation.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PaginationService } from 'src/app/pagination.service';
import { debounceTime, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from "@angular/forms";
import { ADD_TASK_LIST_FORMDATA } from 'src/app/constants/FormData/add_task';
import { ApplicationService, Application } from 'src/app/common/services/application.service';
import { LeaderBoardService, LeaderBoardData } from 'src/app/common/services/leader-board.service';
import { ModalPaginationService } from 'src/app/common/services/modal-pagination.service';
import { ErrorResponseService } from "src/app/common/services/error-response.service"
import { ToastModalService } from 'src/app/common/services/toast-modal.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { ManageSegmentsService } from 'src/app/manage-segments.service';
import { BehaviorSubject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  pageLimitSet = false;
  modalLimitSet = false;
  deleteIndex = 0;
  delete_task_id = 0;
  onClose = false;
  prevGetTaskListCall;
  segmentText = "Segments";
  applicationVariableData = new Map();
  segmentFetchError = false;
  taskList_formData = JSON.parse(
    JSON.stringify(ADD_TASK_LIST_FORMDATA)
  );

  formGroup;
  visibilityFormGroup;
  applications: Application[];
  prevGetUserListCall: any;
  attribute_count: number = 1;
  doubleSlashValidator;
  flag = true;
  elem_map = [
    {
      text: "",
      select: "",
    },
  ];
  elem_groups = [
    [{
      text: "",
      select: "",
    }]
  ];
  curr_settings = {};
  @ViewChildren("attributes") attributes: QueryList<ElementRef>;
  admin_data = adminLabels;
  segmentData = [];
  segmentDataMapper = new Map();
  selectedItems = [];

  rule_variable_options = [];
  rule_type_options = [
    this.admin_data.select,
    "URL",
    "URL Hostname",
    "URL Path",
    "URL Parameters",
    "URL Hash",
    "Page Title",
    "Variables"
  ].map(option => ({ value: option, name: option }));
  rule_type_options_hash = [
    this.admin_data.select,
    "URL",
    "URL Hostname",
    "URL Path",
    "URL Parameters",
    "Page Title"
  ];
  rule_condition_options = [
    this.admin_data.select,
    "Equals",
    "Not Equals",
    "Contains",
    "Does Not Contain",
    "Starts With",
    "Ends With"
  ].map(option => ({ value: option, name: option }));
  rule_condition_options_url_param = [
    this.admin_data.select,
    "Equals",
    "Not Equals",
    "Contains",
    "Does Not Contain",
  ].map(option => ({ value: option, name: option }));
  rule_condition_options_variables = [
    this.admin_data.select,
    "Text Is",
    "Text Is Not",
    "Text Contains",
    "Does Not Contain",
    "Text Matches"
  ].map(option => ({ value: option, name: option }));

  rules: any;
  visible = false;
  showVariablesSelect = false;
  private gotoPageSubject = new BehaviorSubject<number>(1);
    modalHeader: any;
    modalContent: any;
    
  constructor(
    public userService: UserService,
    public tasksService: TasksService,
    private organisationService: OrganisationService,
    private route: ActivatedRoute,
    private titleService: Title,
    public paginationService: PaginationService,
    public modalPaginationService: ModalPaginationService,
    private router: Router,
    private toastr: ToastrService,
    private applicationService: ApplicationService,
    public leaderBoardService: LeaderBoardService,
    private errorResponseService: ErrorResponseService,
    private toastService: ToastModalService,
    private segmentationService: ManageSegmentsService
  ) {
    this.titleService.setTitle(this.admin_data.taskList + " | " + "Cornerstone Guide");
    this.userService.getUserData();
    // if(this.userService.user.role != "superadmin" && !this.userService.enableTaskList){
    //   this.router.navigate([
    //     "/organization",
    //     this.userService.organization_id
    // ]);
    // }
    this.paginationService.initializeValues();
    this.modalPaginationService.initializeValues();
    this.organisationService.getCDNData()

    this.userService.activeTab = 'task list';
    this.route.paramMap.subscribe((params) => {
      this.org_id = params["params"]["org_id"];
      this.userService.organization_id = this.org_id;
      if (this.org_id && (this.userService.user.role == "superadmin" || this.userService.user.role == "mg_admin" || this.userService.user.role == "partner_admin")) {
        this.getOrganization();
      }

      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          // this.userService.setBreadcrumbs(this.admin_data.taskList, this.route.url["_value"]);
          this.userService.setBreadcrumb([
            {
              name: this.admin_data.taskList,
              route: null
            }
          ])
          this.breadcrumb = this.userService.breadCrumbs;
        });
    });
    this.getApplications();
    this.getSegments();
    this.rules = false;



    this.doubleSlashValidator = function doubleslashValidator(control: AbstractControl): { [key: string]: boolean } | null {

      if (control && control.value) {
        if (control.value.indexOf("//") != -1) {
          return { doubleslashValidator: true }
        }

        let val_split = control.value.split("/");
        for (let i = 0; i < val_split.length; i++) {
          if (val_split[i].indexOf("~=") > -1 || (val_split[i].indexOf(">") > -1 && val_split[i].indexOf("<") > -1)) {
            if (val_split[i].indexOf("~=") > -1 && (val_split[i].indexOf(">") > -1 || val_split[i].indexOf("<") > -1)) {
              return { angularTileAndtildaEqual: true }
            }
          }

          if (val_split[i].indexOf("-") > -1 || val_split[i].indexOf("_") > -1) {
            if (val_split[i].indexOf(">") > -1 && val_split[i].indexOf("<") > -1) {
              let str = JSON.parse(JSON.stringify(val_split[i]));
              for (let j = 0; j < str.length; j++) {
                if (!(str.charAt(j) == "<" || str.charAt(j) == ">" || str.charAt(j) == '-' || str.charAt(j) == '_')) {
                  str = str.replaceAll(str.charAt(j), "0");
                }
              }
              str = str.replaceAll("0", "");
              for (let j = 0; j < str.length; j++) {
                if (str.charAt(j) == '-' || str.charAt(j) == '_') {
                  if (str.charAt(j + 1) == '<' || str.charAt(j - 1) == '>') {
                    return { invalidhyphenunderscore: true }
                  }
                }
              }
            } else {
              return { invalidhyphenunderscore: true }
            }
          }

          if (val_split[i].indexOf("~") > -1 || val_split[i].indexOf("=") > -1) {
            if ((val_split[i].indexOf("~") > -1 || val_split[i].indexOf("=") > -1) && val_split[i].indexOf("~=") == -1) {
              return { tildaEqualNotAlone: true }
            } else {
              let str1 = JSON.parse(JSON.stringify(val_split[i]));
              str1 = str1.replaceAll("~=", "0");
              if (str1.indexOf("~") > -1 || str1.indexOf("=") > -1) {
                return { tildaEqualNotAlone: true }
              }
            }
          }


          // if(val_split[i].indexOf(">")>-1 && val_split[i].indexOf("<")>-1){
          //   let store=[];
          //   let invalid_braces=false;
          //   for(let j=0;j<val_split[i].length;j++){
          //     if(val_split[i].chartAt(j)=='<'){
          //       store.unshift("<");
          //       let k=j;
          //       while(val_split[i].charAt(k)!='>'){
          //         if(val_split[i].charAt(k)=='<'){
          //           invalid_braces=true;
          //          return {invalidBraces:true}
          //         }else if(val_split[i].charAt(k)=='>'){
          //           let str = val_split[i].slice(j,k);
          //         }
          //         k++;
          //       }
          //     }
          //   }
          // }
        }
      }
      return null;
    }

    $('[data-toggle="tooltip"]').tooltip({
      html: true
    });
  }

  tasks: Tasks[];
  selectedOrg;
  org_id;
  limit = 10;
  offset = 0;
  search = '';
  is_searching = false;
  modal_limit = 10;
  modal_offset = 0;
  search_user = '';
  breadcrumb;
  lastSearchedValue = "";
  leader_board_data: LeaderBoardData[] = [];

  getOrganization() {
    this.organisationService.getOrganization(this.org_id).subscribe(
      (response) => {
        let organization = response["data"]["organization"];

        this.userService.organization = organization["name"];
        this.userService.organization_id = organization["organization_id"];
        this.selectedOrg = this.userService.organization_id;
        this.userService.organization = organization;
        let admin_settings = JSON.parse(
          this.userService.organization.admin_settings
        );
        this.userService.enableSegments =
          admin_settings["guide_segmentation"] == "1" ? true : false;
        this.userService.enableSSO = admin_settings["sso"] == 1 ? true : false;
        this.userService.enableConnector = admin_settings['api_connector'] == 1 ? true : false;
        this.userService.enableTaskList = admin_settings['task_list'] == 1 ? true : false;
        this.userService.enableMediaThemes = admin_settings['media_files_themes'] == 0 ? false : true;

        this.userService.enablePlayerPackages = admin_settings['self_hosted_player'] == "1" ? true : false;


        // if(!this.userService.enableTaskList){
        //   this.router.navigate([
        //     "/organization",
        //     this.userService.organization_id
        // ]);
        // }

        let org = organization;
        let cdn_sign = JSON.parse(localStorage.getItem("cdn_sign"));

        cdn_sign["cdn_signature"] = organization["cdn_signature"];
        cdn_sign["cdn_signature_expiry"] = Math.floor(Date.now() / 1000) + 3480;

        this.organisationService.cdn_signature = cdn_sign["cdn_signature"];
        this.organisationService.cdn_signature_expiry = cdn_sign["cdn_signature_expiry"];

        localStorage.setItem("organization", JSON.stringify(org));
        localStorage.setItem("cdn_sign", JSON.stringify(cdn_sign));
        // this.ngOnInit();
      }
    );
  }

  ngOnInit() {
    this.is_searching = false;
    if (this.userService.user.role != "superadmin") {
      this.getTasksList();
    } else if (
      this.userService.user.role == "superadmin" &&
      this.selectedOrg == this.userService.organization_id
    ) {
      this.getTasksList();
    }

    this.addForm();
    this.addVisibilityForm();

    this.gotoPageSubject.pipe(
      debounceTime(800))
      .subscribe((page: number) => {
        if (page != this.paginationService.currentPage) {
          this.offset = this.paginationService.gotoPage(page, this.limit, this.offset);
          if (this.offset >= 0) this.getTasksList();
        }
      })
  }

  addForm() {
    let form = {};
    for (let i = 0; i < this.taskList_formData.length; i++) {
      form[this.taskList_formData[i].name] = new FormControl("");
      let validations = [];
      for (let v of this.taskList_formData[i].validators) {
        for (let key in v) {
          validations.push(key);
        }
      }
      let validators = [];
      for (let j = 0; j < validations.length; j++) {
        switch (validations[j]) {
          case "required":
            validators.push(Validators.required);
            break;
        }
      }
      form[this.taskList_formData[i].name].setValidators(validators);
    }
    this.formGroup = new FormGroup(form);
    this.formGroup.get("title").valueChanges.subscribe(x => {
      if (x && !this.userService.pattern.test(x)) {
        this.formGroup.get("title").setValue(x.replace(this.userService.replacePattern, ""), { emitEvent: false });
      }
    });
  }

  getTasksList() {

    if (this.prevGetTaskListCall) {
      // this.prevGetTaskListCall.unsubscribe();
      clearTimeout(this.prevGetTaskListCall);
    }
    this.prevGetTaskListCall = setTimeout(() => {
      this.tasksService.getTasksList(this.limit, this.offset, this.search).subscribe((response) => {
        if (!response['error']) {
          // if (!this.pageLimitSet) {
          this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
          this.pageLimitSet = true;
          // }
          this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);

          if (response['data']['task_list']) {
            this.tasks = response['data']['task_list'];
            if (this.applications) {
              this.tasks.forEach(task => {
                let type = this.applications?.find(app => app.application_id === task['application_id'])?.type;
                task.application_title = type ? task.application_title + " (" + type + ")" : task.application_title;
              })
            }

            this.paginationService.setPaginationStatus(this.tasks, this.limit);
            if (this.toastService.showToast) {
              this.toastService.showToastMessage(this.toastService.toastType);
            }
          }
        } else {
          let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
          this.userService.errorMessage = errorResponse;
          $("#errorModal").modal("show");
        }
      })
    }, this.is_searching ? 300 : 0)
  }

  getTaskListData(task_list) {
    this.tasksService.isEdit = true;
    this.taskList_formData[1].disable = true;
    this.tasksService.isEdit = true;
    // this.showAddTaskListModal();
    // this.taskList_formData[1].disable = true;
    this.tasksService.selectedTask = task_list;
    $("#addTaskListModal").modal("show");
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    for (let i = 0; i < this.taskList_formData.length; i++) {
      if (this.tasksService.selectedTask[this.taskList_formData[i].name]) {
        this.formGroup.patchValue({
          [this.taskList_formData[i].name]: this.tasksService.selectedTask[
            this.taskList_formData[i].name
          ],
        });
      }
    }
  }

  searchTaskList() {
    // this.limit = 10;
    this.offset = 0;
    this.paginationService.initializeValues();
    this.pageLimitSet = false;
    this.is_searching = true;
    this.getTasksList();
  }

  showModal(id) {
    this.tasks.forEach((elem, i) => {
      if (elem.id == id) {
        this.deleteIndex = i;
        this.delete_task_id = id;
      }
    });
    this.modalHeader = this.admin_data.deleteTasklist;
    let tasklist = this.tasks[this.deleteIndex]['title']
    let str = this.admin_data.deleteTasklistContent
    str = str.split(' ')
    str.splice(str.length-1, 0,"'" + tasklist + "'")
    this.modalContent = str.join(' ');
    this.organisationService.showConfirmationModal.next(true);
  }

  deleteTask(app) {
    this.userService.showLoader = true;
    this.is_searching = false;
    this.userService.delete_button.disable = true;
    this.userService.delete_button.text = this.admin_data.deletingButtonText;
    let request = {
      "id": this.delete_task_id
    }
    this.tasksService.deleteTask(request).subscribe(
      (response) => {
        this.organisationService.showConfirmationModal.next(false);
        this.userService.delete_button.disable = false;
        this.userService.delete_button.text = this.admin_data.deleteButtonText;
        this.onClose = true;
        this.deleteIndex = 0;
        this.userService.showModal.next(false);
        this.toastService.showToast = true;
        this.toastService.toastType = "delete_task_list";
        this.getTasksList();

      },
      (error) => {
        this.organisationService.showConfirmationModal.next(false);
      }
    );
  }

  showAddTaskListModal() {
    this.tasksService.isEdit = false;
    if (!this.tasksService.isEdit) {
      this.taskList_formData[1].disable = false;
      this.tasksService.isEdit = false;
    } else {
      this.taskList_formData[1].disable = true;
      this.tasksService.isEdit = true;
    }
    this.addForm();
    // this.tasksService.isEdit = false;
    $("#addTaskListModal").modal("show");
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();

  }

  closeAddTaskListModal() {
    $("#addTaskListModal").modal("hide");
  }

  resetFormData() {
    for (let i = 0; i < this.taskList_formData.length; i++) {
      this.formGroup.patchValue({
        [this.taskList_formData[i].name]: "",
      });
    }
    this.tasksService.selectedTask = null;
  }

  addTaskList() {
    this.tasksService.add_button.disable = true;
    this.tasksService.add_button.text = this.admin_data.taskListAddingTaskButtonText;
    this.is_searching = false;
    this.tasksService.isEdit = false;
    let request = {
      title: this.formGroup.get("title").value.trim(),
      application_id: parseInt(this.formGroup.get("application_id").value)
    };
    this.closeAddTaskListModal();
    this.userService.showLoader = this.userService.showLoader ? false : true;
    this.tasksService.addTaskList(request).subscribe(
      (response) => {
        this.tasksService.add_button.disable = false;
        this.tasksService.add_button.text = this.admin_data.taskListAddTaskButtonText;
        this.tasksService.isEdit = false;
        if (!response['error']) {
          this.toastService.showToast = true;
          this.toastService.toastType = "add_task_list";
          this.getTasksList();
        } else {
          let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
          this.userService.errorMessage = errorResponse;
          $("#errorModal").modal("show");
        }
      });
  }

  editTaskList() {
    this.tasksService.update_button.disable = true;
    this.tasksService.update_button.text = this.admin_data.taskListUpdatingTaskButtonText;
    this.is_searching = false;
    let request = {
      id: parseInt(this.tasksService.selectedTask.id),
      title: this.formGroup.get("title").value.trim(),
      application_id: parseInt(this.formGroup.get("application_id").value),
    };
    this.closeAddTaskListModal();
    this.userService.showLoader = this.userService.showLoader ? false : true;
    this.tasksService.editTaskList(request).subscribe((response) => {
      this.tasksService.update_button.disable = false;
      this.tasksService.update_button.text = this.admin_data.taskListUpdateTaskButtonText;
      this.tasksService.isEdit = false;
      if (response["code"] == 1000) {
        this.resetFormData();
        this.toastService.showToast = true;
        this.toastService.toastType = "update_task_list";
        this.getTasksList();

      }
    }
    );
  }

  getApplications() {
    let self = this;
    var promise = new Promise((resolve) => {
      let applicationService = this.applicationService;
      let limit = 100;
      let offset = 0;
      let cb = applicationService.getApplications(limit, offset);
      let allApplications = [];
      let fetchRows = false;
      let rows;
      function application() {
        cb.subscribe(
          (data) => {
            Array.prototype.push.apply(
              allApplications,
              data["data"]["applications"]
            );
            allApplications.forEach((application, index) => {
              let tempSettings = JSON.parse(application.settings);
              self.applicationVariableData.set(application.application_id, tempSettings.variables == undefined ? [] : tempSettings.variables);
            })
            offset += 100;
            cb = applicationService.getApplications(limit, offset);
            if (!fetchRows) {
              rows = data["data"]["total_rows"]
              fetchRows = true;
            } else {
              rows = rows % 100;
            }
            if (rows / 100 > 1) {
              application();
            } else {
              resolve(allApplications);
            }
          }
        );
      }
      application();
    }).then(function (data: []) {
      self.applications = data;
      let options = [];
      self.applications.forEach(application => {
        if (application['type'] == 'guide') {
          application['type'] = self.admin_data.web;
          application.title = application.title + " (" + application.type + ")";
        } else if (application['type'] == 'electron') {
          application['type'] = self.admin_data.desktop;
          application.title = application.title + " (" + application.type + ")";
        } else if (application['type'] == 'mobile_web') {
          application['type'] = self.admin_data.mobileWeb;
          application.title = application.title + " (" + application.type + ")";
        } else if (application['type'] == 'android') {
          application['type'] = self.admin_data.android;
          application.title = application.title + " (" + application.type + ")";
        } else if (application['type'] == 'ios') {
          application['type'] = self.admin_data.ios;
          application.title = application.title + " (" + application.type + ")";
        } else if (application['type'] == 'intelligence') {
          application['type'] = self.admin_data.appIntelligence;
          application.title = application.title + " (" + application.type + ")";
        } else {
          application['type'] = ""
        }
        options.push({
          value: application.application_id,
          name: application.title
        });
      });
      if (self.tasks) {
        self.tasks.forEach(task => {
          let type = self.applications?.find(app => app.application_id === task.application_id)?.type;
          task.application_title = type ? task.application_title + " (" + type + ")" : task.application_title
        })
      }
      self.taskList_formData[1].options = options;
    });
  }

  getSegments = () => {
    this.segmentDataMapper = new Map();
    this.segmentationService.getAllSegments(this.userService.organization_id)
      .subscribe((res) => {
        if (!res["error"] && res["data"] && res["data"]["groups"]) {
          for (let i = 0; i < res["data"]["groups"].length; i++) {
            this.segmentData[i] = { group_id: res["data"]["groups"][i].group_id, title: res["data"]["groups"][i].title };
            this.segmentDataMapper.set(res["data"]["groups"][i].group_id, res["data"]["groups"][i].title)
          }
        } else {
          this.segmentFetchError = true;
        }
      });
  }

  changePublishStatus(request) {
    this.is_searching = false;
    this.tasksService.updatePublishStatus(request).subscribe((response) => {
      if (response['error'] == false) {
        this.getTasksList();
      }
    })
  }

  appendOffsetAndGetTasks() {
    this.is_searching = false;
    let offset = this.paginationService.appendOffset(this.limit, this.offset);
    if (offset >= 0) {
      this.offset = offset;
      this.getTasksList();
    }
  }

  prependOffsetAndGetTasks() {
    this.is_searching = false;
    let offset = this.paginationService.prependOffset(this.limit, this.offset);
    if (offset >= 0) {
      this.offset = offset;
      this.getTasksList();
    }
  }

  setIndex(ind) {
    this.is_searching = false;
    let offset = this.paginationService.setIndex(ind, this.limit, this.offset);
    if (offset >= 0) {

      this.offset = offset;
      this.getTasksList();
    }

  }

  changeLimit(event) {
    this.limit = parseInt(event);
    this.offset = 0;
    this.getTasksList();
    this.paginationService.initializeValues();
  }

  gotoPage(page) {
    this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
    if (this.offset >= 0) this.getTasksList();
  }

  gotoFirstPage() {
    this.setIndex(1);
  }

  gotoLastPage(event) {
    this.setIndex(event);
  }

  applyFilter(status, role, org_role) {
    // this.limit = 10;
    this.offset = 0;
    this.paginationService.initializeValues();
    this.pageLimitSet = false;
    this.getTasksList();
  }

  getUsersList() {
    this.visible = true;
    if (this.prevGetUserListCall) {
      this.prevGetUserListCall.unsubscribe();
    }

    this.prevGetUserListCall = this.leaderBoardService.getUsersList(this.modal_limit, this.modal_offset, this.search_user).subscribe(
      (response) => {
        this.modalPaginationService.calculatePageLimit(response['data']['total_rows']);

        if (!this.modalLimitSet) {
          this.modalPaginationService.calculatePageLimit(response['data']['total_rows']);
          this.modalLimitSet = true;
        }

        if (response['data']['user_list']) {
          let user_list = [];
          let data = response['data']['user_list'];
          data.forEach(user => {
            user_list.push({
              "image_url": user["image_url"],
              "full_name": user['first_name'] + " " + user['last_name'],
              "email": user['email'],
              "progress": user['completion_percentage']
            });
          });
          this.leader_board_data = user_list;
          this.modalPaginationService.setPaginationStatus(data, this.modal_limit);
        }
        this.visible = false;
      }, error => {
        this.visible = false;
      })
  }

  showLeaderBoardModal() {
    this.leader_board_data = [];
    $('#leaderBoardModal').modal("show");
  }

  closeLeaderBoardModal() {
    $('#leaderBoardModal').modal("hide");
  }

  getLeaderBoardData(task_list) {
    this.modalPaginationService.initializeValues();
    this.tasksService.isEdit = false;
    this.tasksService.openLeaderBoard = true;
    this.tasksService.selectedTask = task_list;
    this.search_user = '';
    this.getUsersList();
    this.showLeaderBoardModal();
  }

  searchUser() {
    // this.modal_limit = 10;
    this.modal_offset = 0;
    this.modalPaginationService.initializeValues();
    this.modalLimitSet = false;
    this.getUsersList();
  }

  appendOffsetAndGetUsers() {
    let offset = this.modalPaginationService.appendOffset(this.modal_limit, this.modal_offset);
    if (offset >= 0) {
      this.modal_offset = offset;
      this.getUsersList();
    }
  }

  prependOffsetAndGetUsers() {
    let offset = this.modalPaginationService.prependOffset(this.modal_limit, this.modal_offset);
    if (offset >= 0) {
      this.modal_offset = offset;
      this.getUsersList();
    }
  }

  setUserIndex(ind) {
    let offset = this.modalPaginationService.setIndex(ind, this.modal_limit, this.modal_offset);
    if (offset >= 0) {
      this.modal_offset = offset;
      this.getUsersList();
    }

  }

  getVisibilityData(task_list) {
    this.rule_variable_options = [];
    this.tasksService.isEdit = false;
    this.tasksService.selectedTask = task_list;
    this.addVisibilityForm();
    this.elem_groups = [
      [{
        text: "",
        select: "",
      }]
    ];
    let setting = (this.tasksService.selectedTask.setting)
      ? JSON.parse(this.tasksService.selectedTask.setting)
      : {};
    this.curr_settings = setting;
    this.rules = (setting["rules"]) ? setting["rules"] : [];
    this.attribute_count = 1;
    let group_count = 0;
    if (this.rules.length > 0) {
      let j = 0;
      for (let i = 0; i < this.rules.length; i++) {
        if (this.rules[i]['logical_condition'] == "||") {
          group_count += 1;
          this.addGroup();
        }

        if (this.rules[i].type == 'Variables') {
          if (this.rule_variable_options.length == 0) {
            // this.rule_variable_options.push(this.admin_data.select);
            // this.applicationVariableData.get(task_list.application_id).forEach(entry => this.rule_variable_options.push(entry.name));
            this.rule_variable_options.push({ value: '', name: this.admin_data.select });
            this.applicationVariableData.get(this.tasksService.selectedTask.application_id)
              .forEach(entry => this.rule_variable_options.push({ value: entry.name, name: entry.name }));
          }
        }
        if (i == 0) {
          this.visibilityFormGroup.get('groups')['controls'][group_count]['controls']['attributes']['controls'][0].patchValue({
            rule_type: this.rules[0]["type"],
            rule_variable_name: this.rules[0]["variableData"],
            rule_condition: this.rules[0]["condition"],
            rule_value: this.rules[0]["value"],
          });
        } else {
          j++;
          if (this.rules[i]) {
            if (this.rules[i]['logical_condition'] != "||") {
              this.addAttribute(group_count);
            } else {
              j = 0;
            }
            this.visibilityFormGroup.get('groups')['controls'][group_count]['controls']['attributes']['controls'][j].patchValue({
              rule_type: this.rules[i]["type"],
              rule_variable_name: this.rules[i]["variableData"],
              rule_condition: this.rules[i]["condition"],
              rule_value: this.rules[i]["value"],
            });
          }
        }
      }
    }
    else {
      this.visibilityFormGroup.get('groups')['controls'][0]['controls']['attributes']['controls'][0].patchValue({
        rule_type: this.admin_data.select,
        rule_variable_name: this.admin_data.select,
        rule_condition: this.admin_data.select,
        rule_value: "",
      });
    }

    let segment_groups = setting?.segment_groups ? JSON.parse(JSON.stringify(setting?.segment_groups)) : [];
    this.selectedItems = [];
    segment_groups.forEach(groupId => {
      if (!this.selectedItems.find(item => item.group_id == groupId)) {
        this.selectedItems.push({ group_id: groupId, title: this.segmentDataMapper.get(groupId) });
      }
    })
    this.showVisibilityModal();
  }

  showVisibilityModal() {
    this.leader_board_data = [];
    $('#visibilityModal').modal("show");
  }

  closeVisibilityModal() {
    this.rules = false;
    this.elem_map = [{ text: "", select: "" }];
    $('#visibilityModal').modal("hide");
    this.selectedItems = [];
  }

  saveVisibilitySettings() {
    if (!this.validateVisibilityForm()) {
      let setting = {};
      let attributes = [];
      let rules = []
      let formValue = this.visibilityFormGroup.value;
      for (let i = 0; i < formValue['groups'].length; i++) {
        for (let j = 0; j < formValue['groups'][i]['attributes'].length; j++) {
          let data = {
            "type": formValue['groups'][i]['attributes'][j]['rule_type'],
            "condition": formValue['groups'][i]['attributes'][j]['rule_condition'],
            "value": formValue['groups'][i]['attributes'][j]['rule_value']
          }
          if (data.type == "Variables") {
            data["variableData"] = formValue['groups'][i]['attributes'][j]['rule_variable_name'];
          }
          if (i == 0 && j == 0) {
            data['logical_condition'] = ''
          } else if (i > 0 && j == 0) {
            data['logical_condition'] = '||'
          } else {
            data['logical_condition'] = '&&'
          }
          rules.push(data);
        }
      }
      this.curr_settings['rules'] = rules;
      this.curr_settings['segment_groups'] = this.selectedItems.map(group => group.group_id);
      //   setting = JSON.stringify({ rules: rules });
      let request = {
        id: parseInt(this.tasksService.selectedTask.id),
        setting: JSON.stringify(this.curr_settings),
      };
      this.closeVisibilityModal();
      this.userService.showLoader = this.userService.showLoader ? false : true;
      this.tasksService.updateSetting(request).subscribe((response) => {
        if (response["code"] == 1000) {
          this.visibilityFormGroup.reset();
          this.toastService.showToast = true;
          this.toastService.toastType = "save_visibility_settings";
          this.getTasksList();
        }
      }
      );
    } else {
      return;
    }
  }

  addVisibilityForm() {
    let form = {};
    form['groups'] = new FormArray([]);
    let form1 = {};
    form1["attributes"] = new FormArray([]);
    form1["attributes"].push(
      new FormGroup({
        rule_type: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
        rule_variable_name: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }),
        rule_condition: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
        rule_value: new FormControl({ value: "", disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
      })
    );
    form['groups'].push(new FormGroup(form1));
    // form['groups']['attributes'].push(
    //   new FormGroup({
    //     rule_type: new FormControl("Select", [Validators.required]),
    //     rule_condition: new FormControl("Select", [Validators.required]),
    //     rule_value: new FormControl("", [Validators.required]),
    //   })
    // )
    this.visibilityFormGroup = new FormGroup(form);
  }

  addAttribute(j) {
    this.attribute_count += 1;
    this.elem_groups[j].push({
      text: "",
      select: "",
    });
    this.visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes'].push(
      new FormGroup({
        rule_type: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
        rule_variable_name: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }),
        rule_condition: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
        rule_value: new FormControl({ value: "", disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
      })
    );
  }

  remove(j, i) {
    this.elem_groups[j].splice(i, 1);
    this.attribute_count -= 1;
    this.visibilityFormGroup.get('groups')['controls'][j]['controls']['attributes'].removeAt(i);
  }

  addGroup() {
    this.elem_groups.push([{
      text: "",
      select: "",
    }])
    let form1 = {};
    form1["attributes"] = new FormArray([]);
    form1["attributes"].push(
      new FormGroup({
        rule_type: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
        rule_variable_name: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }),
        rule_condition: new FormControl({ value: this.admin_data.select, disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
        rule_value: new FormControl({ value: "", disabled: this.tasksService.selectedTask ? this.tasksService.selectedTask.is_publish == '1' : false }, [Validators.required]),
      })
    );
    this.visibilityFormGroup.get('groups').push(
      new FormGroup(form1)
    )
  }

  removeGroup(j) {
    this.elem_groups.splice(j, 1);
    this.visibilityFormGroup.get("groups").removeAt(j);
  }

  applyRuleTypeValidation(attribute, rule_type) {
    // this.flag=false;
    if (attribute.get('rule_type').value == 'URL Path') {
      let validators = [];
      validators.push(Validators.required);
      validators.push(Validators.pattern('^\/{1}[A-Za-z0-9\/*<>~=_-]*$'));
      validators.push(this.doubleSlashValidator);
      attribute.get('rule_value').setValidators(validators)
    }

    if (attribute.get('rule_type').value == 'URL Parameters') {
      let validators = [];
      validators.push(Validators.required);
      validators.push(Validators.pattern(/^\?([\w-]+(=[\w-]*)?(&[\w-]+(=[\w-]*)?)*)?$/));
      attribute.get('rule_value').setValidators(validators);
    }

    if (attribute.get('rule_type').value == 'URL Hash') {
      let validators = [];
      validators.push(Validators.required)
      validators.push(Validators.pattern(/^\#.*(.*)+/));
      attribute.get('rule_value').setValidators(validators);
    }
    attribute.patchValue({
      rule_value: '',
    })
    attribute.markAsTouched();
    attribute.markAsDirty();
    // setTimeout(()=>{
    //   this.flag=true;
    // },200);

    if (attribute.get('rule_type').value == 'Variables') {
      attribute.patchValue({
        rule_variable_name: this.admin_data.select
      })
      if (this.rule_variable_options.length == 0) {
        this.rule_variable_options.push({ value: '', name: this.admin_data.select });
        this.applicationVariableData.get(this.tasksService.selectedTask.application_id)
          .forEach(entry => this.rule_variable_options.push({ value: entry.name, name: entry.name }));
      }
    }
  }

  // processRuleTypeOption(controls) {
  // let has_URL_hash = false;
  // controls.forEach((elem) => {
  //   if (elem.get('rule_type').value == 'URL Hash') {
  //     has_URL_hash = true;
  //     return false;
  //   }
  // })
  // if (!has_URL_hash) {
  // return true;
  // }
  // }

  getcontent() {
    let str = `<div class="text-left"><b>Note:</b><br>` +
      this.admin_data.taskListNote1 + `<br>` +
      this.admin_data.taskListNote2 + `<br>` +
      this.admin_data.taskListNote3 + `<br>` +
      this.admin_data.taskListNote4 + `<br>` +
      this.admin_data.taskListNote5 + `<br>` + `<br>` +
      `</div>`
    return str;

  }

  validateInput(event: Event, isTask: Boolean) {
    this.userService.validateInput(event);
    if ((event.target as HTMLInputElement).value != this.lastSearchedValue) {
      if (isTask) {
        this.searchTaskList();
      } else {
        this.searchUser();
      }
      this.lastSearchedValue = (event.target as HTMLInputElement).value;
    }
  }

  validateVisibilityForm() {
    let formValue = this.visibilityFormGroup.value;
    if (this.selectedItems.length > 0) return false;
    for (let i = 0; i < formValue['groups'].length; i++) {
      for (let j = 0; j < formValue['groups'][i]['attributes'].length; j++) {
        let attr = formValue['groups'][i]['attributes'][j];
        if (attr["rule_type"] == "Variables") {
          if (attr["rule_variable_name"] == "" || attr["rule_variable_name"] == this.admin_data.select) {
            return true;
          }
          let flag = false;
          this.applicationVariableData.get(this.tasksService.selectedTask.application_id).forEach(task => {
            if (attr["rule_variable_name"] == task["name"]) {
              flag = true;
            }
          });

          if (!flag) {
            return true;
          }
        }
        if (attr["rule_type"] == "" || attr["rule_type"] == this.admin_data.select || attr["rule_condition"] == ""
          || attr["rule_condition"] == this.admin_data.select || attr["rule_value"] == "") {
          return true;
        }
      }
    }
    return false;
  }

  updateAppList = (event) => {
    console.log(event);
    switch (event.type) {
      case "select": this.onItemSelect(event); break;
      case "deselect": this.onDeSelect(event); break;
      case "selectAll": this.onSelectAll(); break;
      case "deselectAll": this.onDeSelectAll(); break;
      default: return;
    }
  }

  onItemSelect(group: any) {
    if (!this.selectedItems.some(selectedItem => selectedItem.group_id === group.group_id)) {
      this.selectedItems.push({ group_id: group.group_id, title: group.title });
    }
  }
  onSelectAll() {
    this.selectedItems = this.segmentData.map(group => ({ group_id: group.group_id, title: group.title }));
  }

  onDeSelect = (group: any) => this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.group_id !== group.group_id);

  onDeSelectAll = () => this.selectedItems = [];

  goToAction(event) {
    this.gotoPageSubject.next(Number(event.target.value));
  }
}
